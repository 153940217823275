import { Grid } from "@material-ui/core";
import { Typography } from "@yosgo/swap-ui";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import gsapCore from "gsap/gsap-core";

const TransitionWrap = styled.div`
  height: 100vh;
  width: 100vw;
  position: absoulte;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//https://my.spline.design/swap-c297f4211cdfc49adc551e55c71b1b7a/
//https://www.freecodecamp.org/news/how-to-create-a-reusable-fade-in-animation-component-in-react-with-gsap/
//https://greensock.com/react-advanced/
//https://blog.bitsrc.io/creating-reusable-animation-components-with-react-and-gsap-c9d9133d8e9b

const Transition = (props) => {
  const transitionRef = useRef(null);
  const [active, setActive] = useState(true);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (active && transitionRef) {
      // document.body.style.overflow = "hidden";
      // document.getElementById("nav").style.display = "none";

      gsap.to(transitionRef.current, {
        opacity: 1,
        duration: 3,
        background: "#9fb5ff",
        ease: "elastic",
        onComplete: () => {
          gsap.to(transitionRef.current, {
            opacity: 0,
            duration: 1,
            background: "rgba(0,0,0,0)",
            ease: "elastic",
            delay: 1.5,
            onComplete: () => {
              setActive(false);
              setDone(true);
              // document.body.style.overflow = "visible";
              // document.getElementById("nav").style.display = "inherit";
            },
          });
        },
      });
    }
  }, [active, transitionRef]);

  return (
    <div>
      {active ? (
        <TransitionWrap ref={transitionRef}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <model-viewer
                bounds="tight"
                ar
                ar-modes="webxr scene-viewer quick-look"
                camera-controls
                environment-image="neutral"
                poster="poster.webp"
                shadow-intensity="1"
                camera-orbit="-25.33deg 95.35deg auto"
                src="/swap.glb"
                style={{
                  width: "450px",
                  height: "450px",
                  background: "rgba(0,0,0,0)",
                }}
              >
                <div class="progress-bar hide" slot="progress-bar">
                  <div class="update-bar"></div>
                </div>
              </model-viewer>
            </Grid>
            <Grid item>
              <Typography variant="d2" color="white">
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </TransitionWrap>
      ) : (
        <>{done ? props.children : null}</>
      )}
    </div>
  );
};

export default Transition;
