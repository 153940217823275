import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { Suspense, useEffect, useState } from "react";
import { Grid, Container } from "@material-ui/core";
import styled from "styled-components";
import {
  Button,
  SWAPTheme,
  TextField,
  Typography,
  SWAPSpace,
  Modal,
} from "@yosgo/swap-ui";
import SearchIcon from "@material-ui/icons/Search";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Texture from "./Texture";
import Model from "./Model";
import Frame from "./Frame";
import Me from "./Me";
import Avatar from "./Avatar";
import Kcin from "./Kcin";
import Sharon from "./Sharon";
import Transition from "./Transition";

const Navigation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  padding: 0 7px;
  display: flex;
  align-items: center;
  z-index: 9;
  .logo {
    height: 48px;
  }
  .search .MuiInputBase-root {
    border-radius: 50px;
    font-size: 14px;
    color: #222222;
  }
`;

const WorkingSpace = styled.div`
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  height: 600px;
  background: white;
`;

const StudioWrap = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  .Hotspot {
    padding: 6px 9px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 50px;
    font-size: 14px;
    color: #222222;
    font-weight: bold;
    cursor: pointer;
    background: white;
  }
`;

const demoStudios = [
  {
    name: "諾克薩斯 SEO 工作室",
    category: "行銷",
    model: "/room06.glb",
    owner: "諾克薩斯",
    avatar: "https://renderapi.s3.amazonaws.com/HdQXZws9F.png",
    since: "2021/07/21",
    followers: "5.7k",
    online: false,
    poster: "/poster/06.png",
  },
  {
    name: "蘇瑞瑪平面設計工作室",
    category: "設計",
    model: "/room05.glb",
    owner: "蘇瑞瑪",
    avatar: "https://renderapi.s3.amazonaws.com/YLhgMccxT.png",
    since: "2021/08/21",
    followers: "4.7k",
    online: true,
    poster: "/poster/05.png",
  },
  {
    name: "班德爾線上顧問工作室",
    category: "顧問",
    model: "/room04.glb",
    owner: "班德爾",
    avatar: "https://renderapi.s3.amazonaws.com/fAZg9pQhL.png",
    since: "2021/09/21",
    followers: "3.7k",
    online: false,
    poster: "/poster/04.png",
  },
  {
    name: "左恩翻譯社工作室",
    category: "翻譯",
    model: "/room03.glb",
    owner: "右恩",
    avatar: "https://i.imgur.com/nFNcWAC.jpg",
    since: "2021/10/21",
    followers: "2.7k",
    online: true,
    poster: "/poster/03.png",
  },
  {
    name: "艾歐尼雅網頁設計工作室",
    category: "程式",
    model: "/room02.glb",
    owner: "楊尼雅",
    avatar: "https://renderapi.s3.amazonaws.com/Lh76pbkTz.png",
    since: "2021/11/21",
    followers: "1.7k",
    online: false,
    poster: "/poster/02.png",
  },
  {
    name: "虛空室內設計",
    category: "設計",
    model: "/room01.glb",
    owner: "陳虛空",
    avatar: "https://renderapi.s3.amazonaws.com/lxErACC6w.png",
    since: "2021/12/21",
    followers: "0.7k",
    online: true,
    poster: "/poster/01.png",
  },
];

const demoCity = [
  {
    name: "✏️ 設計師中心",
    position: "-1035.4021182712008m 326.34228309769674m -129.97596169370513m",
    normal: "-1.3435887453735414e-7m 0m -0.999999999999991m",
    camera: "676.1deg 80.84deg auto",
    description:
      "匯聚了全世界頂尖的設計師，從平面、視覺、影像、網頁、應用程式、人機交互、使用者體驗，個領域的設計師都匯聚在此。目前設計師中心有 238 位居民！",
  },
  {
    name: "💻 開發者基地",
    position: "-925.8086680677281m 54.40991847398328m -1190.7086207495918m",
    normal: "0.833073768179256m 0.5297249097974297m 0.15930981360770022m",
    camera: "584.4deg 76.32deg auto",
    description:
      "強調開源、互助的開發者基地！這裡的開發者們都以成為頂尖的工程師為目標，也許可以在這裡找到志同道合的夥伴。目前開發者基地有 178 位居民！",
  },
  {
    name: "📷 好攝酒館",
    position: "224.1914405822754m -78.77031632000505m -865.4369231156659m",
    normal: "1m 0m 0m",
    camera: "494.3deg 83.1deg auto",
    description:
      "人生苦短，必須性感，我們擅長把感動的瞬間記錄下來，舉凡婚禮、活動、家庭.. 都會有我們的身影。目前好攝酒館 328 位居民！",
  },
  {
    name: "📈 行銷天堂",
    position: "314.76261304693753m -116.28541685661969m 239.88631364040776m",
    normal: "0m 1m 0m",
    camera: "407.5deg 82.65deg auto",
    description:
      "好產品要有好的故事來襯托，行銷天堂就是聚集了一群擅長行銷的專家，各種千奇百怪的行銷招式等你來開發。目前行銷天堂有 482 位居民！",
  },
];

export default function Studio() {
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState(0);
  const [demoStudio, setDemoStudio] = useState(0);
  const [cityCamera, setCityCamera] = useState("495.8deg 38.33deg auto");
  const [cityIndex, setCityIndex] = useState(undefined);

  useEffect(() => {
    if (window && document) {
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src = "//unpkg.com/@google/model-viewer/dist/model-viewer.min.js";
      body.appendChild(script);
      script.addEventListener("load", () => {
        //Do something when load
      });
    }
  }, []);

  const handleDemoChange = () => {
    const curr = demoStudio + 1;
    if (curr < 5) {
      setDemoStudio(curr);
    } else {
      setDemoStudio(0);
    }
  };

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  return (
    <StudioWrap>
      <Modal
        size="medium"
        title="Title"
        onClose={() => setModal(false)}
        open={modal}
        primaryButton={{
          title: "Yo",
          onClick: () => setModal(false),
        }}
      />
      <Navigation id="nav">
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Link to="/">
                <img src="/swap.svg" className="logo" />
              </Link>
            </Grid>
            <Grid item>
              {/* <TextField
                        InputProps={{
                          endAdornment: (
                            <SearchIcon
                              style={{ color: "#6f6f6f", height: 24, width: 24 }}
                            />
                          ),
                        }}
                        height={40}
                        placeholder="搜尋工作室"
                        width={240}
                        className="search"
                      /> */}
            </Grid>
            <Grid item>
              {/* <Button variant="text" size="small">
                註冊帳號 / 登入
              </Button> */}
            </Grid>
          </Grid>
        </Container>
      </Navigation>
      {/* Step 0 Landing Page */}
      {step === 0 ? (
        <Transition title="一人也能成就偉大事業">
          <Container maxWidth="lg">
            <Grid container spacing={3} alignItems="center">
              <Grid item xl={6} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="d1">你的事業即將啟程</Typography>
                <SWAPSpace />
                <Typography
                  variant="body1_loose"
                  color="black800"
                  style={{
                    display: "block",
                    marginLeft: "7px",
                  }}
                >
                  SWAP 把接案流程系統化，免除行政瑣事，全心全意在你的事業上！
                </Typography>
                <SWAPSpace size="2xl" />
                <Button
                  size="large"
                  startIcon={<HomeWorkIcon />}
                  onClick={() => setStep(1)}
                >
                  踏上我的創業之旅
                </Button>
              </Grid>
              <Grid
                xl={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                item
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Grid
                  container
                  style={{
                    width: "auto",
                  }}
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <img
                      src={demoStudios[demoStudio].avatar}
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        display: "block",
                        background: "rgba(0,0,0,0.09)",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h3"
                      color="black800"
                      style={{
                        fontWeight: "lighter",
                      }}
                    >
                      {demoStudios[demoStudio].name}
                    </Typography>
                  </Grid>
                </Grid>
                <SWAPSpace />
                {demoStudio > 0 || demoStudio === 0 ? (
                  <model-viewer
                    bounds="tight"
                    ar
                    ar-modes="webxr scene-viewer quick-look"
                    camera-controls
                    environment-image="neutral"
                    poster={demoStudios[demoStudio].poster}
                    shadow-intensity="1"
                    exposure="1"
                    shadow-softness="1"
                    camera-orbit="45deg 70deg 70deg"
                    src={demoStudios[demoStudio].model}
                    style={{
                      width: "400px",
                      height: "400px",
                      background: "rgba(0,0,0,0)",
                    }}
                  >
                    <div class="progress-bar hide" slot="progress-bar">
                      <div class="update-bar"></div>
                    </div>
                  </model-viewer>
                ) : (
                  <img
                    src="https://i.imgur.com/q7J2fNL.jpg"
                    style={{
                      width: "400px",
                      height: "400px",
                      borderRadius: "3px",
                    }}
                  />
                )}
                <SWAPSpace />
                <Typography
                  variant="body2"
                  color="black600"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Since {demoStudios[demoStudio].since}{" "}
                </Typography>
                <SWAPSpace size="xs" />
                <Grid
                  container
                  style={{ width: "auto" }}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="body2" color="black800">
                      擁有者：{demoStudios[demoStudio].owner}
                    </Typography>
                  </Grid>
                </Grid>
                <SWAPSpace />
                <Grid
                  container
                  style={{ width: "auto" }}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      onClick={() =>
                        alert(
                          demoStudios[demoStudio].online
                            ? `${demoStudios[demoStudio].owner} 雖然在線上，但是他正在工作中，暫時不接受訪客 >///<`
                            : `${demoStudios[demoStudio].owner} 目前不在線上 = (`
                        )
                      }
                      startIcon={
                        <div
                          style={{
                            width: "7px",
                            height: "7px",
                            borderRadius: "50%",
                            background: demoStudios[demoStudio].online
                              ? "green"
                              : "red",
                          }}
                        />
                      }
                      endIcon={<TouchAppIcon />}
                    >
                      拜訪 {demoStudios[demoStudio].owner}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => handleDemoChange()}
                    >
                      下一個
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Transition>
      ) : null}
      {/**Step 1  Choose Town*/}
      {step === 1 ? (
        <Transition title="正在初始化你的創業地點">
          <Container maxWidth="lg">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={6}
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="d2">選擇創業地點</Typography>
                <SWAPSpace />
                <Typography variant="body2_loose" color="black700">
                  千金買厝，萬金買鄰。
                  現在就從右邊地圖上選擇一個地點來成立你的線上工作室！
                </Typography>
                {cityIndex >= 0 ? (
                  <div>
                    <SWAPSpace />
                    <SWAPSpace />
                    <Typography variant="h5">
                      你選擇了：{demoCity[cityIndex].name}
                    </Typography>
                    <SWAPSpace />
                    <Typography variant="body1" color="black900">
                      {demoCity[cityIndex].description}
                    </Typography>
                    <SWAPSpace />
                    <SWAPSpace />
                    <Typography variant="h6" color="black900">
                      在這裡的頂尖工作室
                    </Typography>
                    <SWAPSpace />
                    <Grid container spacing={2}>
                      {shuffle(demoStudios).map((item, index) => {
                        return (
                          <Grid item key={`stduio_${index}`}>
                            <img
                              src={item.poster}
                              style={{
                                display: "block",
                                width: "60px",
                                height: "60px",
                                background: "rgba(0,0,0,0)",
                                border: "1px solid rgba(0,0,0,0.09)",
                                borderRadius: "50%",
                                boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.12)",
                                background: "white",
                              }}
                            />
                            <Typography
                              variant="body2"
                              color="black700"
                              style={{
                                fontSize: "12px",
                                display: "block",
                                margin: "6px auto 0 auto",
                                textAlign: "center",
                              }}
                            >
                              {item.owner}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                ) : null}
                <SWAPSpace />
                <SWAPSpace />
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="tertiary"
                      size="small"
                      onClick={() => setStep(0)}
                    >
                      上一步
                    </Button>
                  </Grid>
                  {cityIndex >= 0 ? (
                    <Grid item>
                      <Button
                        variant="primary"
                        size="small"
                        onClick={() => setStep(2)}
                      >
                        在 {demoCity[cityIndex].name} 成立我的工作室
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item>
                {cityCamera ? (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <model-viewer
                      bounds="tight"
                      ar
                      ar-modes="webxr scene-viewer quick-look"
                      camera-controls
                      environment-image="neutral"
                      poster="poster.webp"
                      shadow-intensity="1"
                      camera-orbit={`${cityCamera}`}
                      src="/city.glb"
                      style={{
                        width: "600px",
                        height: "600px",
                        background: "rgba(0,0,0,0)",
                      }}
                    >
                      {demoCity.map((item, index) => (
                        <button
                          key={`hotspot${index}`}
                          class="Hotspot"
                          slot={`hotspot-${index + 1}`}
                          data-position={`${item.position}`}
                          data-normal={`${item.normal}`}
                          data-visibility-attribute="visible"
                          onClick={() => {
                            setCityCamera(item.camera);
                            setCityIndex(index);
                          }}
                        >
                          <div class="HotspotAnnotation">{item.name}</div>
                        </button>
                      ))}
                      <div class="progress-bar hide" slot="progress-bar">
                        <div class="update-bar"></div>
                      </div>
                    </model-viewer>
                    <Button
                      onClick={() => {
                        setCityCamera("495.8deg 38.33deg auto");
                      }}
                      size="small"
                      variant="text"
                    >
                      重置
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <SWAPSpace />
          </Container>
        </Transition>
      ) : null}
      {step === 2 ? (
        <Transition title="準備設計你的工作室">
          <Button onClick={() => setStep(0)}>開發中，回到首頁</Button>
        </Transition>
      ) : null}
      {/* <div>
                <WorkingSpace>
                  <Canvas camera={{ fov: 50, position: [10, 0, 10] }}>
                    <color attach="background" args={["#e0b7ff"]} />
                    <Suspense fallback={null}>
                      <group position-y={-3}>
                        <mesh
                          scale={(2.5, 2.5, 2.5)}
                          position-x={1.4}
                          rotation-y={45}
                        >
                          <Kcin />
                        </mesh>
                        <mesh scale={(2.5, 2.5, 2.5)} position-z={2} rotation-y={45}>
                          <Sharon />
                        </mesh>
                        <Model />
                      </group>
                      <Frame />
                      <Texture />
                      <OrbitControls />
                      <Environment preset="warehouse" background={false} />
                    </Suspense>
                  </Canvas>
                </WorkingSpace>
                <Avatar />
              </div> */}
    </StudioWrap>
  );
}
