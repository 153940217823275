import { useState } from "react";
import { useEffect } from "react";
import Map from "../Map";

const MapRouter = () => {
  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    fetch("/taiwan.json")
      .then((resp) => resp.json())
      .then((data) => {
        console.log("> data", data);
        setMapData(data);
      });
  }, []);

  return <>{mapData ? <Map mapData={mapData.features} /> : null}</>;
};

export default MapRouter;
