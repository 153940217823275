import React, { useEffect, useState } from "react";
import { Avatar, Container, Grid } from "@material-ui/core";
import { SWAPSpace, Typography } from "@yosgo/swap-ui";
import styled from "styled-components";
import { gsap } from "gsap";

import { Footer, Navigation } from "./Pricing";

import { ArcherContainer, ArcherElement } from "react-archer";

import MoneyIcon from "@material-ui/icons/Money";
import ReceiptIcon from "@material-ui/icons/Receipt";

const CircleWrap = styled.div`
  width: ${({ circleSize }) => (circleSize ? `${circleSize}px` : "90px")};
  height: ${({ circleSize }) => (circleSize ? `${circleSize}px` : "90px")};
  border-radius: 50%;
  box-shadow: -2px 2px 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: ${({ width }) => (width ? width : "90%")};
    height: auto;
    display: block;
    object-fit: contain;
  }
`;

const Circle = React.forwardRef((props, ref) => {
  const { url, width, circleSize, id } = props;
  return (
    <div ref={ref} id={id}>
      <CircleWrap width={width} circleSize={circleSize}>
        <img src={url} />
      </CircleWrap>
    </div>
  );
});

const LpWrap = styled.div`
  min-height: 100vh;
  position: relative;
  .one_top_avatar,
  .one_bottom_avatar {
    position: absolute;
  }
  .two_left_avatar,
  .two_right_avatar {
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1px;
  }
  .two_left_avatar_preview,
  .two_right_avatar_preview {
    position: absolute;
    bottom: 30px;
    opacity: 0;
    &.two_left_avatar_preview {
      left: 35px;
    }
    &.two_right_avatar_preview {
      left: 75px;
    }
  }
  .three_left_avatar {
  }
  .three_left_avatar,
  .three_right_avatar {
  }
`;

const Lp = () => {
  const [one_top_avatar, set_one_top_avatar] = useState({ top: 0, left: 0 });
  const [one_bottom_avatar, set_one_bottom_avatar] = useState({
    top: 0,
    left: 0,
  });
  const [three_direction, set_three_direction] = useState("right");

  {
    /**第一區動畫 */
  }
  useEffect(() => {
    const one_top_circle = document.getElementById("one_top_circle");
    const one_top_circle_box = one_top_circle.getBoundingClientRect();
    const one_top_avatar = document.getElementsByClassName("one_top_avatar")[0];
    const one_top_avatar_box = one_top_avatar.getBoundingClientRect();
    const one_bottom_circle = document.getElementById("one_bottom_circle");
    const one_bottom_circle_box = one_bottom_circle.getBoundingClientRect();
    const one_bottom_avatar =
      document.getElementsByClassName("one_bottom_avatar")[0];
    const gap = 83;
    set_one_top_avatar({
      top: one_top_circle_box.top + window.scrollY + gap,
      left:
        one_top_circle_box.left +
        window.scrollX +
        one_bottom_circle_box.width +
        10,
    });
    set_one_bottom_avatar({
      top: one_bottom_circle_box.top + window.scrollY + gap,
      left:
        one_bottom_circle_box.left +
        window.scrollX +
        one_bottom_circle_box.width +
        10,
    });
    var one_tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    one_tl
      .fromTo(
        ".one_top_avatar",
        { opacity: 0, x: -10, scaleX: 0, scaleY: 0 },
        { opacity: 1, x: 0, scaleX: 1, scaleY: 1 }
      )
      .fromTo(".one_top_avatar", { x: 0 }, { x: 75, duration: 1 })
      .fromTo(".one_top_avatar", { y: 0 }, { y: 105, duration: 1 })
      .fromTo(".one_top_avatar", { x: 75 }, { x: 170, duration: 1 })
      .fromTo(
        ".one_top_avatar",
        { opacity: 1 },
        { opacity: 0, scaleX: 0, scaleY: 0 }
      )
      .fromTo(
        ".one_bottom_avatar",
        { opacity: 0, x: -10, scaleX: 0, scaleY: 0 },
        { opacity: 1, x: 0, scaleX: 1, scaleY: 1 }
      )
      .fromTo(".one_bottom_avatar", { x: 0 }, { x: 75, duration: 1 })
      .fromTo(".one_bottom_avatar", { y: 0 }, { y: -105, duration: 1 })
      .fromTo(".one_bottom_avatar", { x: 75 }, { x: 170, duration: 1 })
      .fromTo(
        ".one_bottom_avatar",
        { opacity: 1 },
        { opacity: 0, scaleX: 0, scaleY: 0 }
      );
  }, []);

  {
    /**第二區動畫 */
  }
  useEffect(() => {
    setTimeout(() => {
      const gap =
        document
          .getElementsByClassName("two_left_avatar")[0]
          .getBoundingClientRect().width * 4;
      var two_tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });
      two_tl
        .fromTo(
          ".two_left_avatar",
          { opacity: 0, x: -gap * 1.2 },
          { opacity: 1, x: 0, duration: 1 }
        )
        .fromTo(
          ".two_left_avatar",
          { opacity: 1, scaleX: 1, scaleY: 1, x: 0 },
          { opacity: 0, scaleX: 0, scaleY: 0, x: gap, duration: 1 }
        )
        .fromTo(
          ".two_left_avatar_preview",
          { opacity: 0, scaleX: 1, scaleY: 1 },
          { opacity: 1, scaleX: 1.2, scaleY: 1.2, duration: 1 }
        )
        .fromTo(
          ".two_right_avatar",
          { opacity: 0, x: gap * 1.2 },
          { opacity: 1, x: 0, duration: 1 }
        )
        .fromTo(
          ".two_right_avatar",
          { opacity: 1, scaleX: 1, scaleY: 1, x: 0 },
          { opacity: 0, scaleX: 0, scaleY: 0, x: -gap, duration: 1 }
        )
        .fromTo(
          ".two_right_avatar_preview",
          { opacity: 0, scaleX: 1, scaleY: 1 },
          { opacity: 1, scaleX: 1.2, scaleY: 1.2, duration: 1 }
        );
    }, 1000);
  }, []);

  {
    /**第三區動畫 */
  }
  useEffect(() => {
    const gap = 100 * 3;
    var three_tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    setTimeout(() => {
      three_tl
        .fromTo(
          ".three_left_avatar",
          { opacity: 0, x: -gap * 1.2 },
          { opacity: 1, x: 0, duration: 1 }
        )
        .fromTo(
          ".three_left_avatar",
          { opacity: 1, scaleX: 1, scaleY: 1, x: 0 },
          {
            opacity: 0,
            scaleX: 0,
            scaleY: 0,
            x: gap,
            duration: 1,
            onComplete: () => set_three_direction("left"),
          }
        )
        .fromTo(
          ".three_left_avatar",
          { opacity: 0, scaleX: 0, scaleY: 0, x: gap },
          {
            opacity: 0,
            scaleX: 0,
            scaleY: 0,
            x: gap,
            delay: 3,
          }
        );
    }, 1000);
  }, []);

  return (
    <LpWrap>
      <Navigation />
      <Container maxWidth="lg">
        {/**Hero Section */}
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{ minHeight: "calc(100vh - 74px)" }}
        >
          <Grid item>
            <Typography variant="d1">Hello SWAP</Typography>
            <SWAPSpace />
            <Typography variant="h6">
              受可究受呢行面步度間須、造媽全教自答應強比作巴成裡電為麼包子議媽負
            </Typography>
          </Grid>
        </Grid>
        <SWAPSpace size="4xl" />
        {/**Function and Flow */}
        {[
          {
            title: "商務檔案",
            description:
              "建立 SWAP 商務檔案，讓客戶可在 SWAP 與 Google 上找到你，獲得更多的曝光量與瀏覽量！",
            layout: "row",
            indicator: (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ArcherContainer
                  offset={10}
                  lineStyle="angle"
                  endShape={{
                    arrow: {
                      arrowLength: 3,
                      arrowThickness: 3,
                    },
                  }}
                >
                  <Grid
                    container
                    wrap="nowrap"
                    spacing={6}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <div>
                        <ArcherElement
                          id="google"
                          relations={[
                            {
                              targetId: "profile",
                              targetAnchor: "left",
                              sourceAnchor: "right",
                              style: { strokeColor: "#2D2D2D" },
                            },
                          ]}
                        >
                          <Circle
                            id="one_top_circle"
                            width="60%"
                            url="https://burhanstudio.com/wp-content/uploads/2021/03/New-Google-Chrome-Logo-Google-Search-Logo-Concept.png"
                          />
                        </ArcherElement>
                      </div>
                      <SWAPSpace size={120} />
                      <div>
                        <ArcherElement
                          id="swap"
                          relations={[
                            {
                              targetId: "profile",
                              targetAnchor: "left",
                              sourceAnchor: "right",
                              style: { strokeColor: "#2D2D2D" },
                            },
                          ]}
                        >
                          <Circle
                            id="one_bottom_circle"
                            url="/SWAP_web logo-05.png"
                          />
                        </ArcherElement>
                      </div>
                    </Grid>
                    <Grid item container direction="row-reverse">
                      <ArcherElement id="profile">
                        <img
                          src="/lp/profile_phone.svg"
                          style={{ display: "block", width: "65%" }}
                        />
                      </ArcherElement>
                    </Grid>
                  </Grid>
                </ArcherContainer>
                <Avatar
                  className="one_top_avatar"
                  variant="circle"
                  src={`https://i.pravatar.cc/600?u=${new Date().getTime()}`}
                  style={{ top: one_top_avatar.top, left: one_top_avatar.left }}
                />
                <Avatar
                  className="one_bottom_avatar"
                  variant="circle"
                  src={`https://i.pravatar.cc/600?u=${
                    new Date().getTime() + 10
                  }`}
                  style={{
                    top: one_bottom_avatar.top,
                    left: one_bottom_avatar.left,
                  }}
                />
              </Grid>
            ),
          },
          {
            title: "報價單",
            description:
              "透過 SHA-256 加密的線上簽名機制，你無需實體見面就能簽署報價單，正式與客戶開始合作！",
            layout: "row-reverse",
            indicator: (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ArcherContainer
                  offset={10}
                  lineStyle="straight"
                  endShape={{
                    arrow: {
                      arrowLength: 3,
                      arrowThickness: 3,
                    },
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <ArcherElement
                        id="q_left"
                        relations={[
                          {
                            targetId: "q_middle",
                            targetAnchor: "left",
                            sourceAnchor: "right",
                            style: { strokeColor: "#2D2D2D" },
                            label: (
                              <Avatar
                                src="/lp/soho_sign.png"
                                className="two_left_avatar"
                              />
                            ),
                          },
                        ]}
                      >
                        <Circle url="/lp/member.png" width="120%" />
                      </ArcherElement>
                      <SWAPSpace size={6} />
                      <Typography
                        variant="caption1"
                        style={{ textAlign: "center" }}
                        color="black700"
                      >
                        你
                      </Typography>
                    </Grid>
                    <Grid item style={{ position: "relative" }}>
                      <ArcherElement id="q_middle">
                        <Circle url="/lp/q.svg" circleSize={150} width="70%" />
                      </ArcherElement>
                      <SWAPSpace size={6} />
                      <Typography
                        variant="caption1"
                        style={{ textAlign: "center" }}
                        color="black700"
                      >
                        SWAP 報價單
                      </Typography>
                      <Avatar
                        src="/lp/soho_sign.png"
                        className="two_left_avatar_preview"
                      />
                      <Avatar
                        src="/lp/company_sign.png"
                        className="two_right_avatar_preview"
                      />
                    </Grid>
                    <Grid item>
                      <ArcherElement
                        id="q_right"
                        relations={[
                          {
                            targetId: "q_middle",
                            targetAnchor: "right",
                            sourceAnchor: "left",
                            style: { strokeColor: "#2D2D2D" },
                            label: (
                              <Avatar
                                className="two_right_avatar"
                                src="/lp/company_sign.png"
                              />
                            ),
                          },
                        ]}
                      >
                        <Circle
                          url="https://i.pravatar.cc/300?img=33"
                          width="120%"
                        />
                      </ArcherElement>
                      <SWAPSpace size={6} />
                      <Typography
                        variant="caption1"
                        style={{ textAlign: "center" }}
                        color="black700"
                      >
                        客戶
                      </Typography>
                    </Grid>
                  </Grid>
                </ArcherContainer>
              </Grid>
            ),
          },
          {
            title: "請款單",
            description:
              "透過虛擬帳號系統(國泰世華)，客戶能直接線上支付款項，付款後 SWAP 的請款單將開立案件發票，讓你輕鬆搞定請款與核銷！",
            layout: "row",
            indicator: (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ArcherContainer
                  offset={10}
                  lineStyle="straight"
                  endShape={{
                    arrow: {
                      arrowLength: 3,
                      arrowThickness: 3,
                    },
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <ArcherElement
                        id="p_company"
                        relations={[
                          {
                            targetId: "p_swap",
                            targetAnchor: "left",
                            sourceAnchor: "right",
                            style: { strokeColor: "#2D2D2D" },
                            label: (
                              <div
                                style={{ paddingTop: "10px" }}
                                className="three_left_avatar"
                              >
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  spacing={1}
                                >
                                  <Avatar
                                    style={{
                                      background: "white",
                                      border: "1px solid rgba(0, 0, 0, 0.1)",
                                      padding: "1px",
                                    }}
                                  >
                                    <MoneyIcon color="primary" />
                                  </Avatar>
                                  <Typography variant="caption1">
                                    100 TWD
                                  </Typography>
                                </Grid>
                              </div>
                            ),
                          },
                        ]}
                      >
                        <Circle
                          url="https://i.pravatar.cc/300?img=33"
                          width="120%"
                        />
                      </ArcherElement>
                      <SWAPSpace size={6} />
                      <div>
                        <Typography
                          variant="caption1"
                          style={{ textAlign: "center" }}
                          color="black700"
                        >
                          客戶
                        </Typography>
                      </div>
                      <SWAPSpace />
                    </Grid>
                    <Grid item>
                      <ArcherElement id="p_swap">
                        <Circle url="/lp/p.svg" circleSize={150} width="55%" />
                      </ArcherElement>
                      <SWAPSpace size={6} />
                      <div>
                        <Typography
                          variant="caption1"
                          style={{ textAlign: "center" }}
                          color="black700"
                        >
                          SWAP 請款單
                        </Typography>
                      </div>
                      <SWAPSpace />
                    </Grid>
                  </Grid>
                </ArcherContainer>
              </Grid>
            ),
          },
          {
            title: "案件報酬",
            description:
              "隨時提領在 SWAP 平台上的案件報酬，今天提領，隔日工作日完成匯款。系統自動代扣繳所得稅與二代健保補充保費！",
            layout: "row-reverse",
            indicator: (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ArcherContainer
                  offset={10}
                  lineStyle="straight"
                  endShape={{
                    arrow: {
                      arrowLength: 3,
                      arrowThickness: 3,
                    },
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <ArcherElement
                        id="w_swap"
                        relations={[
                          {
                            targetId: "w_soho",
                            targetAnchor: "left",
                            sourceAnchor: "right",
                            style: { strokeColor: "#2D2D2D" },
                          },
                        ]}
                      >
                        <Circle url="/SWAP_web logo-05.png" id="w_swap" />
                      </ArcherElement>
                      <SWAPSpace size={6} />
                      <Typography
                        variant="caption1"
                        style={{ textAlign: "center" }}
                        color="black700"
                      >
                        SWAP
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ArcherElement id="w_soho">
                        <Circle url="/lp/member.png" width="120%" />
                      </ArcherElement>
                      <SWAPSpace size={6} />
                      <Typography
                        variant="caption1"
                        style={{ textAlign: "center" }}
                        color="black700"
                      >
                        你
                      </Typography>
                    </Grid>
                  </Grid>
                </ArcherContainer>
              </Grid>
            ),
          },
        ].map((item, index) => (
          <div key={`${item}_${index}`}>
            <Grid container direction={item.layout} alignItems="center">
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5} spacing={0}>
                <Typography variant="d3">{item.title}</Typography>
                <SWAPSpace />
                <Typography variant="h5" style={{ fontWeight: "normal" }}>
                  {item.description}
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "900px" }}
              >
                {item.indicator}
              </Grid>
            </Grid>
          </div>
        ))}
        {/**Call to Action */}
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{ minHeight: "calc(100vh - 74px)" }}
        >
          <Grid item>
            <Typography variant="d1">註冊會員</Typography>
            <SWAPSpace />
            <Typography variant="h6">
              與超過 1500+ 工作者在 SWAP 平台上建造／拓展 / 營運個人的接案事業！
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <SWAPSpace size="4xl" />
      <Footer />
    </LpWrap>
  );
};

export default Lp;
