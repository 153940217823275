import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Container,
  Divider,
  Fade,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Button,
  Paper,
  SegmentedTab,
  SegmentedTabs,
  SWAPSpace,
  Typography,
} from "@yosgo/swap-ui";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import * as arrowLine from "arrow-line";

import { Footer, Navigation } from "./Pricing";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import styled from "styled-components";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";

const Chat = ({
  name,
  avatar,
  content,
  direction,
  images,
  typing,
  delayToShow,
}) => {
  const [isTyping, setIsTyping] = useState(true);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    console.log("> Chat", content);
    setTimeout(() => {
      setIsShow(true);
    }, delayToShow * 1000);
    setTimeout(() => {
      setIsTyping(false);
    }, (typing + delayToShow) * 1000);
  }, []);

  return (
    <>
      {isShow ? (
        <Fade in timeout={1000}>
          <div>
            <Grid
              container
              spacing={1}
              alignItems="center"
              wrap="nowrap"
              direction={direction === "left" ? "row" : "row-reverse"}
            >
              <Grid item>
                {direction === "left" ? <SWAPSpace size={80} /> : null}
                <Avatar src={avatar} className="chat_avatar" />
                <Typography
                  variant="small2"
                  color="black700"
                  style={{ textAlign: "center", marginTop: "3px" }}
                >
                  {name}
                </Typography>
                {direction === "right" ? <SWAPSpace size={60} /> : null}
              </Grid>
              <Grid item>
                <div
                  className={direction === "left" ? "chat_left" : "chat_right"}
                >
                  {isTyping ? (
                    <Fade in timeout={1000}>
                      <img
                        src="/lp/typing.gif"
                        style={{
                          borderRadius: "12px",
                          height: "24px",
                          width: "auto",
                        }}
                      />
                    </Fade>
                  ) : (
                    <Fade in timeout={1000}>
                      {content}
                    </Fade>
                  )}
                </div>
              </Grid>
            </Grid>
            {isTyping ? null : (
              <Fade in timeout={1000}>
                <Grid container spacing={1}>
                  <Grid item>
                    <div
                      style={{ width: direction === "left" ? "60px" : "10px" }}
                    />
                  </Grid>
                  {images.map((item, index) => (
                    <Grid item key={`chat_image_${item}_${index}`}>
                      <img className="chat_img" src={item} />
                    </Grid>
                  ))}
                </Grid>
              </Fade>
            )}
          </div>
        </Fade>
      ) : null}
    </>
  );
};
const heroSlides = [
  <div className="slider_wrap">
    <Grid container wrap="nowrap" direction="column">
      <Grid item>
        <Chat
          name="Jack"
          avatar="https://i.pravatar.cc/150?img=33"
          images={["/lp/google_search.png", "/lp/profile.png"]}
          content={
            <Typography variant="body2">
              Mei 你好，我在 GOOGLE
              上搜尋到你的商務檔案，非常喜歡你的作品與設計風格！是否有機會與你合作呢？
            </Typography>
          }
          direction="left"
          delayToShow={0}
          typing={1.5}
        />
        <SWAPSpace size={30} />
        <Chat
          name="Mei"
          avatar="https://i.pravatar.cc/150?img=32"
          images={[]}
          content={
            <Typography variant="body2">
              Hi Jack
              很高興看到你！我們可以約這週三洽談細節，非常期待後續的合作！
            </Typography>
          }
          direction="right"
          delayToShow={2.5}
          typing={1.5}
        />
      </Grid>
    </Grid>
  </div>,
  <div className="slider_wrap">
    <Grid container wrap="nowrap" direction="column">
      <Grid item>
        <Chat
          name="Jack"
          avatar="https://i.pravatar.cc/150?img=33"
          images={[]}
          content={
            <Typography variant="body2">
              我們公司已確認與你合作，再請 Mei 提供報價單囉！
            </Typography>
          }
          direction="left"
          delayToShow={0}
          typing={1}
        />
        <SWAPSpace size={30} />
        <Chat
          name="Mei"
          avatar="https://i.pravatar.cc/150?img=32"
          images={["/lp/q.svg"]}
          content={
            <Typography variant="body2">
              沒問題！Jack 你們確認後可以回簽下方的報價單檔案或是
              <a
                target="_blank"
                href="https://swap.work/q/e7ff2c56-9c6f-490d-a5c6-2c36d96392ef?access_code=tio6PHlIX0"
              >
                點此線上簽名
              </a>
              ！
            </Typography>
          }
          direction="right"
          delayToShow={1.5}
          typing={1.5}
        />
      </Grid>
    </Grid>
  </div>,
  <div className="slider_wrap">
    <Grid container wrap="nowrap" direction="column">
      <Grid item>
        <Chat
          name="Jack"
          avatar="https://i.pravatar.cc/150?img=33"
          images={[]}
          content={
            <Typography variant="body2">
              Hi MEI 本次合作經順利結案，我們公司每個人都非常滿意你的設計成果！
            </Typography>
          }
          direction="left"
          delayToShow={0}
          typing={1}
        />
        <Chat
          name="Mei"
          avatar="https://i.pravatar.cc/150?img=32"
          images={["/lp/p.svg"]}
          content={
            <Typography variant="body2">
              太好了，你可以透過這個請款單匯款，付款後 SWAP 平台會自動開立發票。
              <a
                target="_blank"
                href="https://swap.work/i/f58a6482-bbaa-4910-bbdb-c874844bb90c?access_code=VEHpA4nagS"
              >
                點此付款
              </a>
              。非常期待未來再次合作！
            </Typography>
          }
          direction="right"
          delayToShow={2.5}
          typing={1.5}
        />
      </Grid>
    </Grid>
  </div>,
];
const HeroSectionWrap = styled.div`
  .hero_section_grid {
    .slider_wrap {
      min-height: 360px;
    }
    .chat_left {
      width: 100%;
      padding: 12px 16px;
      background: #e6e9f8;
      box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
      border-radius: 28.0702px 28.0702px 28.0702px 0px;
    }
    .chat_right {
      width: 100%;
      padding: 12px 16px;
      background: #fef8e3;
      box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
      border-radius: 28.0702px 0px 28.0702px 28.0702px;
    }

    .chat_img {
      cursor: pointer;
      width: 120px;
      height: 120px;
      display: block;
      object-fit: contain;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
      margin-top: -10px;
    }
    .chat_avatar {
      width: 50px;
      height: 50px;
    }
    .slick_indicator {
      width: 30px;
      height: 9px;
      border-radius: 12px;
      background: #97a3e1;
      cursor: pointer;
      transition: 1s;
    }
    .slick_indicator_active {
      background: #071e60;
      width: 60px;
      margin: 0 6px;
      transition: 1s;
    }
    .hero_img_m {
      max-width: 90%;
      display: block;
      margin: 0 auto;
    }
  }
`;
const HeroSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideGoTo = (index) => {
    setActiveSlide(index);
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <HeroSectionWrap>
      <div style={{ background: "#FFFFFF", overflow: "hidden" }}>
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            className="hero_section_grid"
            direction={mobile ? "column-reverse" : "row"}
            style={{ height: mobile ? "auto" : "570px" }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              justifyContent={mobile ? "center" : "flex-start"}
            >
              <Grid
                container
                alignItems="center"
                spacing={1}
                justifyContent={mobile ? "center" : "flex-start"}
              >
                <Grid item>
                  <Typography variant={mobile ? "h4" : "h1"} color="primary900">
                    SWAP
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={mobile ? "h4" : "h1"}>
                    讓你的專業變成事業
                  </Typography>
                </Grid>
              </Grid>
              <SWAPSpace />
              <Typography
                variant={mobile ? "h5" : "h6"}
                color="black800"
                style={{
                  fontWeight: "normal",
                  textAlign: mobile ? "center" : "left",
                }}
              >
                {/* 透過 SWAP
                獲得更多曝光機會並在線上輕鬆向客戶請款，讓你心無旁騖地發展個人事業！ */}
                一站式管理專案，為自由工作者打造美好接案體驗
              </Typography>
              <SWAPSpace size={mobile ? 24 : 43} />
              <Grid container spacing={1}>
                <Grid item {...(mobile ? { xs: 12, sm: 12 } : null)}>
                  <Button
                    fullWidth={mobile ? true : false}
                    endIcon={<ArrowForwardOutlinedIcon />}
                    onClick={() => {}}
                  >
                    馬上註冊試用
                  </Button>
                </Grid>
                <Grid item {...(mobile ? { xs: 12, sm: 12 } : null)}>
                  <Button
                    fullWidth={mobile ? true : false}
                    endIcon={
                      <img
                        style={{ width: "15px", height: " 15px" }}
                        src="/lp/google.png"
                      />
                    }
                    variant="secondary"
                    onClick={() => {}}
                  >
                    {" "}
                    使用 GOOGLE 註冊
                  </Button>
                </Grid>
              </Grid>
              <SWAPSpace size={12} />
              {/* <Typography
                variant="small2"
                color="black700"
                style={{ paddingLeft: "6px" }}
              >
                無需信用卡，免費註冊！
              </Typography> */}
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              {mobile ? (
                <div>
                  <img src="/lp/hero_img.svg" className="hero_img_m" />
                  <SWAPSpace size={24} />
                </div>
              ) : (
                <div>
                  {activeSlide === 0 ? heroSlides[0] : null}
                  {activeSlide === 1 ? heroSlides[1] : null}
                  {activeSlide === 2 ? heroSlides[2] : null}
                  <SWAPSpace />
                  <Grid container justifyContent="center">
                    <Grid item>
                      {[
                        "階段 1，接洽潛在客戶",
                        "階段 2，進行報價",
                        "階段 3，結案請款",
                      ].map((item, index) => {
                        return (
                          <div key={`${item}${index}`}>
                            {activeSlide === index ? (
                              <div>
                                <Fade in timeout={500}>
                                  <Typography variant="small2" color="black900">
                                    {item}
                                  </Typography>
                                </Fade>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <SWAPSpace size={12} />
                  <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    alignItems="center"
                  >
                    {[0, 1, 2].map((item) => (
                      <Grid item>
                        <div
                          className={`slick_indicator ${
                            activeSlide === item ? "slick_indicator_active" : ""
                          }`}
                          onClick={() => handleSlideGoTo(item)}
                        />
                      </Grid>
                    ))}
                    <Grid item>
                      <div
                        onClick={() => {
                          handleSlideGoTo(
                            activeSlide + 1 > 2 ? 0 : activeSlide + 1
                          );
                        }}
                      >
                        <Typography
                          variant="small1"
                          color="primary900"
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          {activeSlide === 2 ? "Restart ↻" : "Next ➡"}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
        <SWAPSpace size={60} />
      </div>
    </HeroSectionWrap>
  );
};

const PartnersWrap = styled.div`
  .logos_wrap {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }
  .logos_wrap::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;
const PartnersSection = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <PartnersWrap>
      <div style={{ background: "#FFFFFF" }}>
        <Container maxWidth="lg">
          <div
            className="logos_wrap"
            style={{
              overflowX: mobile ? "scroll" : "hidden",
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent={mobile ? "flex-start" : "space-between"}
              wrap="nowrap"
              style={{
                width: mobile ? "1000px" : "auto",
              }}
            >
              {[
                "/lp/logo/1.png",
                "/lp/logo/2.png",
                "/lp/logo/3.png",
                "/lp/logo/4.png",
                "/lp/logo/5.png",
                "/lp/logo/6.png",
              ].map((item, index) => (
                <Grid
                  item
                  key={`img_${index}`}
                  container
                  justifyContent={mobile ? "flex-start" : "center"}
                >
                  <img
                    src={item}
                    style={{
                      maxWidth: mobile ? "72px" : "120px",
                      maxHeight: mobile ? "26px" : "36px",
                      display: "block",
                      objectFit: "contain",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          <SWAPSpace size={24} />
        </Container>
        <Divider />
      </div>
    </PartnersWrap>
  );
};

const ValueSection = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <div style={{ background: "#FFFFFF" }}>
      <Container maxWidth="lg">
        <SWAPSpace size={mobile ? 24 : 80} />
        <Grid
          container
          wrap={mobile ? "wrap" : "nowrap"}
          justifyContent="space-between"
        >
          {[
            {
              icon: <LocalAtmOutlinedIcon style={{ color: "#F59225" }} />,
              backgroundColor: "#FEF8E3",
              title: "向紙本文件說 Bye Bye",
              description:
                "透過 SWAP 的線上文件與簽名機制，省去紙本往返的時間，更有效率地接案！",
            },
            {
              icon: <img src="/lp/tax.svg" />,
              backgroundColor: "#E7F5F7",
              title: "輕鬆搞定的客戶核銷需求",
              description:
                "透過 SWAP 的線上請款單，大幅縮減核銷過程的時間，儘快取得案件款項！",
            },
            {
              icon: (
                <img
                  src="/lp/link.svg"
                  style={{ width: "18px", color: "#5173F0" }}
                />
              ),
              backgroundColor: "#E6E9F8",
              title: "All In One 一站式服務",
              description:
                "SWAP 彙整了在接案流程中，每個環節所需的功能，讓你專注於案件交付！",
            },
          ].map((item, index) => (
            <Grid
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              key={`${item.title}_${index}`}
            >
              <div
                style={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: item.backgroundColor,
                }}
              >
                {item.icon}
              </div>
              <SWAPSpace size={16} />
              <Typography variant="h6">{item.title}</Typography>
              <SWAPSpace size={8} />
              <Typography variant="body2">{item.description}</Typography>
              {mobile ? <SWAPSpace size={24} /> : null}
            </Grid>
          ))}
        </Grid>
        <SWAPSpace size={mobile ? 46 : 80} />
      </Container>
    </div>
  );
};

const HowItWorkWrap = styled.div`
  .how_to_avatar {
    position: relative;
    z-index: 1;
    margin: 0px auto;
    border: 6px solid rgba(151, 163, 225, 0.15);
    box-shadow: 0px 6.99292px 20.9788px rgba(0, 0, 0, 0.1);
  }
`;
const HowItWorkSection = () => {
  const [activeHow, setActiveHow] = useState(0);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    // 架構圖畫線
    const option = {
      thickness: 4,
      endpoint: {
        type: "none",
      },
      color: "#cccccc",
    };

    const line1 = arrowLine("#h_soho", "#h_swap");
    const line2 = arrowLine("#h_soho_icon", "#h_swap");
    const line3 = arrowLine("#h_company", "#h_swap");
    const line4 = arrowLine("#h_company_icon", "#h_swap");
    const line5 = arrowLine("#h_company", "#h_company_icon");
    const line6 = arrowLine("#h_soho", "#h_soho_icon");
    setInterval(() => {
      line1.update({ ...option, destinationPosition: "topLeft" });
      line2.update({ ...option, destinationPosition: "bottomLeft" });
      line3.update({ ...option, destinationPosition: "topRight" });
      line4.update({ ...option, destinationPosition: "bottomRight" });
      line5.update({ ...option, style: "dash" });
      line6.update({ ...option, style: "dash" });
    }, 100);
  }, []);
  return (
    <HowItWorkWrap>
      <div style={{ background: "#F9F9F9" }}>
        <Container maxWidth="md">
          <SWAPSpace size={mobile ? 40 : 80} />
          <Grid
            container
            direction={mobile ? "column" : "row"}
            alignItems={mobile ? "center" : "flex-start"}
            justifyContent="center"
            spacing={2}
          >
            <Grid item>
              <img src="/lp/bulb.svg" style={{ height: "36px;" }} />
            </Grid>
            <Grid item>
              <Typography variant="h2">我們如何幫你</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" color="primary900">
                加速一人事業
              </Typography>
              <img
                src="/lp/text_wave.svg"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Grid>
          </Grid>
          <SWAPSpace size={mobile ? 12 : 28} />
          <Typography
            variant={mobile ? "body2" : "body1"}
            style={{ textAlign: "center" }}
            color="black800"
          >
            客戶付完案件款項後，能自行下載「請款單及發票」，款項會進入你的SWAP平台帳戶中，你能隨時「取得勞報單」完成稅務申報並「提領款項」至指定之銀行帳戶。
          </Typography>
          <SWAPSpace size={mobile ? 24 : 48} />
          <Grid container justifyContent="center">
            <Grid item>
              <SegmentedTabs value={activeHow} variant="standard" width={280}>
                <SegmentedTab
                  label="會員請款"
                  flex={1}
                  onClick={() => setActiveHow(0)}
                />
                <SegmentedTab
                  label="客戶付款"
                  flex={1}
                  onClick={() => setActiveHow(1)}
                />
                <SegmentedTab
                  label="會員提領"
                  flex={1}
                  onClick={() => setActiveHow(2)}
                />
              </SegmentedTabs>
            </Grid>
          </Grid>
          <SWAPSpace size={28} />
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ display: "none" }}
          >
            <Grid item>
              <Typography variant="body1">
                {activeHow === 0 ? (
                  <Fade in timeout={1000}>
                    <Typography variant="body1">
                      會員使用 SWAP 請款單向客戶結算案件報酬
                    </Typography>
                  </Fade>
                ) : (
                  ""
                )}
                {activeHow === 1 ? (
                  <Fade in timeout={1000}>
                    <Typography variant="body1">
                      SWAP 收到客戶的案件款項，同時開立平台發票給客戶
                    </Typography>
                  </Fade>
                ) : (
                  ""
                )}
                {activeHow === 2 ? (
                  <Fade in timeout={1000}>
                    <Typography variant="body1">
                      SWAP 匯款案件款項給會員，同時開立會員的勞務報酬單
                    </Typography>
                  </Fade>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <SWAPSpace size={24} />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ display: "none" }}
          >
            <Grid item>
              <Typography
                variant="small1"
                color="black700"
                style={{ textAlign: "center", margin: "6px 0" }}
              >
                自由工作者會員
              </Typography>
              <Avatar
                id="h_soho"
                className="how_to_avatar"
                style={{
                  width: "110px",
                  height: "110px",
                  background: "#97A3E1",
                }}
              >
                <EmojiPeopleIcon
                  color="primary"
                  style={{ width: "60px", height: "60px" }}
                />
              </Avatar>
              <SWAPSpace size={60} />
              <Avatar
                id="h_soho_icon"
                className="how_to_avatar"
                style={{ width: "88px", height: "88px", background: "#FBDF8A" }}
              >
                <AccountBalanceIcon
                  color="primary"
                  style={{ width: "40px", height: "40px" }}
                />
              </Avatar>
              <Typography
                variant="small1"
                color="black700"
                style={{ textAlign: "center", margin: "6px 0" }}
              >
                自由工作者會員帳戶
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                id="h_swap"
                className="how_to_avatar"
                style={{
                  width: "36px",
                  height: "36px",
                  transform: "scale(6, 6)",
                }}
                src="/SWAP_web logo-06.png"
              />
            </Grid>
            <Grid item>
              <Typography
                variant="small1"
                color="black700"
                style={{ textAlign: "center", margin: "6px 0" }}
              >
                客戶公司
              </Typography>
              <Avatar
                id="h_company"
                className="how_to_avatar"
                style={{
                  width: "110px",
                  height: "110px",
                  background: "#97A3E1",
                }}
              >
                <LocationCityIcon
                  color="primary"
                  style={{ width: "60px", height: "60px" }}
                />
              </Avatar>
              <SWAPSpace size={60} />
              <Avatar
                id="h_company_icon"
                className="how_to_avatar"
                style={{ width: "88px", height: "88px", background: "#FBDF8A" }}
              >
                <AccountBalanceIcon
                  color="primary"
                  style={{ width: "40px", height: "40px" }}
                />
              </Avatar>
              <Typography
                variant="small1"
                color="black700"
                style={{ textAlign: "center", margin: "6px 0" }}
              >
                客戶公司帳戶
              </Typography>
            </Grid>
          </Grid>
          {activeHow === 0 ? (
            <Fade in timeout={1000}>
              <video
                src="/lp/flow1.mov"
                style={{ width: "100%", background: "rgba(0,0,0,0)" }}
                webkit-playsinline={true}
                playsinline={true}
                autoPlay={mobile ? false : true}
                muted
                loop
              />
            </Fade>
          ) : null}
          {activeHow === 1 ? (
            <Fade in timeout={1000}>
              <video
                src="/lp/flow2.mov"
                style={{ width: "100%", background: "rgba(0,0,0,0)" }}
                webkit-playsinline={true}
                playsinline={true}
                autoPlay={mobile ? false : true}
                muted
                loop
              />
            </Fade>
          ) : null}
          {activeHow === 2 ? (
            <Fade in timeout={1000}>
              <video
                src="/lp/flow3.mov"
                style={{ width: "100%", background: "rgba(0,0,0,0)" }}
                webkit-playsinline={true}
                playsinline={true}
                autoPlay={mobile ? false : true}
                muted
                loop
              />
            </Fade>
          ) : null}
          <SWAPSpace size={mobile ? 80 : 40} />
        </Container>
      </div>
    </HowItWorkWrap>
  );
};

const FunctionsWrap = styled.div`
  .functions_section {
    .function_btn_start_icon {
      transform: scale(0.8, 0.8);
      margin-top: 4px;
      svg {
        color: #4862cc;
      }
    }
    .function_img {
      width: 100%;
      maxheight: 540px;
      margin: 0 auto;
      display: block;
      object-fit: contain;
    }
    .functions_accordion {
      border: none;
      box-shadow: none;
    }
  }
`;
const FunctionsSection = () => {
  const [activeFunction, setActiveFunction] = useState(0);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <FunctionsWrap>
      <Container maxWidth="lg" className="functions_section">
        <SWAPSpace size={mobile ? 24 : 80} />
        <Grid container maxWidth="lg">
          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
            <Typography variant="h4">接案流程自動化</Typography>
            <SWAPSpace size={12} />
            <Typography variant="body1">
              讓 SWAP 成為經營個人事業的後盾，讓你專注於案件交付！
            </Typography>
            <SWAPSpace size={24} />
            {[
              {
                label: "商務檔案",
                icon: (
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 2.54431H10L8 0.544312H2C0.9 0.544312 0.00999999 1.44431 0.00999999 2.54431L0 14.5443C0 15.6443 0.9 16.5443 2 16.5443H18C19.1 16.5443 20 15.6443 20 14.5443V4.54431C20 3.44431 19.1 2.54431 18 2.54431ZM18 14.5443H2V2.54431H7.17L9.17 4.54431H18V14.5443ZM13 9.54431C14.1 9.54431 15 8.64431 15 7.54431C15 6.44431 14.1 5.54431 13 5.54431C11.9 5.54431 11 6.44431 11 7.54431C11 8.64431 11.9 9.54431 13 9.54431ZM9 13.5443H17V12.5443C17 11.2143 14.33 10.5443 13 10.5443C11.67 10.5443 9 11.2143 9 12.5443V13.5443Z"
                      fill="#6F6F6F"
                    />
                  </svg>
                ),
                description:
                  "透過商務檔案管理你的所有內容連結，在 Google 搜尋、SWAP 官網都能曝光，讓更多潛在客戶看到你！成為 SWAP 實名認證的工作者，讓客戶合作得更安心。",
              },
              {
                label: "報價單",
                icon: (
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 0.544312C0.89 0.544312 0 1.43431 0 2.54431V16.5443C0 17.6543 0.89 18.5443 2 18.5443H16C17.11 18.5443 18 17.6543 18 16.5443V2.54431C18 1.43431 17.11 0.544312 16 0.544312H2ZM2 2.54431H16V16.5443H2V2.54431ZM4 4.54431V6.54431H14V4.54431H4ZM4 8.54431V10.5443H14V8.54431H4ZM4 12.5443V14.5443H11V12.5443H4Z"
                      fill="#6F6F6F"
                    />
                  </svg>
                ),
                description:
                  "輕鬆建立報價單，並訂定報價有效日期、報價備註事項，線上即時請客戶確認，隨時修改調整報價。",
                cta: "   報價單範本",
              },
              {
                label: "請款單",
                icon: (
                  <svg
                    width="18"
                    height="23"
                    viewBox="0 0 18 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.87868 1.42299C1.44129 0.860382 2.20435 0.544312 3 0.544312H11C11.2652 0.544312 11.5196 0.649668 11.7071 0.837205L17.7071 6.8372C17.8946 7.02474 18 7.2791 18 7.54431V19.5443C18 20.34 17.6839 21.103 17.1213 21.6656C16.5587 22.2282 15.7957 22.5443 15 22.5443H3C2.20435 22.5443 1.44129 22.2282 0.87868 21.6656C0.31607 21.103 0 20.34 0 19.5443V3.54431C0 2.74866 0.31607 1.9856 0.87868 1.42299ZM3 2.54431C2.73478 2.54431 2.48043 2.64967 2.29289 2.8372C2.10536 3.02474 2 3.27909 2 3.54431V19.5443C2 19.8095 2.10536 20.0639 2.29289 20.2514C2.48043 20.439 2.73478 20.5443 3 20.5443H15C15.2652 20.5443 15.5196 20.439 15.7071 20.2514C15.8946 20.0639 16 19.8095 16 19.5443V7.95852L10.5858 2.54431H3Z"
                      fill="#6F6F6F"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11 0.544312C11.5523 0.544312 12 0.992027 12 1.54431V6.54431H17C17.5523 6.54431 18 6.99203 18 7.54431C18 8.0966 17.5523 8.54431 17 8.54431H11C10.4477 8.54431 10 8.0966 10 7.54431V1.54431C10 0.992027 10.4477 0.544312 11 0.544312Z"
                      fill="#6F6F6F"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 12.5443C4 11.992 4.44772 11.5443 5 11.5443H13C13.5523 11.5443 14 11.992 14 12.5443C14 13.0966 13.5523 13.5443 13 13.5443H5C4.44772 13.5443 4 13.0966 4 12.5443Z"
                      fill="#6F6F6F"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 16.5443C4 15.992 4.44772 15.5443 5 15.5443H13C13.5523 15.5443 14 15.992 14 16.5443C14 17.0966 13.5523 17.5443 13 17.5443H5C4.44772 17.5443 4 17.0966 4 16.5443Z"
                      fill="#6F6F6F"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 8.54431C4 7.99203 4.44772 7.54431 5 7.54431H7C7.55228 7.54431 8 7.99203 8 8.54431C8 9.0966 7.55228 9.54431 7 9.54431H5C4.44772 9.54431 4 9.0966 4 8.54431Z"
                      fill="#6F6F6F"
                    />
                  </svg>
                ),
                description:
                  "建立專業的請款單文件，向客戶請款只需幾分鐘，手機也可以傳送，讓你能隨時掌握請款狀態。",
                cta: "請款單範本",
              },
              {
                label: "簽名分享",
                icon: (
                  <svg
                    width="16"
                    height="23"
                    viewBox="0 0 16 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4.54431L10.58 5.96431L8.99 4.37431V15.5443H7.01V4.37431L5.42 5.96431L4 4.54431L8 0.544312L12 4.54431ZM16 9.54431V20.5443C16 21.6443 15.1 22.5443 14 22.5443H2C0.89 22.5443 0 21.6443 0 20.5443V9.54431C0 8.43431 0.89 7.54431 2 7.54431H5V9.54431H2V20.5443H14V9.54431H11V7.54431H14C15.1 7.54431 16 8.43431 16 9.54431Z"
                      fill="#6F6F6F"
                    />
                  </svg>
                ),
                description:
                  "簽名分享都只需要一個連結，採線上簽名無需耗時紙本往來，讓雙方合作更安心。",
              },
              {
                label: "付款核銷",
                icon: (
                  <svg
                    width="22"
                    height="17"
                    viewBox="0 0 22 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 10.5443V2.54431C18 1.44431 17.1 0.544312 16 0.544312H2C0.9 0.544312 0 1.44431 0 2.54431V10.5443C0 11.6443 0.9 12.5443 2 12.5443H16C17.1 12.5443 18 11.6443 18 10.5443ZM16 10.5443H2V2.54431H16V10.5443ZM9 3.54431C7.34 3.54431 6 4.88431 6 6.54431C6 8.20431 7.34 9.54431 9 9.54431C10.66 9.54431 12 8.20431 12 6.54431C12 4.88431 10.66 3.54431 9 3.54431ZM22 3.54431V14.5443C22 15.6443 21.1 16.5443 20 16.5443H3C3 15.5443 3 15.6443 3 14.5443H20V3.54431C21.1 3.54431 21 3.54431 22 3.54431Z"
                      fill="#6F6F6F"
                    />
                  </svg>
                ),
                description:
                  "系統會依照你提領報酬之金額，自動計算稅務，並產生電子化勞報單，讓核銷變得簡單又有效率。",
              },

              {
                label: "專案管理",
                icon: (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4.54431V2.54431H8V4.54431H12ZM2 6.54431V17.5443H18V6.54431H2ZM18 4.54431C19.11 4.54431 20 5.43431 20 6.54431V17.5443C20 18.6543 19.11 19.5443 18 19.5443H2C0.89 19.5443 0 18.6543 0 17.5443L0.00999999 6.54431C0.00999999 5.43431 0.89 4.54431 2 4.54431H6V2.54431C6 1.43431 6.89 0.544312 8 0.544312H12C13.11 0.544312 14 1.43431 14 2.54431V4.54431H18Z"
                      fill="#6F6F6F"
                    />
                  </svg>
                ),
                description:
                  "以專案方式管理客戶資訊和繁雜文件，及時掌握每個專案的進度，再也不用擔心哪些文件忘記簽名、哪些案件還沒請款。",
              },
            ].map((item, index) => (
              <div key={`acc_${index}`}>
                <Accordion
                  className="functions_accordion"
                  onClick={() => setActiveFunction(index)}
                  expanded={index === activeFunction}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>
                        <SWAPSpace size={3} />
                        <Typography color="black700">{item.icon}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6">{item.label}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <Typography variant="body2" color="black800">
                        {item.description}
                      </Typography>
                      {item.cta ? (
                        <div>
                          <SWAPSpace size={16} />
                          <Button
                            size="small"
                            startIcon={
                              <div className="function_btn_start_icon">
                                {item.icon}
                              </div>
                            }
                            variant="text"
                          >
                            {item.cta}
                          </Button>
                        </div>
                      ) : null}
                      {mobile ? (
                        <div>
                          {activeFunction === 0 ? (
                            <div>
                              <img
                                className="function_img"
                                src="/lp/profile.png"
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </Grid>
          {mobile ? null : <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />}
          {mobile ? null : (
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {activeFunction === 0 ? (
                <div>
                  <img className="function_img" src="/lp/profile.png" />
                </div>
              ) : null}
            </Grid>
          )}
        </Grid>
        <SWAPSpace size={mobile ? 24 : 80} />
      </Container>
    </FunctionsWrap>
  );
};

const CompanyOneWrap = styled.div`
  .company_of_one {
    height: 375px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .company_of_one_text {
      position: relative;
      z-index: 2;
    }
    .box {
      padding: 8px 16px;
      border-radius: 12px;
      background: white;
    }
  }
`;
const CompanyOneSection = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <CompanyOneWrap>
      <div className="company_of_one">
        <img
          src={mobile ? "/lp/company_of_one_m.png" : "/lp/company_of_one.png"}
        />
        <div className="company_of_one_text">
          <Typography
            variant={mobile ? "h5" : "h2"}
            style={{ textAlign: "center" }}
            color="white"
          >
            SWAP 帶你迎接未來自由工作的趨勢
          </Typography>
          <SWAPSpace />
          <div className="box">
            <Typography
              variant={mobile ? "title" : "h5"}
              style={{ textAlign: "center" }}
            >
              一人接案也能像公司一樣高效運作！
            </Typography>
          </div>
        </div>
      </div>
    </CompanyOneWrap>
  );
};

const SWAPMemberWrap = styled.div`
  .member_profiles {
    overflow: hidden;
    background: linear-gradient(
      360deg,
      #c1c7ed 0%,
      rgba(236, 236, 236, 0) 94.94%
    );
  }
`;
const SWAPMemberSection = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <SWAPMemberWrap>
      <div style={{ overflow: "hidden" }}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
          spacing={2}
          className="member_profiles"
          wrap="nowrap"
        >
          <Grid item>
            <SWAPSpace size={mobile ? 24 : 75} />
          </Grid>
          <Grid item>
            <Typography
              variant={mobile ? "h4" : "h1"}
              style={{ textAlign: "center" }}
            >
              來自不同領域的自由工作者
            </Typography>
          </Grid>
          <Grid item>
            <SWAPSpace size={mobile ? 8 : 16} />
            <Typography
              variant={mobile ? "h6" : "h4"}
              style={{ textAlign: "center", fontWeight: "normal" }}
            >
              開始尋找適合的事業合作夥伴
            </Typography>
            <SWAPSpace size={mobile ? 56 : 72} />
          </Grid>
          <Grid item>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              wrap="nowrap"
              spacing={3}
            >
              {[
                {
                  title: "林小美",
                  subtitle: "平面設計",
                  description: "MEI DESIGN /發覺生活中的想像力 Branding ",
                },
                {
                  title: "曾益郁",
                  subtitle: "廣告行銷",
                  description: "FB 廣告、Google 關鍵字廣告，讓你被世界看見！",
                },
                {
                  title: "蕭子江",
                  subtitle: "APP 設計",
                  description: "8 年 APP 設計，精通 ios, android 設計規範！",
                },
                {
                  title: "吳怡君",
                  subtitle: "文字翻譯",
                  description: "美/日/韓 ↔ 中翻譯，3, 4 月份滿檔，急件請另洽！",
                },
                {
                  title: "HARMONY",
                  subtitle: "平面設計",
                  description: "Branding / Art Direction / Illustration",
                },
              ].map((item, index) => (
                <Grid item key={`item_${index}`}>
                  <Paper
                    style={{
                      padding: "24px",
                      width: mobile ? "275px" : "400px",
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      wrap="nowrap"
                      spacing={1}
                    >
                      <Grid item>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Avatar
                              src={`https://i.pravatar.cc/150?u=${item.title}${index}`}
                              variant="rounded"
                              style={{ width: "80px", height: "80px" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">{item.title}</Typography>
                            <Typography variant="body1">
                              {item.subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <img style={{ width: "27px" }} src="/lp/pass.png" />
                      </Grid>
                    </Grid>
                    <SWAPSpace />
                    <Typography variant="body2" color="black900">
                      {item.description}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <SWAPSpace size={24} />
          <Grid item>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              wrap="nowrap"
              spacing={3}
            >
              {[
                {
                  title: "RICHARD",
                  subtitle: "工程顧問",
                  description: "10 年經驗，AWS, GCP 雲端架構師",
                },
                {
                  title: "徐嘉育",
                  subtitle: "攝影與影像製作",
                  description: "YT 頻道剪片、上字幕、影片後製、動畫製作！",
                },
                {
                  title: "胡育霖",
                  subtitle: "網站程式",
                  description: "矽谷新創 javascript 全端工程師！",
                },
                {
                  title: "陳佳玲",
                  subtitle: "動畫音樂",
                  description: "金曲獎、金馬獎典禮影片配樂、作詞作曲！",
                },
              ].map((item, index) => (
                <Grid item key={`item_${index}`}>
                  <Paper style={{ padding: "24px", width: "400px" }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      wrap="nowrap"
                      spacing={1}
                    >
                      <Grid item>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Avatar
                              src={`https://i.pravatar.cc/150?u=${item.title}${index}`}
                              variant="rounded"
                              style={{ width: "80px", height: "80px" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">{item.title}</Typography>
                            <Typography variant="body1">
                              {item.subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <img style={{ width: "27px" }} src="/lp/pass.png" />
                      </Grid>
                    </Grid>
                    <SWAPSpace />
                    <Typography variant="body2" color="black900">
                      {item.description}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <SWAPSpace size={30} />
            <Button variant="primary">瀏覽更多工作者</Button>
            <SWAPSpace size={mobile ? 28 : 44} />
          </Grid>
        </Grid>
      </div>
    </SWAPMemberWrap>
  );
};

const testimonialData = [
  {
    name: "Lemon-Kao",
    title: "檸檬知識創新創辦人",
    avatarUrl:
      "https://swap-img.s3-ap-northeast-1.amazonaws.com/landingPageImg/Lemon-Kao.jpg",
    testimonial:
      "SWAP是陪伴我的好夥伴，幫助我在個人工作者時期，有跟廠商合作的請款工具。待我創辦公司後，又可以讓外包夥伴能開發票給我，不僅節省我的行政成本，還讓我有更大的合作彈性！",
  },
  {
    name: "Eric-Lin",
    title: "目標規劃教練",
    avatarUrl:
      "https://swap-img.s3-ap-northeast-1.amazonaws.com/landingPageImg/Eric-Lin.jpg",
    testimonial:
      "透過 SWAP 便利又有效率的服務，我可以將更多精力和時間專注於我的服務，而不是耗費在這些繁複的行政財務工作上！",
  },
  {
    name: "Gary Liaw",
    title: "醫療科技譯者",
    avatarUrl:
      "https://swap-img.s3-ap-northeast-1.amazonaws.com/landingPageImg/gary.jpeg",
    testimonial:
      "身為自由譯者，從找客戶到處理帳務，都要自己來。還好有 SWAP，讓帳務處理省了不少時間。遇到有特殊要求的業主，經由 SWAP 客服積極討論、迅速提供彈性方案，讓本來的麻煩事輕鬆解決，真的很方便！",
  },
  {
    name: "Jack Lee",
    title: "攝影師",
    avatarUrl: "https://i.pravatar.cc/150?u=AbigailLee",
    testimonial:
      "SWAP 讓每個工作者都有機會下班之餘發展個人事業，藉由平台上的機制，我可輕鬆地進行接案也不用擔心核銷，一直到現在全直接案仍幫助我省下很多時間！",
  },
  {
    name: "Scott Chen",
    title: "工程師",
    avatarUrl: "https://i.pravatar.cc/150?u=HarmonyChen",
    testimonial:
      "我通常會密集工作一年，接著一年到海島 Long Stay，SWAP 真的很智能，可以讓我過著想要的生活模式、專注提供好服務給客戶！",
  },
  {
    name: "Harmony Ma",
    title: "插畫師",
    avatarUrl: "https://i.pravatar.cc/150?u=JackMa",
    testimonial:
      "在 SWAP 上我可以保持在一人接案的狀態，同時對接到各個大型公司！",
  },
];
const TestimonialWrap = styled.div`
  .testimonial {
    background: linear-gradient(
      180deg,
      #fef8e3 13.71%,
      rgba(255, 255, 255, 1) 100%
    );
    .testimonial_m {
      padding: 24px;
      width: 100%;
      box-sizing: border-box;
      box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
      border: none;
    }
    .testimonial_slide_wrap {
      .testimonial_slide_paper {
        padding: 24px;
        width: 400px;
        box-sizing: border-box;
        box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
        border: none;
      }
    }
  }
`;
const TestimonialSection = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <TestimonialWrap>
      <div style={{ background: "#C1C7ED" }}>
        <img
          src="/lp/wave.png"
          style={{
            display: "block",
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
      <div className="testimonial">
        <SWAPSpace size={mobile ? 24 : 74} />
        <Grid container justifyContent="center" spacing={1}>
          <Grid item>
            <Typography variant={mobile ? "h4" : "h1"}>💬 超過</Typography>
          </Grid>
          <Grid item>
            <Typography variant={mobile ? "h4" : "h1"} color="secondary800">
              1,500+
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={mobile ? "h4" : "h1"}>
              位自由工作者已經加入
            </Typography>
          </Grid>
        </Grid>
        <SWAPSpace size={mobile ? 8 : 16} />
        <Typography
          variant={mobile ? "h6" : "h4"}
          style={{ textAlign: "center", fontWeight: "normal" }}
        >
          聽聽他們使用後的分享
        </Typography>
        <SWAPSpace size={mobile ? 16 : 72} />

        {mobile ? (
          <Container maxWidth="lg">
            <Grid container direction="column">
              {testimonialData.map((item, index) => {
                return (
                  <Grid item key={`${item.name}_${index}`} xs={12}>
                    <Paper className="testimonial_m">
                      <Typography variant="body2_loose" color="black800">
                        {item.testimonial}
                      </Typography>
                      <SWAPSpace />
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Avatar
                            variant="rounded"
                            src={item.avatarUrl}
                            style={{ width: "56px", height: "56px" }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" color="primary">
                            {item.name}
                          </Typography>
                          <Typography variant="body2">{item.title}</Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                    <SWAPSpace
                      size={index === testimonialData.length - 1 ? 24 : 16}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        ) : null}
        {mobile ? null : (
          <div
            className="testimonial_slide_wrap"
            style={{ height: mobile ? "auto" : "360px" }}
          >
            <Slider
              {...{
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                centerMode: true,
                autoplay: true,
                autoplaySpeed: 5000,
                adaptiveHeight: true,
              }}
            >
              {testimonialData.map((item, index) => (
                <div key={`testimonial${index}`} className="testimonial_slide">
                  <Paper className="testimonial_slide_paper">
                    <Typography variant="body2_loose" color="black800">
                      {item.testimonial}
                    </Typography>
                    <SWAPSpace />
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Avatar
                          variant="rounded"
                          src={item.avatarUrl}
                          style={{ width: "56px", height: "56px" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" color="primary">
                          {item.name}
                        </Typography>
                        <Typography variant="body2">{item.title}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  <SWAPSpace size={70} />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </TestimonialWrap>
  );
};

const CtaWrap = styled.div`
  .cta_area {
    display: block;
    width: 100%;
    margin: 0 auto;
    background: linear-gradient(87.35deg, #4862cc 0%, #c1c7ed 99.77%);
    border-radius: 24px;
    display: flex;
    align-items: center;
    .desktop_img {
      margin-right: -65px;
      margin-bottom: -48px;
      width: 510px;
      height: 213px;
      display: block;
      object-fit: contain;
    }
  }
  .mobile_img {
    width: 100%;
  }
`;
const CtaSection = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <CtaWrap>
      <Container maxWidth="lg">
        <SWAPSpace size={mobile ? 24 : 75} />
        {mobile ? <img className={"mobile_img"} src="/lp/docs.png" /> : null}
        <div
          className="cta_area"
          style={{
            padding: mobile ? "24px 24px" : "48px 65px",
          }}
        >
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent={mobile ? "flex-start" : "space-between"}
            direction="row"
          >
            <Grid item {...(mobile ? { xs: 12 } : null)}>
              <Typography variant="h2" color="white">
                加入 SWAP
              </Typography>
              <SWAPSpace size="xs" />
              <Typography variant="h2" color="white">
                開始你的個人事業
              </Typography>
              <SWAPSpace size={24} />
              <Button
                variant="secondary"
                endIcon={<ArrowForwardOutlinedIcon />}
                fullWidth={mobile}
              >
                開始使用
              </Button>
            </Grid>
            {mobile ? null : (
              <Grid item>
                <img className={"desktop_img"} src="/lp/docs.png" />
              </Grid>
            )}
          </Grid>
        </div>
      </Container>
      <SWAPSpace size={mobile ? 24 : 75} />
    </CtaWrap>
  );
};

const Lp2Wrap = styled.div`
  background: white;
  overflow: hidden;
`;
const Lp2 = () => {
  return (
    <Lp2Wrap>
      <Navigation />
      <HeroSection />
      <PartnersSection />
      <ValueSection />
      <HowItWorkSection />
      <FunctionsSection />
      <CompanyOneSection />
      <SWAPMemberSection />
      <TestimonialSection />
      <CtaSection />/
      <Footer />
    </Lp2Wrap>
  );
};

export default Lp2;
