/**
 * https://github.com/vasturiano/react-globe.gl/blob/master/example/airline-routes/highlight-links.html
 */

import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Globe from "react-globe.gl";
import MockData from "./MockData";

const MAP_CENTER = { lat: 23.6, lng: 120.6, altitude: 0.15 };
const OPACITY = 0.1;
const DATA = MockData.filter(
  (item) => item.company_address !== "" && item.company_lat && item.company_lng
);

const GlobeWrap = styled.div``;

export default function GlobeComponent() {
  const globeEl = useRef();
  const [hoverArc, setHoverArc] = useState();

  useEffect(() => {
    // load data
    globeEl.current.pointOfView(MAP_CENTER, 4000);
  }, []);

  return (
    <GlobeWrap>
      <Globe
        animateIn={false}
        ref={globeEl}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        arcsData={DATA}
        arcLabel={(d) =>
          `${new Date(d.paid_at).toDateString()}：SWAP 會員 ➡ ${d.company_name}`
        }
        arcStartLat={(d) => d.member_lat}
        arcStartLng={(d) => d.member_lng}
        arcEndLat={(d) => d.company_lat}
        arcEndLng={(d) => d.company_lng}
        arcDashLength={0.4}
        arcDashGap={0.2}
        arcDashAnimateTime={3000}
        arcsTransitionDuration={0}
        arcColor={(d) => {
          const op = !hoverArc ? OPACITY : d === hoverArc ? 0.9 : OPACITY / 4;
          return [`rgba(0, 255, 0, ${op})`, `rgba(255, 0, 0, ${op})`];
        }}
        arcStroke={0.03}
        onArcHover={(d) => {
          setHoverArc(d);
        }}
        pointsData={DATA.map((item) => ({
          member_lat: item.member_lat,
          member_lng: item.member_lng,
        }))}
        pointColor={() => "rgba(255,255,255,0.95)"}
        pointAltitude={0}
        pointRadius={0.02}
        pointsMerge={true}
        pointLat={(d) => d.member_lat}
        pointLng={(d) => d.member_lng}
        pointLabel={(d) => d.member_lat}
      />
    </GlobeWrap>
  );
}
