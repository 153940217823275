import { Container, Grid } from "@material-ui/core";
import {
  Button,
  SWAPSpace,
  Typography,
  Modal,
  CircularProgress,
} from "@yosgo/swap-ui";
import styled from "styled-components";
import Globe from "react-globe.gl";
import { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import Mosaic from "react-photo-mosaic";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import MockData from "./MockData";
import { Link } from "react-router-dom";

const STAT_DATA_HEIGHT = "150px";
const DATA = MockData.map((item) => ({
  //   alt: Math.random() * 0.8 + 0.1,
  ...item,
  a_lat: (Math.random() - 0.5) * 180,
  a_lng: (Math.random() - 0.5) * 360,
  b_lat: (Math.random() - 0.5) * 180,
  b_lng: (Math.random() - 0.5) * 360,
  alt: 1,
  radius: Math.random() * 5,
})).reverse();
const CUSTOMDATA = [
  {
    label: "swap",
    lat: 46.64641057943983,
    lng: -47.713867834319245,
    alt: -0.3,
    model: "/swap_icon.gltf",
    obj: undefined,
    scale: [0.15, 0.15, 0.15],
    rotation: [-0.8, -1, -1],
  },
  //   {
  //     label: "flag",
  //     lat: 44.00482484586686,
  //     lng: -70.53935420614076,
  //     alt: 0,
  //     model: "/flag.gltf",
  //     obj: undefined,
  //     scale: [10, 10, 10],
  //     rotation: [-2, -3, -1],
  //   },
  //   {
  //     label: "flag",
  //     lat: 23.54382950164728,
  //     lng: -35.29137585752264,
  //     alt: 0,
  //     model: "/flag.gltf",
  //     obj: undefined,
  //     scale: [10, 10, 10],
  //     rotation: [-1, -3, -1],
  //   },
];
const IMAGEDATA = new Array(435).fill("").map((_, index) => {
  return "/mosaic/" + `${index}` + ".JPG";
});

const HeroWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #000010;
  #globe_container {
    height: calc(100vh - ${STAT_DATA_HEIGHT});
  }
  #hero_divider {
    height: 0.1px;
    background: rgba(255, 255, 255, 0.22);
  }
  #stat_data {
    height: ${STAT_DATA_HEIGHT};
  }
  #logo {
    position: absolute;
    top: 36px;
    left: 12px;
    height: 49px;
  }
`;

export default function Hero() {
  const globeEl = useRef();
  const [gWidth, setgWidth] = useState(0);
  const [gHeight, setgHeight] = useState(0);
  const [customData, setCustomData] = useState(undefined);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  //讀取球體模型
  useEffect(() => {
    //載入自訂模型
    const loadModel = (modelURL, index) => {
      const loader = new GLTFLoader();
      return new Promise((resolve) => {
        loader.load(
          modelURL,
          function (gltf) {
            const value = CUSTOMDATA.filter(
              (item) => item.model === modelURL
            )[0];
            //將模型放在球體上
            gltf.scene.scale.set(
              value.scale[0],
              value.scale[1],
              value.scale[2]
            );
            gltf.scene.rotation.set(
              value.rotation[0],
              value.rotation[1],
              value.rotation[2]
            );
            resolve({
              ...value,
              obj: gltf.scene,
            });
          },
          undefined,
          function (error) {
            console.error(error);
          }
        );
      });
    };
    Promise.all(
      CUSTOMDATA.map((item, index) => loadModel(item.model, index))
    ).then((r) => {
      setCustomData(r);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    });
  }, []);

  //調整球體
  useEffect(() => {
    if (!loading) {
      const g = document.getElementById("globe_container");
      if (g) {
        setgWidth(g.offsetWidth);
        setgHeight(g.offsetHeight);
      }

      globeEl.current.pointOfView({
        altitude: 3,
        lat: 35.72879875422176,
        lng: -37.43558425649702,
      });

      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = 0.3;
      globeEl.current.controls().enableRotate = true;
      globeEl.current.controls().enableZoom = true;
    }
  }, [loading]);

  //更新資料
  useEffect(() => {
    if (!loading) {
      const count = 10;
      let index = 0;
      setData(DATA.slice(count * index + index, count * (index + 1)));
      setInterval(() => {
        if (index * count > DATA.length) {
          index = 0;
          setData(DATA.slice(count * index + index, count * (index + 1)));
        } else {
          index = index + 1;
          setData(DATA.slice(count * index + index, count * (index + 1)));
        }
      }, 60000);
    }
  }, [loading]);

  return (
    <HeroWrap>
      {loading ? (
        <CircularProgress size={20} thickness={5} />
      ) : (
        <div>
          <Container maxWidth="lg" style={{ position: "relative" }}>
            <Link to="/">
              <img id="logo" src="/swap_white.png" />
            </Link>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography variant="d1" color="white">
                  自由工作者的財務管理平台
                </Typography>
                <SWAPSpace />
                <Typography variant="body1_loose" color="white">
                  一站式管理所有案件的報價單與請款單，線上向客戶請款並開立發票。讓你把專注力用在最有價值的地方，複雜的財務就讓
                  SWAP 來幫助你吧！
                  {/* Millions of developers and companies build, ship, and maintain their software on GitHub—the largest and most advanced development platform in the world. */}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div id="globe_container">
                  <Globe
                    //Globe
                    onGlobeClick={({ lat, lng }) => {
                      console.log({ lat, lng });
                    }}
                    ref={globeEl}
                    width={gWidth}
                    height={gHeight}
                    globeImageUrl="https://i.imgur.com/6PRX8j3.jpg"
                    atmosphereColor="#347098"
                    atmosphereAltitude="0.3"
                    //Arc
                    arcsData={data}
                    arcLabel={(d) =>
                      `<div style="padding:18px; background: rgba(0,0,0,0.78); border-radius: 6px;">
                      <p style="margin: 0 0 3px 0; font-size: 14px; font-weight: bold;">結案於 ${new Date(
                        d.paid_at
                      )
                        .toISOString()
                        .slice(0, 7)}</p>
                      <p style="margin: 0; font-size: 12px;">會員與${
                        d.company_name
                      }的合作</p>
                      </div>`
                    }
                    arcStartLat={(d) => d.a_lat}
                    arcStartLng={(d) => d.a_lng}
                    arcEndLat={(d) => d.b_lat}
                    arcEndLng={(d) => d.b_lng}
                    arcDashAnimateTime={3000}
                    arcsTransitionDuration={0}
                    arcColor={() => "#ffffff"}
                    arcStroke={1.5}
                    arcCurveResolution={120}
                    arcCircularResolution={120}
                    arcDashLength={0.6}
                    arcDashGap={0.3}
                    arcAltitude={0.4}
                    onArcClick={() => {}}
                    onArcHover={(arc, prev) => {
                      if (arc) {
                        globeEl.current.controls().autoRotate = false;
                      } else {
                        globeEl.current.controls().autoRotate = true;
                      }
                    }}
                    //Point
                    pointsData={data
                      .map((item) => ({
                        lat: item.b_lat,
                        lng: item.b_lng,
                      }))
                      .concat(
                        data.map((item) => ({
                          lat: item.a_lat,
                          lng: item.a_lng,
                        }))
                      )}
                    pointColor={() => "rgba(255,255,255,1)"}
                    pointAltitude={0}
                    pointRadius={0.9}
                    pointLat={(d) => d.lat}
                    pointLng={(d) => d.lng}
                    //Ring
                    ringsData={data.map((item) => ({
                      lat: item.b_lat,
                      lng: item.b_lng,
                    }))}
                    ringLat={(d) => d.lat}
                    ringLng={(d) => d.lng}
                    ringColor={() => "rgba(255,255,255,0.9)"}
                    ringRepeatPeriod={3000}
                    ringResolution={120}
                    //Custom
                    customLayerData={customData ? customData : []}
                    customThreeObject={(d) => {
                      const value = d.obj;
                      return value;
                      // return new THREE.Mesh(
                      //   new THREE.SphereBufferGeometry(3),
                      //   new THREE.MeshLambertMaterial({ color: "white" })
                      // );
                    }}
                    customThreeObjectUpdate={(obj, d) => {
                      Object.assign(
                        obj.position,
                        globeEl.current.getCoords(d.lat, d.lng, d.alt)
                      );
                    }}
                    onCustomLayerClick={(obj) => {
                      setOpen(true);
                      globeEl.current.controls().autoRotate = false;
                    }}
                    onCustomLayerHover={(obj) => {
                      if (obj) {
                        globeEl.current.controls().autoRotate = false;
                      } else {
                        globeEl.current.controls().autoRotate = true;
                      }
                    }}
                  />
                </div>
                <Modal
                  title="SWAP 歡迎你的到來"
                  size="medium"
                  open={open}
                  onClose={() => {
                    setOpen(false);
                    globeEl.current.controls().autoRotate = true;
                  }}
                  children={
                    <>
                      <Typography variant="body2_loose">
                        我們相信自由工作者是未來的主流工作型態，SWAP
                        的使命是透過網路與科技上的創新來改善 Freelancers
                        的工作體驗與接案市場的效率！
                      </Typography>
                      <SWAPSpace />
                      <Typography variant="body2_loose">
                        下方馬賽克是由 SWAP 協助 Freelancers
                        拍攝個人形象照活動的照片組成。
                      </Typography>
                      <SWAPSpace size="xs" />
                      <div
                        style={{
                          minWidth: "580px",
                          minHeight: "497px",
                          position: "relative",
                        }}
                      >
                        <Mosaic
                          images={IMAGEDATA}
                          mainImagePath={"/swap.png"}
                          width={580}
                          height={497}
                          maxBlocksInRow={24}
                          spaceBetween={1}
                          onClick={() => {}}
                        />
                      </div>
                      <SWAPSpace />
                    </>
                  }
                  primaryButton={{
                    title: "返回",
                    onClick: () => {
                      setOpen(false);
                      globeEl.current.controls().autoRotate = true;
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="lg">
            <div id="hero_divider" />
            <Grid container id="stat_data" alignItems="center" spacing={1}>
              {[
                {
                  label: "55,000 +", //423 * 24 * 5.49
                  text: "案件累積時數",
                },
                {
                  label: "1,200 +",
                  text: "自由工作者會員",
                },
                {
                  label: "200 +",
                  text: "會員合作的客戶",
                },
                {
                  label: "10 +",
                  text: "自由工作者類型",
                },
              ].map((item, index) => (
                <Grid
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                  item
                  key={`${item.label}_${index}`}
                >
                  <Typography
                    variant="h3"
                    color="white"
                    style={{ fontWeight: "normal" }}
                  >
                    {item.label}
                  </Typography>
                  <Typography variant="body2_loose" color="white">
                    {item.text}
                  </Typography>
                </Grid>
              ))}
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                container
                direction="row-reverse"
              >
                <Button
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => {
                    window.location.href = "https://swap.work";
                  }}
                >
                  成為 SWAP 的會員
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </HeroWrap>
  );
}
