import { Container, Grid } from "@material-ui/core";
import { Button, Chip, SWAPSpace, Typography, Modal } from "@yosgo/swap-ui";
import styled from "styled-components";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import { gsap } from "gsap";
import { useEffect, useState } from "react";

const TransactionWrap = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: black;
  .dot_line {
    border: 1px dashed white;
    height: 1px;
    width: 100%;
  }
  .line_to_right {
    position: relative;
    &:after {
      position: absolute;
      top: -5px;
      right: -3px;
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 10px;
      border-color: transparent transparent transparent #ffffff;
    }
  }
  .line_to_left {
    position: relative;
    &:after {
      position: absolute;
      top: -5px;
      left: -3px;
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 10px 5px 0;
      border-color: transparent #ffffff transparent transparent;
    }
  }
  .chipWrap {
    display: flex;
    justify-content: center;
    margin: 12px 0;
    cursor: pointer;
  }
`;

const RuleWrap = styled.div`
  text-align: center;
  position: relative;
  .avatar {
    width: 120px;
    height: 120px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      display: block;
      object-fit: contain;
      padding: 6px;
      border-radius: 50%;
    }
  }
  .label {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -30px;
    text-align: center;
  }
`;

const transactionDetails = [
  {
    title: "支付 10,500 元案件款項",
    description:
      "一級棒股份有限公司可以透過 SWAP 請款單上的虛擬帳號來支付案件的款項。付款的金額是含稅金額",
    img: "/detail_01.png",
  },
  {
    title: "開立同額電子發票",
    description:
      "在一級棒股份有限公司付款後，SWAP 將開立相同金額的電子發票。例如客戶付款 10,500 元，發票金額為 10,500 元。",
    img: "/detail_02.png",
  },
  {
    title: "提領 10,000 元案件所得",
    description:
      "案件款項扣除營業稅 500 元後，David 的 SWAP 帳戶中有 10,000 元的案件款項可以提領。",
    img: "/detail_03.png",
  },
  {
    title: "申報等額個人所得",
    description:
      "David 在提領案件款項時，SWAP 系統將扣除代扣繳的所得稅與二代健保補充保費，並自動為 David 申報個人所得。",
    img: "/detail_04.png",
  },
];

const Rule = ({ img, label }) => {
  return (
    <RuleWrap>
      <div className="avatar">
        <img src={img} />
      </div>
      <div className="label">
        <Typography
          color="white"
          variant="body2_loose"
          style={{ fontSize: "12px" }}
        >
          {label}
        </Typography>
      </div>
    </RuleWrap>
  );
};

const Transaction = () => {
  const [modal, setModal] = useState(false);
  const [currDetail, setCurrDetail] = useState(transactionDetails[0]);
  useEffect(() => {
    var tl = gsap.timeline({ repeat: 0, repeatDelay: 0 });
    tl.fromTo(
      ".tl_1",
      { y: 10, opacity: 0 },
      {
        y: 0,
        duration: 0.6,
        opacity: 1,
      }
    )
      .fromTo(
        ".tl_2",
        {
          y: 10,
          opacity: 0,
        },
        {
          y: 0,
          duration: 0.6,
          opacity: 1,
        }
      )
      .fromTo(
        ".tl_3",
        {
          y: 10,
          opacity: 0,
        },
        {
          y: 0,
          duration: 0.6,
          opacity: 1,
        }
      )
      .fromTo(
        ".tl_4",
        { x: -30, opacity: 0 },
        {
          duration: 1,
          opacity: 1,
          x: 0,
        }
      )
      .fromTo(
        ".tl_5",
        { x: 30, opacity: 0 },
        {
          duration: 1,
          opacity: 1,
          x: 0,
        }
      )
      .fromTo(
        ".tl_6",
        { x: 30, opacity: 0 },
        {
          duration: 1,
          opacity: 1,
          x: 0,
        }
      )
      .fromTo(
        ".tl_7",
        { x: -30, opacity: 0 },
        {
          duration: 1,
          opacity: 1,
          x: 0,
        }
      );
  }, []);

  return (
    <div>
      <Modal
        size="medium"
        title={currDetail.title}
        open={modal}
        onClose={() => setModal(false)}
        children={
          <div>
            <Typography variant="body1">{currDetail.description}</Typography>
            <SWAPSpace />
            <img
              src={currDetail.img}
              style={{
                width: "100%",
                display: "block",
                border: "1px solid rgba(0,0,0,0.1)",
              }}
            />
          </div>
        }
      />
      <TransactionWrap>
        <Container maxWidth="lg">
          <Typography color="white" variant="h1">
            更靈活、更彈性、更專業
          </Typography>
          <SWAPSpace />
          <Grid container alignItems="center">
            <Grid item>
              <Typography
                color="white"
                variant="h6"
                style={{ fontWeight: "lighter" }}
              >
                讓我們來看看 David 與一級棒股份有限公司是如何透過 SWAP
                完成交易！
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="tertiary"
                style={{ background: "black", color: "white" }}
                onClick={() => window.location.reload()}
              >
                重新播放
              </Button>
            </Grid>
          </Grid>
          <SWAPSpace size="4xl" />
          <SWAPSpace size="4xl" />
          <div>
            <Grid
              container
              alignItems="center"
              spacing={3}
              justifyContent="space-between"
            >
              <Grid item>
                <div className="tl tl_1">
                  <Rule
                    label="一級棒股份有限公司"
                    img="https://luna1.co/new-branding-hp-exp/faces/unknown-name.png"
                  />
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div className="tl tl_4">
                  <div className="chipWrap">
                    <Chip
                      flexDirection="row-reverse"
                      label="客戶支付 10,500 元案件款項"
                      icon={<LooksOneIcon fontSize="12px" />}
                      onClick={() => {
                        setCurrDetail(transactionDetails[0]);
                        setModal(true);
                      }}
                    />
                  </div>
                  <div className="dot_line line_to_right" />
                </div>
                <SWAPSpace size="2xl" />
                <div className="tl tl_5">
                  <div className="dot_line line_to_left" />
                  <div className="chipWrap">
                    <Chip
                      label="開立同額電子發票"
                      icon={<LooksTwoIcon fontSize="12px" />}
                      onClick={() => {
                        setCurrDetail(transactionDetails[1]);
                        setModal(true);
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className="tl tl_2">
                  <Rule label="SWAP Platform" img="/SWAP_web logo-05.png" />
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div className="tl tl_6">
                  <div className="chipWrap">
                    <Chip
                      label="提領 10,000 元案件所得"
                      icon={<Looks3Icon fontSize="12px" />}
                      onClick={() => {
                        setCurrDetail(transactionDetails[2]);
                        setModal(true);
                      }}
                    />
                  </div>
                  <div className="dot_line line_to_left" />
                </div>
                <SWAPSpace size="2xl" />
                <div className="tl tl_7">
                  <div className="dot_line line_to_right" />
                  <div className="chipWrap ">
                    <Chip
                      label="申報等額個人所得"
                      icon={<Looks4Icon fontSize="12px" />}
                      onClick={() => {
                        setCurrDetail(transactionDetails[3]);
                        setModal(true);
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className="tl tl_3">
                  <Rule
                    label="David"
                    img="https://luna1.co/new-branding-hp-exp/faces/ahmet-aslan.png"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </TransactionWrap>
    </div>
  );
};
export default Transaction;
