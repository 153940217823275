import React, { useEffect, useState } from "react";
import { select } from "d3";
import * as d3 from "d3-geo";
import { Avatar, Container, Drawer, Grid, Tooltip } from "@material-ui/core";
import { SWAPSpace, Typography, Button, TextField } from "@yosgo/swap-ui";
import styled from "styled-components";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";

import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BusinessIcon from "@material-ui/icons/Business";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { Link } from "react-router-dom";

const companyData = [
  {
    company_name: "天城影像有限公司",
    comany_id: 66575168,
    company_boos: "王永煜",
    company_address: "新北市中和區圓通路295之3號13樓",
    geo: [24.988392680385125, 121.48733395536703],
  },
  {
    company_name: "絕色時尚股份有限公司",
    comany_id: 25123556,
    company_boos: "林宗昱",
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    geo: [25.082402280069136, 121.56249542653265],
  },
  {
    company_name: "拓治股份有限公司",
    comany_id: 12565208,
    company_boos: "傅仁傑",
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    geo: [25.05264987062541, 121.61078339769568],
  },
  {
    company_name: "山品壹合有限公司",
    comany_id: 50888137,
    company_boos: "張凱祈",
    company_address: "臺北市中山區民生東路3段2號7樓",
    geo: [25.057870941210545, 121.53772884187723],
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    comany_id: 83243587,
    company_boos: "高永祺",
    company_address: "臺北市松山區八德路3段218號7樓",
    geo: [25.048230359561394, 121.55696615536817],
  },
  {
    company_name: "德立媒體行銷有限公司",
    comany_id: 53129146,
    company_boos: "鄒樹立",
    company_address: "臺北市松山區光復北路112號10樓",
    geo: [25.05107643454496, 121.55768245536812],
  },
  {
    company_name: "萱普艾森國際有限公司",
    comany_id: 90693968,
    company_boos: "黃韻純",
    company_address: "高雄市苓雅區中華四路2號13樓",
    geo: [22.61217268775283, 120.3028854399785],
  },
  {
    company_name: "維斯塔行銷顧問有限公司",
    comany_id: 83434777,
    company_boos: "李芸",
    company_address: "新北市中和區建八路2號9樓之2",
    geo: [24.998454430437715, 121.48624615351346],
  },
  {
    company_name: "虹彩服飾股份有限公司",
    comany_id: 82911239,
    company_boos: "陳曉婷",
    company_address: "新北市土城區中華路2段212號2樓",
    geo: [24.968790384411175, 121.4371158130391],
  },
  {
    company_name: "明基電通股份有限公司",
    comany_id: 70721522,
    company_boos: "李焜耀",
    company_address: "臺北市內湖區基湖路16號",
    geo: [25.081503713383793, 121.56457386885987],
  },
  {
    company_name: "享萊股份有限公司",
    comany_id: 24569221,
    company_boos: "鄭翰霖",
    company_address: "臺北市大同區承德路3段287之2號",
    geo: [25.074946155574704, 121.52074008235103],
  },
  {
    company_name: "群眾自造股份有限公司",
    comany_id: 52354974,
    company_boos: "蘇民",
    company_address: "臺北市中山區民生東路3段2號7樓",
    geo: [25.057880660252955, 121.5376966553684],
  },
  {
    company_name: "若晨室內裝修有限公司",
    comany_id: 83075603,
    company_boos: "邱耀儀",
    company_address: "新北市新莊區新崑路179號3樓",
    geo: [25.000404103275404, 121.43315901118584],
  },
  {
    company_name: "晨紘國際有限公司",
    comany_id: 82806834,
    company_boos: "楊宇宸",
    company_address: "臺北市士林區中正路601號8樓之7",
    geo: [25.086700186294244, 121.51004991304148],
  },
  {
    company_name: "凱莉愛內衣股份有限公司",
    comany_id: 59261640,
    company_boos: "張賀森",
    company_address: "高雄市苓雅區興中二路69號6樓之2",
    geo: [22.614607679431714, 120.3009377264874],
  },
  {
    company_name: "寵物百分百股份有限公司",
    comany_id: 83201117,
    company_boos: "黃聰圍",
    company_address: "高雄市仁武區鳳仁路231號1樓",
    geo: [22.69818043889813, 120.34659954183394],
  },
  {
    company_name: "沃爾斯丹國際有限公司",
    comany_id: 91069127,
    company_boos: "趙健閎",
    company_address: "新北市樹林區三龍街45巷2號",
    geo: [25.018891215368246, 121.41451229769494],
  },
  {
    company_name: "寬陽台空間創創股份有限公司",
    comany_id: 83382010,
    company_boos: "劉家彤",
    company_address: "臺北市大安區復興南路2段327號14樓之2",
    geo: [25.024013296915605, 121.54375519769518],
  },
  {
    company_name: "路遙力股份有限公司",
    comany_id: 83799706,
    company_boos: "",
    company_address: "臺北市中山區南京東路2段69號8樓",
    geo: [25.052485012488205, 121.53051141118695],
  },
  {
    company_name: "紘祥實業有限公司",
    comany_id: 50793293,
    company_boos: "謝苙蓉",
    company_address: "臺北市中正區重慶南路1段95號11樓",
    geo: [25.0438871274882, 121.5130123265319],
  },
  {
    company_name: "建儒科技文教有限公司",
    comany_id: 24969916,
    company_boos: "鄭積緯",
    company_address: "臺北市中正區衡陽路51號1樓、4樓之6、4樓之7",
    geo: [],
  },
  {
    company_name: "奔跑山豬運動事業有限公司",
    comany_id: 85088168,
    company_boos: "溫昌霖",
    company_address: "花蓮縣花蓮市主睦里成功街145號一、二樓",
    geo: [25.04278997194495, 121.5121454014452],
  },
  {
    company_name: "目目和設計有限公司",
    comany_id: 54874509,
    company_boos: "王俐婷",
    company_address: "新北市永和區國中路14號4樓之1",
    geo: [25.00513127180433, 121.5244660111859],
  },
  {
    company_name: "灼見文創有限公司",
    comany_id: 85076297,
    company_boos: "趙曉慧",
    company_address: "新北市三峽區復興路162號6樓之1",
    geo: [24.938669082170655, 121.37114514002094],
  },
  {
    company_name: "伽馬實業有限公司",
    comany_id: 24959852,
    company_boos: "房彥文",
    company_address: "臺北市大同區迪化街1段14巷38號",
    geo: [25.05433368992244, 121.50938131304075],
  },
  {
    company_name: "自由人藝術設計股份有限公司",
    comany_id: 53874137,
    company_boos: "顏寧志",
    company_address: "臺中市西屯區西平南巷5弄12號1樓",
    geo: [24.195012757463314, 120.6171202283697],
  },
  {
    company_name: "歐洲之星貿易有限公司",
    comany_id: 54734108,
    company_boos: "黃凡恩",
    company_address: "新北市板橋區中山路2段62號7樓之3",
    geo: [25.016050032656103, 121.47415061303998],
  },
  {
    company_name: "詠越實業有限公司",
    comany_id: 90508274,
    company_boos: "陳信佑",
    company_address: "桃園市桃園區新埔六街128號(1樓)",
    geo: [],
  },
  {
    company_name: "維肯媒體股份有限公司",
    comany_id: 55734512,
    company_boos: "文子瑋",
    company_address: "臺北市信義區菸廠路88號5樓之5",
    geo: [25.016574386777826, 121.30182921304008],
  },
  {
    company_name: "勝騰緩衝研發股份有限公司",
    comany_id: 80039415,
    company_boos: "王豐達",
    company_address: "新北市新莊區中和街118號8樓",
    geo: [25.047867040398753, 121.44759916885937],
  },
  {
    company_name: "夢想家房屋經紀有限公司",
    comany_id: 83490812,
    company_boos: "許蕙甄",
    company_address: "桃園市龜山區樂善二路193號",
    geo: [25.046688242822224, 121.38433882467795],
  },
  {
    company_name: "信佳人資服務有限公司",
    comany_id: 80636454,
    company_boos: "温永盛",
    company_address: "桃園市中壢區自強里莊敬路119號",
    geo: [24.969832656485032, 121.25749478420298],
  },
  {
    company_name: "艾思奇整合行銷股份有限公司",
    comany_id: 91101920,
    company_boos: "柯竺君",
    company_address: "嘉義縣中埔鄉和美村大義路118號一樓",
    geo: [23.453178546959595, 120.47816344184731],
  },
  {
    company_name: "歐奇國際事業有限公司",
    comany_id: 24754204,
    company_boos: "李建廷",
    company_address: "臺北市中山區合江街20巷35號1樓、地下",
    geo: [25.055513984195535, 121.53798936885944],
  },
  {
    company_name: "數字科技股份有限公司",
    comany_id: 28466605,
    company_boos: "廖世芳",
    company_address: "新北市三重區重新路5段609巷12號10樓",
    geo: [25.046817722375955, 121.46850541304076],
  },
  {
    company_name: "閱野文創股份有限公司",
    comany_id: 54289140,
    company_boos: "吳奇靜",
    company_address: "新北市三峽區大學路103號",
    geo: [24.942223989124074, 121.37569648420246],
  },
  {
    company_name: "春富光電科技有限公司",
    comany_id: 54040373,
    company_boos: "林素卿",
    company_address: "新北市樹林區太元街110巷14號",
    geo: [24.977950570890215, 121.41932092653042],
  },
  {
    company_name: "玖惟股份有限公司",
    comany_id: 90702468,
    company_boos: "劉桓睿",
    geo: [],
    company_address: "臺北市大安區和平東路2段265巷3號1樓",
    geo: [25.025535981110867, 121.54410899769519],
  },
  {
    company_name: "英格斯能源股份有限公司",
    comany_id: 91086370,
    company_boos: "楊景棋",
    company_address: "臺中市北區中清路一段79號5樓",
    geo: [24.154845841939547, 120.67721042836897],
  },
  {
    company_name: "超級傳家泰潤股份有限公司",
    comany_id: 90889029,
    company_boos: "許自宏",
    company_address: "臺北市內湖區行愛路57號1樓",
    geo: [25.062628313468753, 121.58147081118712],
  },
  {
    company_name: "采傑整合行銷國際有限公司",
    comany_id: 83410986,
    company_boos: "傅靖雯",
    company_address: "臺北市大安區忠孝東路4段107號12樓",
    geo: [25.041917529310243, 121.54829816885936],
  },
  {
    company_name: "少女凱倫有限公司",
    comany_id: 83799754,
    company_boos: "花芸曦",
    company_address: "臺北市中正區羅斯福路3段244巷9弄2之1號",
    geo: [25.017483884486218, 121.53007099769493],
  },
  {
    company_name: "為烽科技股份有限公司",
    comany_id: 82809987,
    company_boos: "蔡駿騵",
    company_address: "臺北市士林區後港街147號1樓",
    geo: [25.087270935024744, 121.52146524002393],
  },
  {
    company_name: "資創國際保險經紀人股份有限公司",
    comany_id: 50896158,
    company_boos: "李易遠",
    company_address: "臺北市松山區復興北路167號8樓之2",
    geo: [25.054169370844143, 121.54432079769572],
  },
  {
    company_name: "共專有限公司",
    comany_id: 90776398,
    company_boos: "",
    company_address: "新北市新莊區中正路829巷19號7樓",
    geo: [25.027944955288387, 121.42119615536775],
  },
  {
    company_name: "博克科技股份有限公司",
    comany_id: 12675508,
    company_boos: "蔡錦承",
    company_address: "臺北市內湖區新湖二路162號4樓之1",
    geo: [25.063352949399327, 121.57969549769585],
  },
  {
    company_name: "品則股份有限公司",
    comany_id: 55771115,
    company_boos: "林詩懷",
    company_address: "臺北市信義區基隆路2段77號4樓之7",
    geo: [25.030459950903793, 121.55813188420409],
  },
  {
    company_name: "紘祥數位科技教育有限公司",
    comany_id: 50798939,
    company_boos: "謝苙蓉",
    company_address: "臺北市中山區建國北路2段129號2樓",
    geo: [25.060192361288024, 121.53736788420464],
  },
  {
    company_name: "晰穀有限公司",
    comany_id: 50866181,
    company_boos: "洪巧芬",
    company_address: "臺北市萬華區萬大路277巷8號1樓",
    geo: [25.025545081094187, 121.50093554187661],
  },
  {
    company_name: "皇錩實業有限公司",
    comany_id: 22559839,
    company_boos: "許麗琴",
    company_address: "新北市三重區國道路1段85巷3號(1樓)",
    geo: [25.072899294721957, 121.47997177071362],
  },
  {
    company_name: "和光里股份有限公司",
    comany_id: 83554879,
    company_boos: "許羽慈",
    company_address: "臺北市大安區信義路4段296號15樓",
    geo: [25.03586746116169, 121.55479309560836],
  },
  {
    company_name: "紅創數位科技股份有限公司",
    comany_id: 42655965,
    company_boos: "范玟君",
    company_address: "臺北市松山區南京東路4段1號8樓",
    geo: [25.052086837548284, 121.54954036885947],
  },
  {
    company_name: "專案管理顧問有限公司",
    comany_id: 54170109,
    company_boos: "游振昌",
    company_address: "臺北市松山區南京東路3段287號10樓",
    geo: [25.052271173855267, 121.54621312653201],
  },
  {
    company_name: "天然健康股份有限公司",
    comany_id: 83102904,
    company_boos: "廖憲宏",
    company_address: "南投縣埔里鎮中心路254號",
    geo: [23.95940446805041, 120.98008716059653],
  },
  {
    company_name: "台灣檢驗科技股份有限公司",
    comany_id: 23928467,
    company_boos: "李仁燮(In Seop Lee)",
    company_address: "新北市五股區(新北產業園區)五工路136之1號",
    geo: [25.063696435036654, 121.45745793508537],
  },
  {
    company_name: "苔蘚文化有限公司",
    comany_id: 83552062,
    company_boos: "古乃方",
    company_address: "新竹縣竹北市十興里勝利六街98號1樓",
    geo: [24.8248464458035, 121.02688916885505],
  },
  {
    company_name: "夙怡國際有限公司",
    comany_id: 29037370,
    company_boos: "陳夙怡",
    company_address: "臺北市內湖區江南街96巷10號",
    geo: [25.077266524008948, 121.57653242653247],
  },
  {
    company_name: "嘜記餐飲食品有限公司",
    comany_id: 28480590,
    company_boos: "李文基",
    company_address: "臺北市中山區南京東路3段218號",
    geo: [25.051761918346426, 121.54349652653204],
  },
  {
    company_name: "吳爾芙國際有限公司",
    comany_id: 83281076,
    company_boos: "房秋瑋",
    company_address: "臺北市信義區基隆路2段149之49號11樓之9",
    geo: [25.02674052823856, 121.55534218420405],
  },
  {
    company_name: "緹拉設計有限公司",
    comany_id: 56673892,
    company_boos: "張元婷",
    company_address: "新北市板橋區南興路36號",
    geo: [25.00772511418383, 121.45457269769494],
  },
  {
    company_name: "全心互娛有限公司",
    comany_id: 84006767,
    company_boos: "李盈臻",
    company_address: "臺北市大安區忠孝東路4段162號9樓之1",
    geo: [25.041403287491654, 121.55028152653176],
  },
  {
    company_name: "金寶山事業股份有限公司",
    comany_id: 86510452,
    company_boos: "曹光澯",
    company_address: "臺北市中山區民權東路2段158號1樓",
    geo: [25.06229584572052, 121.53503212653217],
  },
  {
    company_name: "嬌生股份有限公司",
    comany_id: 30814854,
    company_boos: "田中美由紀(Miyuki Tanaka",
    company_address: "臺北市中山區民生東路3段2號10、11樓",
    geo: [25.05772515548183, 121.53765374002333],
  },
  {
    company_name: "京兆儀藝術創作有限公司",
    comany_id: 82853499,
    company_boos: "郭彥甫",
    company_address: "新北市淡水區濱海路3段148巷50弄56號13樓",
    geo: [25.191958692929326, 121.4293863553708],
  },
  {
    company_name: "裕眾電子商務有限公司",
    comany_id: 91071860,
    company_boos: "何昆霖",
    company_address: "高雄市鳳山區老爺四街135巷2之4號",
    geo: [22.61286981754097, 120.34901456881487],
  },
  {
    company_name: "有保庇宗教文創股份有限公司",
    comany_id: 90825840,
    company_boos: "卓良賢",
    company_address: "臺中市北區雙十路二段101號3樓之1",
    geo: [24.156016056768713, 120.68992199767875],
  },
  {
    company_name: "人人愛有限公司",
    comany_id: 83206020,
    company_boos: "曾琳詒",
    company_address: "臺北市中正區中華路2段127號5樓",
    geo: [25.03220869478232, 121.50477149769534],
  },
  {
    company_name: "大豐建築企業股份有限公司",
    comany_id: 18556000,
    company_boos: "陳長文",
    company_address: "臺北市大同區迪化街1段289號",
    geo: [25.0616631776792, 121.50932389769581],
  },
  {
    company_name: "優美地設計行銷室內裝修有限公司",
    comany_id: 83460253,
    company_boos: "張博凱",
    company_address: "臺北市大同區西寧北路78之16號8樓",
    geo: [25.05460223152405, 121.50865294002321],
  },
  {
    company_name: "蒂嘉國際有限公司",
    comany_id: 66621260,
    company_boos: "楊世璿",
    geo: [],
    company_address: "臺北市內湖區瑞光路358巷30弄15號4樓",
    geo: [25.076486959649976, 121.57307119769631],
  },
  {
    company_name: "爆器顧問股份有限公司",
    comany_id: 85077107,
    company_boos: "林書弘",
    company_address: "新北市土城區裕生路86巷12號3樓",
    geo: [24.98546935745345, 121.44668532653066],
  },
  {
    company_name: "中租迪和股份有限公司",
    comany_id: 5072925,
    company_boos: "陳鳳龍",
    company_address: "臺北市內湖區瑞光路362號8樓至12樓",
    geo: [25.077282553969177, 121.5723822553687],
  },
  {
    company_name: "珍納達國際有限公司",
    comany_id: 28822668,
    company_boos: "余曉青",
    company_address: "臺北市信義區基隆路2段85號5樓",
    geo: [25.03006959021649, 121.55796926885894],
  },
  {
    company_name: "沐石設計室內裝修有限公司",
    comany_id: 90468239,
    company_boos: "吳惠雯",
    company_address: "臺北市中正區羅斯福路4段68號7樓之13",
    geo: [25.014689763723403, 121.53423612653141],
  },
  {
    company_name: "雲想文創股份有限公司",
    comany_id: 53560754,
    company_boos: "王之芸",
    company_address: "臺北市松山區敦化北路170號3樓",
    geo: [25.056560813777747, 121.54855946885951],
  },
  {
    company_name: "朵墨國際事業有限公司",
    comany_id: 54136807,
    company_boos: "陳怡安",
    company_address: "臺北市松山區八德路2段346巷7弄26號1樓",
    geo: [25.046773743372707, 121.54551499769563],
  },
  {
    company_name: "摩爾國際品牌顧問有限公司",
    comany_id: 85007335,
    company_boos: "劉富明",
    company_address: "臺北市中山區民生東路3段2號7樓",
    geo: [25.057715436427078, 121.53768592653213],
  },
  {
    company_name: "衣衫衣舞股份有限公司",
    comany_id: 53870391,
    company_boos: "黃金龍",
    company_address: "彰化縣花壇鄉中庄村車路街196巷臨26之1號",
    geo: [24.01205507580589, 120.55493864144533],
  },
  {
    company_name: "德禾國際工業有限公司",
    comany_id: 89765703,
    company_boos: "曾學參",
    company_address: "高雄市苓雅區武漢街167號1樓",
    geo: [22.619968534694948, 120.32590541114237],
  },
  {
    company_name: "中強光電股份有限公司",
    comany_id: 22099907,
    company_boos: "張威儀",
    company_address: "新竹市東區力行路11號",
    geo: [24.76899980242542, 121.0285149265265],
  },
  {
    company_name: "好心藝國際股份有限公司",
    comany_id: 27318705,
    company_boos: "李俊賢",
    company_address: "臺北市士林區承德路4段150號5樓",
    geo: [25.085164502107073, 121.52315762653272],
  },
  {
    company_name: "典珀有限公司",
    comany_id: 82959681,
    company_boos: "賴欣怡",
    company_address: "臺北市大安區忠孝東路4段170巷6弄2號7樓",
    geo: [25.040841926738405, 121.55069256885919],
  },
  {
    company_name: "獨立女子整合行銷股份有限公司",
    comany_id: 83194988,
    company_boos: "林昭瑾",
    company_address: "臺北市內湖區內湖路2段51號2樓之2",
    geo: [25.079668222482933, 121.58188945536864],
  },
  {
    company_name: "而秝顧問股份有限公司",
    comany_id: 85013619,
    company_boos: "蘇家儀",
    company_address: "臺北市大安區忠孝東路4段169號11樓之4",
    geo: [25.041851207457412, 121.55025082653172],
  },
  {
    company_name: "活力平方創意包裝設計有限公司",
    comany_id: 24389136,
    company_boos: "林清瑩",
    company_address: "臺中市北屯區景賢路310號1樓",
    geo: [24.167439592287394, 120.71692208233387],
  },
  {
    company_name: "千慶興業有限公司",
    comany_id: 80288878,
    company_boos: "李國維",
    company_address: "臺北市北投區知行路317號2樓",
    geo: [25.122511709152956, 121.46703006886067],
  },
  {
    company_name: "晨富建設有限公司",
    comany_id: 28088049,
    company_boos: "陳閩逸",
    company_address: "新竹市公園里東大路一段39巷39號",
    geo: [24.802689936770708, 120.97456542652723],
  },
  {
    company_name: "彩蛋影像有限公司",
    comany_id: 82868556,
    company_boos: "高憲郎",
    company_address: "高雄市鳳山區瑞隆東路209巷5號1樓",
    geo: [22.605991430597964, 120.33917466881468],
  },
  {
    company_name: "野人創意設計有限公司",
    comany_id: 55722810,
    company_boos: "吳昱緯",
    company_address: "臺北市士林區德行東路90巷33弄2號1樓",
    geo: [25.106012490507073, 121.52821085536928],
  },
  {
    company_name: "創宇數位科技有限公司",
    comany_id: 25166891,
    company_boos: "莊涵凱",
    company_address: "桃園市中壢區普強里中山東路二段154號1樓",
    geo: [24.952977512210612, 121.23927276885756],
  },
  {
    company_name: "活意行銷企管顧問有限公司",
    comany_id: 80644139,
    company_boos: "許碧雲",
    company_address: "臺中市南屯區大誠里東興路二段69巷11號",
    geo: [24.13826447309505, 120.65247226884232],
  },
  {
    company_name: "金上吉塑膠股份有限公司",
    comany_id: 89905351,
    company_boos: "鄭中仁",
    company_address: "臺南市安南區佃東里安新五路285號",
    geo: [23.08089107506155, 120.1861421264956],
  },
  {
    company_name: "馨安養生事業有限公司",
    comany_id: 50841318,
    company_boos: "黃雯",
    company_address: "新北市林口區忠孝三路30號(1樓)",
    geo: [25.069965946736964, 121.36056992653235],
  },
  {
    company_name: "亮采生物科技有限公司",
    comany_id: 24255122,
    company_boos: "林宜龍",
    company_address: "新北市板橋區中山路1段3號5樓",
    geo: [24.97002602204394, 121.43218888420283],
  },
  {
    company_name: "盛香堂股份有限公司",
    comany_id: 51652709,
    company_boos: "林鴻祐",
    company_address: "臺中市南區建國南路一段112號",
    geo: [24.12018846604981, 120.65045678418703],
  },
  {
    company_name: "展奕娛樂有限公司",
    comany_id: 69354800,
    company_boos: "盧盈杉",
    company_address: "新北市蘆洲區中原路12巷32號2樓",
    geo: [25.084537021001044, 121.46067054002371],
  },
  {
    company_name: "平原創意股份有限公司",
    comany_id: 42853509,
    company_boos: "吳昭平",
    company_address: "臺北市大安區敦化南路1段233巷48號1樓",
    geo: [25.040518108620326, 121.5491154265317],
  },
  {
    company_name: "愛瑞思創意婚紗有限公司",
    comany_id: 42848099,
    company_boos: "何正輝",
    company_address: "新北市新莊區昌平街342號",
    geo: [25.060359266728405, 121.45480594002325],
  },
  {
    company_name: "昱廣貿易有限公司",
    comany_id: 42746924,
    company_boos: "施雅惠",
    company_address: "桃園市龜山區南美里南上路280巷13-1號1樓",
    geo: [25.045569184187556, 121.30094262653188],
  },
  {
    company_name: "赤粒藝術經紀策展有限公司",
    comany_id: 27733999,
    company_boos: "陳慧君",
    company_address: "臺北市大安區大安路1段116巷15號(1樓)",
    geo: [25.04001930905538, 121.54493482653176],
  },
  {
    company_name: "午洋企業有限公司",
    comany_id: 12485134,
    company_boos: "楊錫濸",
    company_address: "臺北市中山區南京東路2段66號9樓之2",
    geo: [25.052008657146917, 121.53040422653191],
  },
  {
    company_name: "紹宏國際股份有限公司",
    comany_id: 69635041,
    company_boos: "吳珮漩",
    company_address: "桃園市龜山區大坑里南上路435號2樓",
    geo: [25.045380904378177, 121.3051551400231],
  },
  {
    company_name: "振泰檢驗科技股份有限公司",
    comany_id: 54733826,
    company_boos: "章嘉明",
    company_address: "新北市汐止區新台五路1段79號13樓之7",
    geo: [25.05660524782137, 121.63555626885962],
  },
  {
    company_name: "雄材大智材料科技股份有限公司",
    comany_id: 82910479,
    company_boos: "林正雄",
    company_address: "臺北市內湖區洲子街12號3樓",
    geo: [25.07828639308397, 121.57486531118727],
  },
  {
    company_name: "寶島手路菜有限公司",
    comany_id: 42839649,
    company_boos: "周書如",
    company_address: "臺北市萬華區峨眉街100號7樓之16",
    geo: [25.044356245519296, 121.50339085351428],
  },
  {
    company_name: "就是俊傑音樂股份有限公司",
    comany_id: 28675785,
    company_boos: "黃冠龍",
    company_address: "臺北市內湖區洲子街151號1樓",
    geo: [25.081420058838415, 121.56856499769628],
  },
  {
    company_name: "曉數碼股份有限公司",
    comany_id: 24563367,
    company_boos: "小崎卓也",
    company_address: "臺北市中正區羅斯福路2段100號20樓",
    geo: [25.026821299052923, 121.52221489769522],
  },
  {
    company_name: "阿托科技股份有限公司",
    comany_id: 16085721,
    company_boos: "楊志海",
    company_address: "臺北市松山區南京東路3段285號4樓",
    geo: [25.05221713741376, 121.54558661118679],
  },
  {
    company_name: "常予有限公司",
    comany_id: 68213022,
    company_boos: "施翔宇",
    company_address: "新北市三重區仁安街73號8樓",
    geo: [25.084040521553245, 121.48408564002362],
  },
  {
    company_name: "落居百物股份有限公司",
    comany_id: 90821669,
    company_boos: "陳奕璋",
    company_address: "臺北市中山區南京東路2段150號4樓",
    geo: [25.05198381573935, 121.53422809769566],
  },
  {
    company_name: "品晟股份有限公司",
    comany_id: 80274678,
    company_boos: "沈一傑",
    company_address: "臺北市信義區基隆路1段111號1樓",
    geo: [25.04513270491067, 121.56698314002296],
  },
  {
    company_name: "馥甘有限公司",
    comany_id: 50869476,
    company_boos: "許耀中",
    company_address: "臺北市松山區南京東路4段133巷5弄2號",
    geo: [25.05241772030939, 121.55523792653203],
  },
  {
    company_name: "勝宏精密科技股份有限公司",
    comany_id: 53780954,
    company_boos: "廖崇硯",
    company_address: "臺中市大里區日新里福大路41號1樓",
    geo: [24.12114580475084, 120.69388862651451],
  },
  {
    company_name: "唯聖紡織股份有限公司",
    comany_id: 23934881,
    company_boos: "高福來",
    company_address: "新北市鶯歌區德昌二街70巷32弄77號",
    geo: [24.958107042101634, 121.33014012653025],
  },
  {
    company_name: "撼星數位行銷股份有限公司",
    comany_id: 82911293,
    company_boos: "黃聖閔",
    company_address: "臺北市中山區龍江路295巷10之2號",
    geo: [25.060761490955535, 121.54145234002347],
  },
  {
    company_name: "亞奇雷科技股份有限公司",
    comany_id: 50890128,
    company_boos: "趙宸宇",
    company_address: "臺北市內湖區堤頂大道1段33號8樓",
    geo: [25.057465070173006, 121.57375658420455],
  },
  {
    company_name: "練習曲文創有限公司",
    comany_id: 83445943,
    company_boos: "張椀茹",
    company_address: "花蓮縣新城鄉新城村17鄰博愛路67號",
    geo: [24.126160359191434, 121.65222786884203],
  },
  {
    company_name: "聚梧桐餐廳有限公司",
    comany_id: 24659588,
    company_boos: "張智凱",
    company_address: "高雄市新興區中山二路472號1樓之1",
    geo: [22.62057573058313, 120.30231145532379],
  },
  {
    company_name: "長鴻出版社股份有限公司",
    comany_id: 86392027,
    company_boos: "蘇建中",
    company_address: "臺南市東區東門里北門路一段76號3樓",
    geo: [],
  },
  {
    company_name: "西街阿爸有限公司",
    comany_id: 90475359,
    company_boos: "陳勇豪",
    company_address: "臺北市松山區敦化北路222巷31號2樓",
    geo: [22.99137785076709, 120.21172739765774],
  },
  {
    company_name: "美味大師股份有限公司",
    comany_id: 23311250,
    company_boos: "趙丕倫",
    company_address: "臺北市文山區一壽街20巷7號1樓",
    geo: [24.979790536298164, 121.55585012653069],
  },
  {
    company_name: "戴思科技股份有限公司",
    comany_id: 64943012,
    company_boos: "黃碧玉",
    company_address: "臺南市仁德區後壁里後壁里德崙路77巷25弄62號1樓",
    geo: [22.9458921644044, 120.25332993998435],
  },
  {
    company_name: "寵愛有家股份有限公司",
    comany_id: 42623535,
    company_boos: "胡睿哲",
    company_address: "苗栗縣苑裡鎮苑港里2鄰苑港10之69號",
    geo: [24.450644350827606, 120.65465528419305],
  },
  {
    company_name: "哲煜科技股份有限公司",
    comany_id: 43774383,
    company_boos: "李秉哲",
    company_address: "臺北市中山區復興北路80號12樓",
    geo: [25.051168299328392, 121.54383332653202],
  },
  {
    company_name: "開拓動漫事業有限公司",
    comany_id: 29034628,
    company_boos: "杜書毓",
    company_address: "新北市永和區秀朗路2段229號4樓之3",
    geo: [25.000314225617675, 121.5264457976946],
  },
  {
    company_name: "得速科技有限公司",
    comany_id: 27968587,
    company_boos: "沈大為",
    company_address: "新北市新店區復興路45號10樓",
    geo: [24.986820332029676, 121.53678455536702],
  },
  {
    company_name: "詠暉行銷股份有限公司",
    comany_id: 90526532,
    company_boos: "蔡承翰",
    company_address: "臺中市西屯區潮洋里市政北二路282號19樓之11",
    geo: [24.164487727951418, 120.6364610688426],
  },
  {
    company_name: "好事貸有限公司",
    comany_id: 85020415,
    company_boos: "孫鴻貴",
    company_address: "臺中市西區忠誠里臺灣大道二段573號12樓",
    geo: [24.157728730544974, 120.6580181976789],
  },
  {
    company_name: "沃草有限公司",
    comany_id: 54648384,
    company_boos: "邱貴菊",
    company_address: "臺北市中正區懷寧街92之1號7樓",
    geo: [25.04270442446034, 121.51394399769553],
  },
  {
    company_name: "鉲栮有限公司",
    comany_id: 90835116,
    company_boos: "連皆竣",
    company_address: "臺南市中西區南美里民生路一段167號3樓",
    geo: [22.99401274328155, 120.20029331300287],
  },
  {
    company_name: "藝起呵呵有限公司",
    comany_id: 83702891,
    company_boos: "王建揚",
    company_address: "桃園市桃園區文康街11之2號",
    geo: [24.993710752885956, 121.30392876885827],
  },
  {
    company_name: "無限量股份有限公司",
    comany_id: 82816087,
    company_boos: "陳量成",
    company_address: "新北市新莊區頭前路161號10樓",
    geo: [25.052218039917168, 121.46109045536829],
  },
  {
    company_name: "一特安餐飲股份有限公司",
    comany_id: 50866422,
    company_boos: "李昇燮",
    company_address: "臺北市中正區重慶南路1段10號8樓之15",
    geo: [25.041596305981468, 121.51326399769562],
  },
];

const memberData = [
  {
    company_name: "紘祥數位科技教育有限公司",
    first: "吳彩綠",
    first_date: "2021-06-07T09:34:57.955+08:00",
    followers_count: 2,
    followers: "林如慧,林晨浩",
    count: 4,
  },
  {
    company_name: "晨紘國際有限公司",
    first: "康甄庭",
    first_date: "2021-06-07T09:04:20.389+08:00",
    followers_count: 2,
    followers: "徐俊強,羅鈺樺",
    count: 4,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    first: "張瑀庭",
    first_date: "2021-01-29T01:44:05.357+08:00",
    followers_count: 2,
    followers: "潘信達,莊越翔",
    count: 9,
  },
  {
    company_name: "白鹿工作室",
    first: "朱淇宏",
    first_date: "2021-06-30T16:02:56.603+08:00",
    followers_count: 1,
    followers: "汪子涵",
    count: 2,
  },
  {
    company_name: "絕色時尚股份有限公司",
    first: "李澤維",
    first_date: "2020-10-23T11:33:43.757+08:00",
    followers_count: 1,
    followers: "黃三鳳",
    count: 15,
  },
  {
    company_name: "英格斯能源股份有限公司",
    first: "蔣雅文",
    first_date: "2021-08-02T12:29:40.283+08:00",
    followers_count: 1,
    followers: "邜姿瑩",
    count: 2,
  },
  {
    company_name: "給樂工作室",
    first: "周奕安",
    first_date: "2021-04-07T19:04:45.551+08:00",
    followers_count: 1,
    followers: "張彧",
    count: 7,
  },
  {
    company_name: "楓林書館",
    first: "王莉棋",
    first_date: "2021-07-02T23:22:58.239+08:00",
    followers_count: 1,
    followers: "王雅萱",
    count: 5,
  },
  {
    company_name: "和泉健康節能有限公司",
    first: "洪琳",
    first_date: "2020-12-10T15:01:29.593+08:00",
    followers_count: 1,
    followers: "王昇",
    count: 2,
  },
  {
    company_name: "享萊股份有限公司",
    first: "林昆立",
    first_date: "2021-07-04T14:39:58.944+08:00",
    followers_count: 1,
    followers: "郭子德",
    count: 5,
  },
  {
    company_name: "國立中正大學",
    first: "何威廷",
    first_date: "2020-12-09T11:37:13.169+08:00",
    followers_count: 1,
    followers: "廖晨堯",
    count: 4,
  },
  {
    company_name: "沃爾斯丹國際有限公司",
    first: "江佩容",
    first_date: "2021-08-19T12:37:26.437+08:00",
    followers_count: 1,
    followers: "魏心如",
    count: 3,
  },
  {
    company_name: "山品壹合有限公司",
    first: "温宗翰",
    first_date: "2020-12-09T21:52:26.898+08:00",
    followers_count: 1,
    followers: "黃鈺茹",
    count: 11,
  },
  {
    company_name: "共專有限公司",
    first: "張維元",
    first_date: "2021-07-05T14:51:33.064+08:00",
    followers_count: 1,
    followers: "簡郡緯",
    count: 2,
  },
  {
    company_name: "拓治股份有限公司",
    first: "邱千純",
    first_date: "2021-09-23T09:40:04.196+08:00",
    followers_count: 1,
    followers: "陳盈竹",
    count: 11,
  },
  {
    company_name: "實踐大學",
    first: "余承倧",
    first_date: "2021-08-02T16:11:17.794+08:00",
    followers_count: 1,
    followers: "白玉萱",
    count: 11,
  },
  {
    company_name: "勝騰緩衝研發股份有限公司",
    first: "江佩容",
    first_date: "2021-08-25T21:12:12.007+08:00",
    followers_count: 1,
    followers: "魏心如",
    count: 4,
  },
  {
    company_name: "光穹遊戲股份有限公司",
    first: "王雅萱",
    first_date: "2021-12-17T21:58:38.926+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "全心互娛有限公司",
    first: "黃三鳳",
    first_date: "2021-07-01T11:26:36.885+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "典珀有限公司",
    first: "姚盈伃",
    first_date: "2021-12-13T23:19:31.549+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "凱莉愛內衣股份有限公司",
    first: "黃三鳳",
    first_date: "2021-05-31T19:23:18.32+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "創宇數位科技有限公司",
    first: "黃明斌",
    first_date: "2021-12-09T17:59:27.484+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "勝宏精密科技股份有限公司",
    first: "黃明斌",
    first_date: "2021-12-14T15:27:37.733+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "千慶興業有限公司",
    first: "許哲耀",
    first_date: "2021-09-03T21:55:17.943+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "午洋企業有限公司",
    first: "殷聖哲",
    first_date: "2021-12-10T05:55:49.578+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "南山人壽保險股份有限公司",
    first: "葉百豐",
    first_date: "2021-04-29T12:52:58.101+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "博克科技股份有限公司",
    first: "李崇偉",
    first_date: "2021-05-25T05:24:48.639+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "台南企業股份有限公司職工福利委員會",
    first: "戚正寰",
    first_date: "2021-08-23T15:18:24.751+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "台灣戴姆勒亞洲商車股份有限公司職工福利委員會",
    first: "林陳揚",
    first_date: "2020-12-23T22:21:48.778+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "台灣檢驗科技股份有限公司",
    first: "陳炳彰",
    first_date: "2021-11-16T09:47:03.103+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "台灣舞蹈治療研究協會",
    first: "黃于珊",
    first_date: "2021-05-28T19:26:49.309+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "台灣迪卡儂有限公司",
    first: "林雅淳",
    first_date: "2021-11-30T23:37:37.759+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "台灣香港邊城青年",
    first: "羅樂文",
    first_date: "2021-11-11T13:07:38.726+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "吳爾芙國際有限公司",
    first: "陳炳彰",
    first_date: "2021-12-20T12:53:05.309+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "和光里股份有限公司",
    first: "魏心如",
    first_date: "2021-08-05T10:53:00.384+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "和泉健康節能有限公司",
    first: "王昇",
    first_date: "2020-12-10T00:49:42.415+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "品則股份有限公司",
    first: "鄧立心",
    first_date: "2021-02-03T15:37:42.047+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "品晟股份有限公司",
    first: "李嘉展",
    first_date: "2021-12-03T23:06:28.31+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "哲煜科技股份有限公司",
    first: "蘇祥輝",
    first_date: "2021-03-26T17:41:36.13+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "唯聖紡織股份有限公司",
    first: "葉百豐",
    first_date: "2021-01-25T17:44:45.208+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "嘜記餐飲食品有限公司",
    first: "覃基豪",
    first_date: "2021-05-31T12:25:02.971+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "嚎哮排演",
    first: "王瑞璞",
    first_date: "2021-08-29T15:32:04.425+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "四零四科技股份有限公司聯合職工福利委員會",
    first: "林家輝",
    first_date: "2021-07-07T10:44:59.547+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "國立中山大學",
    first: "李崇偉",
    first_date: "2021-04-29T16:49:24.04+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "國立台灣藝術大學",
    first: "李啟維",
    first_date: "2021-04-28T01:47:37.429+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "國立屏東大學",
    first: "禚昌龍",
    first_date: "2021-10-20T14:07:38+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "國立科學工藝博物館",
    first: "余孟軒",
    first_date: "2021-09-07T18:00:28.563+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "國立臺中教育大學",
    first: "王莉棋",
    first_date: "2021-10-03T22:42:52.435+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "國立臺北藝術大學",
    first: "李崇偉",
    first_date: "2021-11-19T11:02:27.541+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "國立臺灣藝術大學",
    first: "莊文化",
    first_date: "2020-12-09T15:30:38.995+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "國立高雄科技大學",
    first: "李嘉展",
    first_date: "2022-01-10T16:15:13.873+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "圓山大飯店有限公司",
    first: "禚昌龍",
    first_date: "2021-05-19T20:32:04.763+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "夙怡國際有限公司",
    first: "陳炳彰",
    first_date: "2021-11-09T15:08:12.811+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "多香果舖",
    first: "葉百豐",
    first_date: "2021-05-05T18:16:37.741+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "夢想家房屋經紀有限公司",
    first: "李宛蓉",
    first_date: "2021-07-16T10:52:21.735+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "大人山養有限公司",
    first: "李嘉展",
    first_date: "2022-01-07T18:46:43.687+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "大樹家股份有限公司古亭分公司",
    first: "林嵩皓",
    first_date: "2021-11-10T18:28:34.322+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "大豐建築企業股份有限公司",
    first: "羅聖婷",
    first_date: "2021-08-31T14:51:19.998+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "天城影像有限公司",
    first: "姚盈伃",
    first_date: "2022-01-03T21:55:42.98+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "天然健康股份有限公司",
    first: "江佩容",
    first_date: "2022-01-05T16:12:35.651+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "奔跑山豬運動事業有限公司",
    first: "吳飛旭",
    first_date: "2021-06-13T20:11:08.936+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "好事貸有限公司",
    first: "陳炳彰",
    first_date: "2021-11-02T14:20:57.445+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "好心藝國際股份有限公司",
    first: "范芳婷",
    first_date: "2021-12-10T16:18:56.469+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "威銘儀器有限公司",
    first: "楊鈞丞",
    first_date: "2020-11-30T14:09:07.623+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "嬌生股份有限公司",
    first: "許勝凱",
    first_date: "2021-10-04T22:37:13.668+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "寬陽台空間創創股份有限公司",
    first: "張宇良",
    first_date: "2021-10-05T10:04:33.769+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "寵愛有家股份有限公司",
    first: "譚竟汶",
    first_date: "2021-12-27T23:04:52.978+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "寵物百分百股份有限公司",
    first: "鄭鈞元",
    first_date: "2021-11-01T08:00:51.861+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "寶久實業股份有限公司",
    first: "胡庭茵",
    first_date: "2021-12-06T15:14:15.064+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "寶島手路菜有限公司",
    first: "陳炳彰",
    first_date: "2021-11-05T10:25:11.513+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "專案管理顧問有限公司",
    first: "陳炳彰",
    first_date: "2021-11-20T10:55:59.648+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "少女凱倫有限公司",
    first: "林賢榮",
    first_date: "2021-09-29T18:14:12.539+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "就是俊傑音樂股份有限公司",
    first: "朱淇宏",
    first_date: "2021-10-28T15:59:48.961+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "屏東縣立大同高中",
    first: "江佩容",
    first_date: "2021-11-05T14:13:56.536+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "展奕娛樂有限公司",
    first: "徐翊庭",
    first_date: "2021-11-15T16:09:50.871+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "崑山科技大學",
    first: "彭子恆",
    first_date: "2021-05-06T00:54:01.159+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "常予有限公司",
    first: "陳炳彰",
    first_date: "2021-11-19T10:26:13.166+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "平原創意股份有限公司",
    first: "林嵩皓",
    first_date: "2021-11-24T17:24:52.803+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "年頡科技股份有限公司",
    first: "陳炳彰",
    first_date: "2021-11-16T15:38:34.294+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "幻境咖啡有限公司",
    first: "李嘉展",
    first_date: "2021-12-16T16:57:20.747+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "建儒科技文教有限公司",
    first: "鄭宇",
    first_date: "2021-10-05T15:23:01.195+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "彩蛋影像有限公司",
    first: "江元宏",
    first_date: "2021-11-06T12:45:54.181+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "得速科技有限公司",
    first: "禚昌龍",
    first_date: "2021-12-24T16:01:34.855+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "德禾國際工業有限公司",
    first: "蘇祥輝",
    first_date: "2021-02-21T21:40:44.689+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "德立媒體行銷有限公司",
    first: "洪宛薐",
    first_date: "2021-02-05T12:24:07.829+08:00",
    followers_count: 0,
    followers: "",
    count: 8,
  },
  {
    company_name: "恆易資訊有限公司",
    first: "王林郁翔",
    first_date: "2021-03-31T14:37:45.827+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "恩大娛樂有限公司",
    first: "劉晏辰",
    first_date: "2021-10-14T10:37:17.901+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "愛瑞思創意婚紗有限公司",
    first: "陳炳彰",
    first_date: "2021-11-19T21:20:35.118+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "戴思科技股份有限公司",
    first: "張育銓",
    first_date: "2021-11-12T11:54:26.865+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "振泰檢驗科技股份有限公司",
    first: "林陳揚",
    first_date: "2021-12-10T10:17:02.35+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "摩爾國際品牌顧問有限公司",
    first: "覃基豪",
    first_date: "2021-10-12T16:17:24.632+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "撼星數位行銷股份有限公司",
    first: "邱念慈",
    first_date: "2021-12-11T12:26:46.318+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "數字科技股份有限公司",
    first: "陳炳彰",
    first_date: "2021-11-04T23:12:50.621+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "新北市興福國小",
    first: "魏嘉興",
    first_date: "2021-11-29T21:13:02.62+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "新譯漢方有限公司",
    first: "林昀駿",
    first_date: "2021-01-22T19:39:35.793+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "明基電通股份有限公司",
    first: "張杰",
    first_date: "2021-08-13T13:30:25.081+08:00",
    followers_count: 0,
    followers: "",
    count: 5,
  },
  {
    company_name: "星予公關顧問有限公司",
    first: "魏心如",
    first_date: "2020-11-13T11:31:02.867+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "春富光電科技有限公司",
    first: "葉百豐",
    first_date: "2020-11-17T15:02:16.614+08:00",
    followers_count: 0,
    followers: "",
    count: 4,
  },
  {
    company_name: "昱廣貿易有限公司",
    first: "葉百豐",
    first_date: "2021-04-29T13:26:31.564+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "晨富建設有限公司",
    first: "黃一玲",
    first_date: "2021-08-31T12:48:37.262+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "晰穀有限公司",
    first: "譚竟汶",
    first_date: "2021-05-31T13:44:06.618+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "晶衍有限公司",
    first: "林晉崴",
    first_date: "2022-01-04T16:08:54.276+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "曉數碼股份有限公司",
    first: "王莉棋",
    first_date: "2021-07-16T16:05:00.168+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "有保庇宗教文創股份有限公司",
    first: "魏廷蓉",
    first_date: "2021-03-29T15:05:48.045+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "朝陽科技大學",
    first: "陳炳彰",
    first_date: "2021-11-16T14:22:09.196+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "木和子影像音樂工作室",
    first: "徐翊庭",
    first_date: "2021-11-13T20:18:19.027+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "朵墨國際事業有限公司",
    first: "陳曦",
    first_date: "2021-12-31T19:27:14.565+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "村山生技有限公司",
    first: "陳曦",
    first_date: "2022-01-03T22:09:55.555+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "梅花鹿影業股份有限公司",
    first: "張宇良",
    first_date: "2021-11-29T13:25:43.878+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "欣宸國際股份有限公司",
    first: "許哲耀",
    first_date: "2021-09-21T21:30:14.021+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "歐奇國際事業有限公司",
    first: "陳曦",
    first_date: "2021-01-16T13:11:50.098+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "歐洲之星貿易有限公司",
    first: "黃琮群",
    first_date: "2021-12-08T14:27:53.681+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "沃草有限公司",
    first: "范子祥",
    first_date: "2021-12-20T11:36:18.663+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "沐石設計室內裝修有限公司",
    first: "李嘉展",
    first_date: "2021-12-20T15:36:37.042+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "活力平方創意包裝設計有限公司",
    first: "吳飛旭",
    first_date: "2021-10-01T13:05:12.378+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "活意行銷企管顧問有限公司",
    first: "王莉棋",
    first_date: "2021-09-24T15:22:28.265+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "淳貿企業有限公司",
    first: "吳家銘",
    first_date: "2022-01-04T15:36:18.496+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "源美國際企業有限公司",
    first: "周承泰",
    first_date: "2021-12-20T16:08:00.416+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "漢翔企業社",
    first: "林湘玟",
    first_date: "2021-02-01T16:36:47.388+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "灼見文創有限公司",
    first: "郭于菁",
    first_date: "2021-10-26T19:03:19.692+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "為烽科技有限公司",
    first: "王昇",
    first_date: "2021-01-08T16:03:19.297+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "無穹藝術有限公司",
    first: "禚昌龍",
    first_date: "2021-07-26T13:06:29.877+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "無限量股份有限公司",
    first: "張博清",
    first_date: "2021-04-29T14:15:55.299+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "爆器顧問股份有限公司",
    first: "范綱燊",
    first_date: "2021-08-18T13:45:48.476+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "獨立女子整合行銷股份有限公司",
    first: "陳炳彰",
    first_date: "2021-11-25T10:50:06.923+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "玖惟股份有限公司",
    first: "蔡孟儒",
    first_date: "2021-12-06T16:30:37.916+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "珍納達國際有限公司",
    first: "覃基豪",
    first_date: "2021-08-20T16:07:38.223+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "田喬營造股份有限公司",
    first: "李嘉展",
    first_date: "2021-10-15T07:48:04.842+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "疍宅甜點店",
    first: "鄭宇",
    first_date: "2021-10-01T12:26:05.743+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "皇錩實業有限公司",
    first: "劉育名",
    first_date: "2021-05-20T12:22:10.165+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "盛香堂股份有限公司",
    first: "陳炳彰",
    first_date: "2021-11-19T07:46:49.868+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "目目和設計有限公司",
    first: "陳瑞軒",
    first_date: "2021-08-31T12:37:22.653+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "知己者創意數位行銷有限公司",
    first: "覃基豪",
    first_date: "2020-12-22T11:38:11.916+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "石皮有限公司",
    first: "葉仁智",
    first_date: "2021-09-06T06:35:32.158+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "社團法人全球小紅帽協會",
    first: "江佩容",
    first_date: "2021-09-09T23:27:31.226+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "社團法人台灣數位文化協會",
    first: "李崇偉",
    first_date: "2021-12-02T20:53:52.49+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "秀泰國際娛樂有限公司",
    first: "覃基豪",
    first_date: "2021-08-11T14:58:42.391+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "空島設計有限公司",
    first: "莊文化",
    first_date: "2021-02-25T22:38:44.131+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "紅創數位科技股份有限公司",
    first: "劉柏年",
    first_date: "2021-04-15T14:48:44.502+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "紅動亞洲傳媒有限公司",
    first: "李宛蓉",
    first_date: "2021-08-24T20:12:50.406+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "紘祥實業有限公司",
    first: "林晨浩",
    first_date: "2021-03-05T12:25:26.83+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    first: "高永祺",
    first_date: "2020-10-06T10:53:36.538+08:00",
    followers_count: 0,
    followers: "",
    count: 4,
  },
  {
    company_name: "紹宏國際股份有限公司",
    first: "徐俊強",
    first_date: "2021-09-25T22:42:13.402+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "維斯塔行銷顧問有限公司",
    first: "黃祺芸",
    first_date: "2021-01-22T10:12:22.625+08:00",
    followers_count: 0,
    followers: "",
    count: 6,
  },
  {
    company_name: "維肯媒體股份有限公司",
    first: "吳翊嘉",
    first_date: "2021-10-21T17:23:44.207+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "練習曲文創有限公司",
    first: "魏心如",
    first_date: "2020-10-13T15:35:38.209+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "緹拉設計有限公司",
    first: "王雅萱",
    first_date: "2021-11-30T21:31:38.801+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "美味大師股份有限公司",
    first: "俞吟佳",
    first_date: "2021-10-18T14:51:58.017+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "美澤生活事業有限公司",
    first: "魏廷蓉",
    first_date: "2020-12-12T21:23:44.292+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "群眾自造股份有限公司",
    first: "謝明佑",
    first_date: "2021-05-01T00:42:16.938+08:00",
    followers_count: 0,
    followers: "",
    count: 4,
  },
  {
    company_name: "而秝顧問股份有限公司",
    first: "黃鈺茹",
    first_date: "2021-05-13T10:54:50.167+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "聚梧桐餐廳有限公司",
    first: "禚昌龍",
    first_date: "2021-07-30T15:16:08.5+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "自由人藝術設計股份有限公司",
    first: "李東峻",
    first_date: "2021-04-09T15:50:24.83+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "臺北市政府警察局文山第二分局",
    first: "葉百豐",
    first_date: "2021-06-11T17:41:34.894+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "艾思奇整合行銷股份有限公司",
    first: "邱念慈",
    first_date: "2021-11-15T14:56:02.282+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "苔蘚文化有限公司",
    first: "陳曦",
    first_date: "2021-04-20T10:32:48.264+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "苗栗縣私立云成文理補習班",
    first: "李嘉展",
    first_date: "2021-12-02T22:17:17.299+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "若晨室內裝修有限公司",
    first: "鄭婷君",
    first_date: "2021-04-23T10:00:46.108+08:00",
    followers_count: 0,
    followers: "",
    count: 4,
  },
  {
    company_name: "菲爾資產有限公司",
    first: "李嘉展",
    first_date: "2022-01-05T09:44:22.793+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "萱普艾森國際有限公司",
    first: "梁金後",
    first_date: "2021-06-08T00:10:53.479+08:00",
    followers_count: 0,
    followers: "",
    count: 8,
  },
  {
    company_name: "落居百物有限公司",
    first: "魏心如",
    first_date: "2021-10-05T18:46:45.885+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "蒂嘉國際有限公司",
    first: "王昇",
    first_date: "2021-01-15T21:48:27.191+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "藝起呵呵有限公司",
    first: "朱淇宏",
    first_date: "2021-10-29T17:42:07.098+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "虹彩服飾股份有限公司",
    first: "張文彥",
    first_date: "2021-09-27T12:00:41.416+08:00",
    followers_count: 0,
    followers: "",
    count: 6,
  },
  {
    company_name: "蝶映藝術文化有限公司",
    first: "陳曦",
    first_date: "2021-09-17T16:29:29.271+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "衛生福利部中央健康保險署",
    first: "高立翰",
    first_date: "2021-10-22T16:29:12.22+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "衣衫衣舞股份有限公司",
    first: "陳炳彰",
    first_date: "2021-11-08T16:46:19.17+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "裕眾電子商務有限公司",
    first: "陳炳彰",
    first_date: "2021-11-08T00:06:11.206+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "西街阿爸有限公司",
    first: "林陳揚",
    first_date: "2021-12-15T18:58:25.76+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "詠暉行銷股份有限公司",
    first: "崔立言",
    first_date: "2021-11-29T02:14:49.093+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "詠越實業有限公司",
    first: "林晉崴",
    first_date: "2021-12-05T16:12:55.871+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "財團法人中山管理教育基金會",
    first: "徐梅軒",
    first_date: "2021-08-25T10:02:06.325+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "財團法人台灣敦睦聯誼會高雄圓山大飯店",
    first: "禚昌龍",
    first_date: "2021-05-19T21:23:11.867+08:00",
    followers_count: 0,
    followers: "",
    count: 5,
  },
  {
    company_name: "財團法人基督之家",
    first: "蘇祥輝",
    first_date: "2021-04-30T23:05:17.422+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "財團法人麗寶基金會",
    first: "朱淇宏",
    first_date: "2021-12-08T12:40:23.26+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "資創國際保險經紀人股份有限公司",
    first: "陳薪智",
    first_date: "2021-07-08T00:43:11.51+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "赤粒藝術經紀策展有限公司",
    first: "汪子涵",
    first_date: "2021-09-24T17:07:53.505+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "超級傳家泰潤股份有限公司",
    first: "覃基豪",
    first_date: "2021-07-19T14:58:35.682+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "路遙力股份有限公司",
    first: "林品賢",
    first_date: "2021-06-02T14:57:46.579+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "迪利米食品有限公司",
    first: "林陳揚",
    first_date: "2021-06-20T16:40:48.183+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "采傑整合行銷國際有限公司",
    first: "林陳揚",
    first_date: "2021-10-08T16:15:37.253+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "野人創意設計有限公司",
    first: "張雅晴",
    first_date: "2021-06-04T15:15:21.381+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "金上吉塑膠股份有限公司",
    first: "譚竟汶",
    first_date: "2021-03-19T10:57:55.058+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "金寶山事業股份有限公司",
    first: "黃三鳳",
    first_date: "2021-07-26T09:18:17.485+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "鉲栮有限公司",
    first: "陳炳彰",
    first_date: "2021-11-17T21:20:07.847+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "長鴻出版社股份有限公司",
    first: "王莉棋",
    first_date: "2021-08-31T12:10:08.925+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "開拓動漫事業有限公司",
    first: "王瑞璞",
    first_date: "2021-12-06T13:16:17.064+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "開物整合設計股份有限公司",
    first: "范綱燊",
    first_date: "2021-10-15T20:51:55.635+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "閱野文創股份有限公司",
    first: "陳瑞軒",
    first_date: "2021-07-23T14:27:20.783+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "阮綜合社團法人阮綜合醫院",
    first: "何承謙",
    first_date: "2021-05-24T11:15:36.439+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "阮綜合醫療社團法人阮綜合醫院",
    first: "何承謙",
    first_date: "2021-07-20T08:53:10.857+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "阿托科技股份有限公司",
    first: "陳祐萱",
    first_date: "2021-11-08T16:01:25.814+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "雄材大智材料科技股份有限公司",
    first: "殷聖哲",
    first_date: "2021-06-24T14:57:32.144+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "雲想文創股份有限公司",
    first: "陳瑞軒",
    first_date: "2021-05-02T18:33:51.219+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "靜宜大學",
    first: "陳炳彰",
    first_date: "2021-11-10T22:41:33.083+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "願如是股份有限公司",
    first: "魏廷蓉",
    first_date: "2020-12-21T23:34:01.888+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "顧廣毅創意工作室",
    first: "王偉泰",
    first_date: "2021-10-26T14:12:41.541+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "馥甘有限公司",
    first: "葉百豐",
    first_date: "2021-04-13T11:32:40.294+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "馨安養生事業有限公司",
    first: "魏心如",
    first_date: "2021-02-19T14:47:04.832+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "高雄室內合唱團",
    first: "何威廷",
    first_date: "2021-06-29T09:20:14.463+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "Embassy of Saint Vincent and the Grenadines",
    first: "連英佑",
    first_date: "2021-11-04T13:39:50.678+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "高雄醫學大學附設中和紀念醫院",
    first: "張宇良",
    first_date: "2021-10-28T14:26:03.402+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "一棠文創工作室",
    first: "陳炳彰",
    first_date: "2021-11-09T14:52:12.604+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "一特安餐飲股份有限公司",
    first: "徐翊庭",
    first_date: "2021-11-26T15:15:40.709+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "中國青年救國團桃園市中壢國民運動中心",
    first: "張辰宇",
    first_date: "2021-02-05T13:57:47.982+08:00",
    followers_count: 0,
    followers: "",
    count: 4,
  },
  {
    company_name: "中強光電股份有限公司",
    first: "許乃蓉",
    first_date: "2021-05-14T11:45:58.028+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "中租迪和股份有限公司",
    first: "Nichaphat Saewang",
    first_date: "2021-12-21T10:37:41.093+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "丰沛行銷管理顧問有限公司",
    first: "黃子杰",
    first_date: "2022-01-10T13:14:33.417+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "亞圖斯科技工作室",
    first: "林冠霓",
    first_date: "2021-05-31T23:19:51.757+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "亞奇雷科技股份有限公司",
    first: "許哲耀",
    first_date: "2021-12-03T11:47:58.725+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "京兆儀藝術創作有限公司",
    first: "朱淇宏",
    first_date: "2021-06-30T15:52:42.328+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "亮采生物科技有限公司",
    first: "黃三鳳",
    first_date: "2021-03-31T12:57:27.465+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "人人愛有限公司",
    first: "張辰宇",
    first_date: "2021-04-21T10:56:13.398+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "伽馬實業有限公司",
    first: "陳曦",
    first_date: "2020-11-16T00:05:01.881+08:00",
    followers_count: 0,
    followers: "",
    count: 3,
  },
  {
    company_name: "余宗鳴律師事務所",
    first: "葉百豐",
    first_date: "2021-05-05T18:38:06.797+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "信佳人資服務有限公司",
    first: "黃琬真",
    first_date: "2021-01-08T14:39:31.349+08:00",
    followers_count: 0,
    followers: "",
    count: 2,
  },
  {
    company_name: "健康傳媒國際事業有限公司",
    first: "劉育名",
    first_date: "2020-09-25T10:11:03.468+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
  {
    company_name: "億力鑫系統科技股份有限公司",
    first: "劉柏年",
    first_date: "2022-01-10T15:25:55.533+08:00",
    followers_count: 0,
    followers: "",
    count: 1,
  },
];

const combine = companyData
  .map((item) => {
    let value = memberData.filter(
      (i) => i.company_name === item.company_name
    )[0];
    if (value) {
      return {
        ...item,
        first: value?.first,
        first_date: value?.first_date,
        followers_count: value?.followers_count,
        followers: value?.followers,
        count: value?.count,
      };
    } else {
      return undefined;
    }
  })
  .filter((item) => item?.first);

const MapWrap = styled.div`
  .marker {
    cursor: pointer;
  }
  .logo {
    display: block;
    height: 54px;
    margin-left: 4px;
  }
`;

function Map({ mapData }) {
  const [currData, setCurrData] = useState(undefined);
  const [markerData, setMarkerData] = useState(combine);
  const [name, setName] = useState("");

  const rn = React.useRef(null);

  useEffect(() => {
    renderMap();
  }, [mapData]);

  const renderMap = () => {
    const node = rn.current;

    const projection = () => {
      return d3.geoMercator().center([123.4, 24.5]).scale(8000);
    };
    select(node).append("g").classed("countries", true);
    const countries = select("g").selectAll("path").data(mapData);

    countries
      .enter()
      .append("path")
      .classed("country", true)
      .attr("fill", "lightgray")
      .attr("stroke", "#3f51b5")
      .attr("stroke-width", 1.5)
      .each(function (d, i) {
        select(this).attr("d", d3.geoPath().projection(projection())(d));
      });
  };

  return (
    <MapWrap>
      <Drawer open={currData}>
        <SWAPSpace />
        <Container>
          <div style={{ width: "500px" }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">{currData?.company_name}</Typography>
              </Grid>
              <Grid item>
                <CloseOutlinedIcon
                  onClick={() => setCurrData(undefined)}
                  style={{ cursor: "pointer", fontSize: 36, marginTop: 5 }}
                />
              </Grid>
            </Grid>
            <SWAPSpace />
            <Typography variant="h4">首位合作者：{currData?.first}</Typography>
            <Typography variant="h4">合作次數：{currData?.count}</Typography>
          </div>
        </Container>
        <SWAPSpace />
      </Drawer>
      <Container maxWidth="lg">
        <SWAPSpace size="2xl" />
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Link to="/">
              <img src="/swap.svg" className="logo" />
            </Link>
            <SWAPSpace size="2xl" />
            <Typography variant="d1">2021</Typography>
            <Typography variant="d2">年度回顧</Typography>
            <SWAPSpace />
            <Typography variant="body1_loose" color="black900">
              看看這一年 SWAP 平台上的自由工作者跟全台灣的哪些公司合作！輸入你的
              Email 看看今年你已經跟哪些公司合作。
            </Typography>
            <SWAPSpace size="2xl" />
            {[
              {
                icon: (
                  <PeopleAltIcon color="primary" style={{ fontSize: "3rem" }} />
                ),
                text: "1,266 位自由工作者會員",
              },
              {
                icon: (
                  <BusinessIcon
                    color="primary"
                    fontSize="2rem"
                    style={{ fontSize: "3rem" }}
                  />
                ),
                text: `${companyData.length} 家合作公司 / 機構`,
              },
            ].map((item, index) => {
              return (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  key={`icon_${{ index }}`}
                >
                  <Grid item>{item.icon}</Grid>
                  <Grid item>
                    <Typography variant="title_loose">{item.text}</Typography>
                  </Grid>
                </Grid>
              );
            })}

            <SWAPSpace size="2xl" />
            <Grid container spacing={1} wrap="nowrap">
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <TextField
                  height={56}
                  label="輸入你的名字"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  onClick={() => {
                    if (name === "") {
                      setMarkerData(combine);
                    } else {
                      setMarkerData(
                        combine.filter((i) => (i.first === name ? i : null))
                      );
                    }
                  }}
                >
                  回顧我的成果
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "calc(100vh - 16px)" }}
          >
            <svg
              style={{ display: "none" }}
              width="300px"
              height="calc(100vh - 16px)"
              ref={rn}
            />

            <ComposableMap
              projection="geoMercator"
              style={{
                border: "1px solid rgba(0,0,0,0.09)",
                borderRadius: "12px",
              }}
              projectionConfig={{
                center: [120.6, 23.6],
                scale: 15000,
              }}
              height={1000}
            >
              <ZoomableGroup>
                <Geographies geography={"/taiwan.json"}>
                  {({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill="lightgray"
                        stroke="#3f51b5"
                        strokeWidth={2.5}
                      />
                    ))
                  }
                </Geographies>
                {markerData.map((item, index) => {
                  if (item.geo && item.geo.length > 0) {
                    return (
                      <Marker
                        coordinates={item.geo.reverse()}
                        key={`marker_${index}`}
                      >
                        <circle
                          className="marker"
                          r={6}
                          fill="#F53"
                          onClick={() => setCurrData(item)}
                        />
                      </Marker>
                    );
                  } else {
                    return null;
                  }
                })}
              </ZoomableGroup>
            </ComposableMap>
          </Grid>
        </Grid>
        <SWAPSpace size="2xl" />
      </Container>
    </MapWrap>
  );
}

export default Map;
