import { Divider, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import {
  Button,
  Chip,
  Container,
  Modal,
  RadioList,
  SWAPSpace,
  TextField,
  Typography,
} from "@yosgo/swap-ui";
import styled from "styled-components";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import MonetizationOnTwoToneIcon from "@material-ui/icons/MonetizationOnTwoTone";
import CardGiftcardTwoToneIcon from "@material-ui/icons/CardGiftcardTwoTone";

import { useEffect, useState } from "react";

const data = [
  {
    name: "1月",
    vip: 1,
    normal: 4.3,
  },
  {
    name: "2月",
    vip: 1,
    normal: 4.4,
  },
  {
    name: "3月",
    vip: 1,
    normal: 4.1,
  },
  {
    name: "",
    vip: 1,
    normal: 4.1,
  },
];

const NavigationWrap = styled.div``;

export const Navigation = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <NavigationWrap>
      <img
        src={mobile ? "/navigation_m.svg" : "/navigation.svg"}
        style={{
          width: "100%",
          display: "block",
          objectFit: "cover",
        }}
      />
    </NavigationWrap>
  );
};

const FooterWrap = styled.div``;
export const Footer = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <FooterWrap>
      <img
        src={mobile ? "/footer_m.svg" : "/footer.svg"}
        style={{
          width: "100%",
          display: "block",
          objectFit: "cover",
        }}
      />
    </FooterWrap>
  );
};

const BarWrap = styled.div`
  //https://codepen.io/SLXFX/pen/kyaxRW
  margin-left: -12.5px;
  .cuboid {
    width: 50px;
    height: ${({ height }) => (height ? `${height * 100}px` : "100px")};
    margin: 0;
    background-color: #ccc;
    position: relative;
    display: inline-block;
    opacity: ${({ undefined }) => (undefined ? 0.4 : 1)};
  }
  .cuboid-rt:before {
    content: "";
    height: ${({ height }) => (height ? `${height * 100}px` : "100px")};
    width: 20px;
    background-color: #b3b3b3;
    position: absolute;
    left: 100%;
    top: -10px;
    transform: skew(0, -45deg);
  }
  .cuboid-rt:after {
    content: "";
    width: 50px;
    height: 20px;
    background-color: #999999;
    position: absolute;
    top: -20px;
    left: 10px;
    transform: skew(-45deg);
  }
`;

const Bar = ({ height, undefined }) => {
  return (
    <BarWrap height={undefined ? 5 : height} undefined={undefined}>
      <div className="cuboid cuboid-rt" />
    </BarWrap>
  );
};

const Calc = ({ type, taxAmount, setTaxAmount }) => {
  const tax = Math.round((taxAmount / 1.05) * 0.05);
  const minFee = Math.round((taxAmount / 1.05) * 0.025);
  const maxFee = Math.round((taxAmount / 1.05) * 0.045);
  const vipFee = Math.round((taxAmount / 1.05) * 0.01);
  const fee = type === "normal" ? [minFee, maxFee] : [vipFee];

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography variant="body1">客戶付款金額(含稅金額)</Typography>
        </Grid>
        <Grid item>
          <TextField
            width={130}
            label=""
            height={36}
            value={taxAmount}
            type="text"
            onChange={(e) => {
              if (Number(e.target.value)) {
                setTaxAmount(Number(e.target.value));
              } else {
                console.log(e.target.value);
              }
            }}
            InputProps={{
              endAdornment: "TWD",
            }}
          />
        </Grid>
      </Grid>
      <SWAPSpace />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography variant="body1">- 營業稅(5%)</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{tax.toLocaleString()} TWD</Typography>
        </Grid>
      </Grid>
      <SWAPSpace />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography variant="body1">
            - 請款單手續費(
            {type === "normal" ? (
              <span style={{ fontWeight: "bold" }}>2.5% ~ 4.5%</span>
            ) : (
              <span style={{ color: "#3f51b5", fontWeight: "bold" }}>1%</span>
            )}
            )
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {type === "vip" ? (
              <span
                style={{
                  color: "#E5640C",
                  fontWeight: "bolder",
                  marginRight: "4px",
                }}
              >
                節省了 {(maxFee - vipFee).toLocaleString()}{" "}
                <span>
                  <MonetizationOnTwoToneIcon style={{ marginBottom: "-6px" }} />
                </span>
                <span>
                  <MonetizationOnTwoToneIcon
                    style={{
                      marginBottom: "-6px",
                      marginLeft: "-9px",
                    }}
                  />
                </span>
                <span>
                  <MonetizationOnTwoToneIcon
                    style={{ marginBottom: "-6px", marginLeft: "-9px" }}
                  />
                </span>
              </span>
            ) : null}
            {type === "normal"
              ? `${fee[0].toLocaleString()} ~ ${fee[1].toLocaleString()}`
              : `${fee[0].toLocaleString()}`}{" "}
            TWD
          </Typography>
        </Grid>
      </Grid>
      <SWAPSpace />
      <Divider />
      <SWAPSpace />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography variant="body1">我的報酬</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {type === "normal"
              ? `${(taxAmount - tax - fee[0]).toLocaleString()} ~ ${(
                  taxAmount -
                  tax -
                  fee[1]
                ).toLocaleString()}`
              : `${(taxAmount - tax - fee).toLocaleString()}`}{" "}
            TWD
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const PricingWrap = styled.div`
  .normal_percentage_label {
    &:last-child {
      display: none;
    }
  }
  .vip_percentage_label {
    &:last-child {
      display: none;
    }
  }
  .service_title {
    transition: 0.3s;
    padding: 12px;
    margin: -12px;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      background: #e6e9f8;
    }
  }
  .thumb_1 {
    color: #e5640c;
    position: absolute;
    top: -18px;
    right: -18px;
    transform: rotate(-15deg);
  }
  .thumb_2 {
    color: #f27521;
    position: absolute;
    top: 18px;
    right: -18px;
    transform: rotate(-15deg);
  }
  .thumb_3 {
    color: #f59225;
    position: absolute;
    top: 9px;
    right: 18px;
    transform: rotate(-15deg);
  }
  .pricing_chart {
    display: none;
    margin-top: 90px;
    padding-bottom: -24px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    height: 500px;
    .x_label {
      position: absolute;
      bottom: -9px;
      right: -30px;
    }
    .y_label {
      position: absolute;
      top: -18px;
      left: -12px;
    }

    .y_1 {
      position: absolute;
      left: -36px;
      bottom: 90px;
    }
    .y_baseline {
      position: absolute;
      left: 0px;
      bottom: 0px;
      height: 100px;
      width: 100%;
      border-top: 2px solid #97a3e1;
      z-index: 2;
      cursor: pointer;
      transition: 0.5s;
      background: rgba(151, 163, 225, 0);
      &.y_baseline_active,
      &:hover {
        border-top: 2px solid #3f51b5;
        background: rgba(151, 163, 225, 0.7);
        &:after {
          position: absolute;
          content: "請款單手續費費率 1%";
          width: 500px;
          text-align: center;
          top: calc(50px - 9px);
          left: calc(50% - 250px);
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
    .y_range {
      position: absolute;
      left: 0px;
      bottom: 250px;
      height: 200px;
      width: 100%;
      border-top: 2px dashed #f99894;
      border-bottom: 2px dashed #f99894;
      z-index: 2;
      cursor: pointer;
      transition: 0.5s;
      background: rgba(151, 163, 225, 0);
      &.y_range_active,
      &:hover {
        background: rgba(198, 0, 3, 0.2);
        border-top: 2px dashed #c60003;
        border-bottom: 2px dashed #c60003;
        &:after {
          position: absolute;
          content: "請款單手續費費率 2.5% ~ 4.5%";
          width: 500px;
          text-align: center;
          top: calc(100px - 9px);
          left: calc(50% - 250px);
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
    .y_2_5 {
      position: absolute;
      left: -36px;
      bottom: 240px;
    }
    .y_4_5 {
      position: absolute;
      left: -36px;
      bottom: 440px;
    }
  }
`;

const Pricing = () => {
  const [serviceModal, setServiceModal] = useState(false);
  const [currService, setCurrService] = useState(undefined);
  const [memberIdentity, setMemberIdentity] = useState("normal");
  const [taxAmount, setTaxAmount] = useState(10500);
  const [chartW, setChartW] = useState(0);
  const [vipModal, setVipModal] = useState(false);

  const handleMemberPriceChart = (type) => {
    memberIdentity !== type
      ? setMemberIdentity(type)
      : setMemberIdentity(undefined);
  };

  useEffect(() => {
    const width = document.getElementById("chart_wrap");
    setChartW(width.offsetWidth);
  }, []);

  return (
    <PricingWrap>
      <Navigation />
      <Container maxWidth="xl">
        <SWAPSpace size={80} />
        {/**請款單手續費費率 */}
        <div>
          <Grid container spacing={0} alignItems="flex-start">
            <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
              <SWAPSpace size={40} />
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography
                    variant="d3"
                    style={{
                      marginLeft: "-6px",
                    }}
                  >
                    3 月費率
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="d1"
                    color="primary900"
                    style={{ paddingBottom: "5px" }}
                  >
                    {data[data.length - 1].normal}%
                  </Typography>
                </Grid>
                <Grid item></Grid>
              </Grid>
              <SWAPSpace />
              <Typography variant="body1" color="black1000">
                SWAP 請款單的手續費將依據交易量多寡動態計算而成，每月 1
                號發布當月份的費率。
              </Typography>
              <SWAPSpace size="2xl" />
              <Slider
                {...{
                  dots: false,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 1,
                  vertical: true,
                  autoplay: true,
                  autoplaySpeed: 5000,
                  adaptiveHeight: true,
                }}
              >
                {[
                  "過年前後會有大量的案件要請款，所以費率會較高！",
                  "在跟客戶報價時別忘了考慮請款單手續費的成本！",
                  "升級 VIP 會員可享有固定 1% 的費率！",
                ].map((item, index) => (
                  <div key={`hint_${index}`}>
                    <Grid
                      container
                      alignItems="center"
                      className={`hint_${index + 1}`}
                    >
                      <Grid item>
                        <EmojiObjectsIcon style={{ color: "#F8C131" }} />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body1"
                          color="black1000"
                          style={{ marginLeft: "3px", fontWeight: "bold" }}
                        >
                          {item}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Slider>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
              direction="column"
              id="chart_wrap"
            >
              <Typography
                variant="body2"
                style={{ textAlign: "center" }}
                color="black800"
              >
                每月歷史費率，平均{" "}
                <Typography
                  variant="body2"
                  color="primary800"
                  style={{ display: "inline-block" }}
                >
                  {(
                    data
                      .map((item) => item.normal)
                      .reduce((acc, curr) => acc + curr, 0) / data.length
                  ).toFixed(2)}
                  %
                </Typography>
              </Typography>
              <SWAPSpace />
              <LineChart
                width={chartW}
                height={300}
                data={data}
                margin={{ top: 0, right: 65, bottom: 0, left: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tickSize={0}
                  tick={({ x, y, stroke, payload }) => (
                    <g
                      transform={`translate(${
                        x + chartW / data.length / 1.7
                      },${y})`}
                    >
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        fontSize={10}
                      >
                        {payload.value}
                      </text>
                    </g>
                  )}
                />
                <YAxis
                  type="number"
                  domain={[0, 4.5]}
                  tickCount={10}
                  interval={1}
                  tickSize={0}
                  tick={({ x, y, stroke, payload }) => (
                    <g transform={`translate(${x - 5},${y - 12})`}>
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fill="#666"
                        fontSize={10}
                      >
                        {payload.value}%
                      </text>
                    </g>
                  )}
                />
                <Legend
                  wrapperStyle={{
                    width: "300px",
                    left: "calc(50% - 150px)",
                    textAlign: "center",
                  }}
                  formatter={(value) => {
                    if (value === "normal") {
                      return <div>一般會員費率 2.5% ~ 4.5%</div>;
                    } else {
                      return <div>VIP會員費率 1%</div>;
                    }
                  }}
                />
                <Line
                  type="stepAfter"
                  dataKey="normal"
                  stroke="black"
                  strokeWidth={3}
                  dot={false}
                  label={({ x, y, stroke, value }) => {
                    return (
                      <g
                        className="normal_percentage_label"
                        transform={`translate(${
                          chartW / data.length / 1.85
                        },${22})`}
                      >
                        <text
                          x={x}
                          y={y}
                          dy={-4}
                          fill={stroke}
                          fontSize={15}
                          textAnchor="middle"
                        >
                          {value}%
                        </text>
                      </g>
                    );
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="vip"
                  stroke="#3f51b5"
                  strokeWidth={3}
                  dot={false}
                  label={({ x, y, stroke, value }) => {
                    return (
                      <g
                        className="vip_percentage_label"
                        transform={`translate(${
                          chartW / data.length / 1.85
                        },${22})`}
                      >
                        <text
                          x={x}
                          y={y}
                          dy={-4}
                          fill={stroke}
                          fontSize={15}
                          textAnchor="middle"
                        >
                          {value}%
                        </text>
                      </g>
                    );
                  }}
                />
              </LineChart>
            </Grid>
          </Grid>
        </div>
        <SWAPSpace size={180} />
        {/**方案與價目表 */}
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography variant="h2">功能項目</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
              direction="column"
            >
              <Chip
                contained
                variant="neutral"
                height="4.5rem"
                width="100%"
                label={
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="h4" color="black1000">
                      一般會員
                    </Typography>
                  </div>
                }
                style={{ fontSize: "1.2rem" }}
              />
              {/* <SWAPSpace />
            <Button variant="secondary">免費註冊</Button> */}
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
              direction="column"
            >
              <Chip
                contained
                variant="primary"
                height="4.5rem"
                width="100%"
                label={
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="h4" color="black1000">
                      VIP會員
                    </Typography>
                    <ThumbUpAltIcon className="thumb_1" fontSize="large" />
                    <ThumbUpAltIcon className="thumb_2" fontSize="medium" />
                    <ThumbUpAltIcon className="thumb_3" fontSize="small" />
                  </div>
                }
                style={{ fontSize: "1.2rem", position: "relative" }}
              />
              {/* <SWAPSpace />
            <Button variant="secondary">立即升級</Button> */}
            </Grid>
          </Grid>
          <SWAPSpace size="2xl" />
          <Divider />
          {[
            {
              header: "曝光與媒合",
              title: "商務檔案頁面",
              description:
                "免費建制你的頁面，把作品集、你的接案資訊集中在 SWAP 商務檔案上，讓客戶主動與你合作！",
              normal: "",
              vip: "",
              normal_available: true,
              vip_available: true,
              secondaryButton: undefined,
              primaryButton: undefined,
              img: "/pricing/business_file.svg",
            },
            {
              header: null,
              title: "曝光至 Google 搜尋引擎",
              description:
                "你的商務檔案頁面將同步更新到 Google 搜尋引擎，讓客戶能直接在 Google 上找到到你！",
              normal: "",
              vip: "",
              normal_available: true,
              vip_available: true,
              secondaryButton: undefined,
              primaryButton: undefined,
              img: "/pricing/google.svg",
            },
            {
              header: "向客戶報價與請款",
              title: "報價單",
              description:
                "客戶無法使用你個人名義開的報價單嗎？沒關係，現在你直接提供給客戶 SWAP 平台的報價單！",
              normal: "",
              vip: "",
              normal_available: true,
              vip_available: true,
              secondaryButton: undefined,
              primaryButton: {
                title: "查看報價單範本",
                onClick: () =>
                  (window.location.href =
                    "https://swap.work/q/e7ff2c56-9c6f-490d-a5c6-2c36d96392ef?access_code=tio6PHlIX0"),
              },
              img: "/pricing/q.svg",
            },
            {
              header: undefined,
              title: "請款單",
              description:
                "透過 SWAP 的請款單線上向客戶請款。當客戶完成匯款後自動開立 SWAP 平台的發票，讓核銷變得好簡單！",
              normal: "動態費率 2.5% ~ 4.5%",
              vip: "固定費率 1%",
              normal_available: true,
              vip_available: true,
              secondaryButton: undefined,
              primaryButton: {
                title: "查看請款單範本",
                onClick: () =>
                  (window.location.href =
                    "https://swap.work/i/f58a6482-bbaa-4910-bbdb-c874844bb90c?access_code=VEHpA4nagS"),
              },
              img: "/pricing/pr.svg",
            },
            {
              header: undefined,
              title: "先開發票後付款",
              description:
                "公家機關或是大公司的客戶經常要求要先拿到發票後才會進行付款，透過本功能你可以在客戶付款前產生請款用的發票，讓客戶可以拿到發票後再付款！",
              normal: "",
              vip: "",
              normal_available: false,
              vip_available: true,
              secondaryButton: undefined,
              primaryButton: {
                title: "立即升級VIP會員",
                onClick: () => {},
              },
              img: "/pricing/invoice.png",
            },
            {
              header: undefined,
              title: "大額請款",
              description:
                "沒有單張請款單上限 10 萬元的限制！請款單手續費須依照實際請款金額進行報價。",
              normal: "",
              vip: "專案報價",
              normal_available: false,
              vip_available: true,
              secondaryButton: undefined,
              primaryButton: {
                title: "立即升級VIP會員",
                onClick: () => {},
              },
              img: "/pricing/huge.png",
            },
            {
              header: undefined,
              title: "跨國請款",
              description:
                "由 SWAP 平台提供 Invoice 給外國客戶並協助換匯！相關費用須依照實際合作情況進行報價。",
              normal: "",
              vip: "專案報價",
              normal_available: false,
              vip_available: true,
              secondaryButton: undefined,
              primaryButton: {
                title: "立即升級VIP會員",
                onClick: () => {},
              },
              img: "/pricing/foreign.png",
            },
            // {
            //   header: "財務服務",
            //   title: "平台案件所得申報",
            //   description:
            //     "你在 SWAP 平台上的每個案件所得，系統都會依照政府規定，協助你申報與扣繳",
            //   normal: "",
            //   vip: "",
            //   normal_available: true,
            //   vip_available: true,
            //   secondaryButton: undefined,
            //   primaryButton: undefined,
            //   img: "https://via.placeholder.com/900x450.png?text=Need%20Picture%20Support",
            // },
            // {
            //   header: undefined,
            //   title: "財務助手",
            //   description: "統計在 SWAP 平台上個類型的所得",
            //   normal: "",
            //   vip: "",
            //   normal_available: false,
            //   vip_available: true,
            //   secondaryButton: undefined,
            //   primaryButton: {
            //     title: "立即升級VIP會員",
            //     onClick: () => {},
            //   },
            //   img: "https://via.placeholder.com/900x450.png?text=Need%20Picture%20Support",
            // },
            // {
            //   header: "設立公司",
            //   title: "僅設立",
            //   description: "當你想從個人改為",
            //   normal: "",
            //   vip: "",
            //   normal_available: true,
            //   vip_available: true,
            //   secondaryButton: undefined,
            //   primaryButton: {
            //     title: "聯絡我們",
            //     onClick: () => {},
            //   },
            //   img: "https://via.placeholder.com/900x450.png?text=Need%20Picture%20Support",
            // },
            // {
            //   header: undefined,
            //   title: "設立 + 記帳",
            //   description:
            //     "以更優惠的價格一站完成公司設立 ~ 日常營運所需的記帳作業。記帳費用為按月繳費，年收 14 個月，含年度申報！",
            //   normal: "",
            //   vip: "",
            //   normal_available: true,
            //   vip_available: true,
            //   secondaryButton: undefined,
            //   primaryButton: {
            //     title: "聯絡我們",
            //     onClick: () => {},
            //   },
            //   img: "https://via.placeholder.com/900x450.png?text=Need%20Picture%20Support",
            // },
          ].map((item, index) => (
            <div key={`price_item_row_${index}`}>
              <Grid
                container
                alignItems="flex-end"
                className="service_title"
                onClick={() => {
                  setCurrService(item);
                  setServiceModal(true);
                }}
              >
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  className="service_header"
                >
                  {item.header ? (
                    <div>
                      <SWAPSpace size="4xl" />
                      <Typography
                        variant="h4"
                        color="black1000"
                        style={{ marginLeft: "-1.5px" }}
                      >
                        {item.header}
                      </Typography>
                      <SWAPSpace />
                    </div>
                  ) : null}
                  <div>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography
                          variant="h4"
                          color="black900"
                          style={{ fontWeight: "normal", marginRight: "3px" }}
                        >
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{
                            color: "gray",
                            fontSize: "15px",
                            marginTop: "7px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  direction="column"
                >
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {item.normal_available !== undefined ? (
                      <Grid item style={{ marginBottom: "-4px" }}>
                        {item.normal_available ? (
                          <CheckIcon color="primary" fontSize="large" />
                        ) : (
                          <CloseIcon color="secondary" fontSize="large" />
                        )}
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="black900"
                        style={{ fontWeight: "normal" }}
                      >
                        {item.normal}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  direction="column"
                >
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {item.vip_available !== undefined ? (
                      <Grid item style={{ marginBottom: "-4px" }}>
                        {item.vip_available ? (
                          <CheckIcon color="primary" fontSize="large" />
                        ) : (
                          <CloseIcon color="secondary" fontSize="large" />
                        )}
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="black1000"
                        style={{ fontWeight: "normal" }}
                      >
                        {item.vip}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <SWAPSpace size="3xl" />
            </div>
          ))}
          {currService ? (
            <Modal
              open={serviceModal}
              title={currService.title}
              size="small"
              onClose={() => setServiceModal(false)}
              children={
                <div>
                  <Typography variant="body1">
                    {currService.img ? (
                      <div>
                        <img
                          src={currService.img}
                          style={{
                            width: "100%",
                            height: "auto",
                            display: "block",
                            overflow: "hidden",
                            objectFit: "contain",
                          }}
                        />
                        <SWAPSpace />
                      </div>
                    ) : null}

                    {currService.description}
                  </Typography>
                </div>
              }
              primaryButton={currService.primaryButton}
              secondaryButton={currService.secondaryButton}
            />
          ) : null}
        </div>
        <SWAPSpace size={180} />
        {/**方案與請款單手續費 */}
        <div>
          <Typography variant="d3" style={{ textAlign: "center" }}>
            試算請款單手續費
          </Typography>
          <SWAPSpace size="4xl" />
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Chip
                contained
                variant="neutral"
                height="4.5rem"
                width="100%"
                label={
                  <div style={{ textAlign: "center" }}>
                    一般會員
                    <SWAPSpace size="xs" />
                    <Typography variant="body2" color="black800">
                      動態費率 2.5% ~ 4.5%
                    </Typography>
                  </div>
                }
                style={{ fontSize: "1.2rem" }}
              />
              <SWAPSpace />
              <Calc
                type="normal"
                taxAmount={taxAmount}
                setTaxAmount={setTaxAmount}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Chip
                contained
                variant="primary"
                height="4.5rem"
                width="100%"
                label={
                  <div style={{ textAlign: "center" }}>
                    VIP會員
                    <SWAPSpace size="xs" />
                    <Typography variant="body2" color="black800">
                      固定費率 1%
                    </Typography>
                    <ThumbUpAltIcon className="thumb_1" fontSize="large" />
                    <ThumbUpAltIcon className="thumb_2" fontSize="medium" />
                    <ThumbUpAltIcon className="thumb_3" fontSize="small" />
                  </div>
                }
                style={{ fontSize: "1.2rem", position: "relative" }}
              />
              <SWAPSpace />
              <Calc
                type="vip"
                taxAmount={taxAmount}
                setTaxAmount={setTaxAmount}
              />
            </Grid>
          </Grid>
          <SWAPSpace size="4xl" />
          <Grid container spacing={3} alignItems="center">
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ paddingLeft: "2.5%" }}
            >
              <Button
                width="95%"
                variant="secondary"
                size="small"
                onClick={() => alert("預期會打開註冊視窗，這裡就不做了")}
              >
                免費註冊
              </Button>
              <SWAPSpace size="2xl" />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Button fullWidth size="large" onClick={() => setVipModal(true)}>
                立即升級 VIP 會員
              </Button>
              <Modal
                size="medium"
                title="升級 VIP 會員"
                helpText="功能更實用了、費率更低了，最重要的是你的一人公司又變得更有效率了！"
                open={vipModal}
                onClose={() => setVipModal(false)}
                children={
                  <div>
                    <Slider
                      {...{
                        dots: false,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 1,
                        autoplay: true,
                        autoplaySpeed: 5000,
                      }}
                    >
                      {[
                        {
                          title: `首次升級 VIP 會員獎勵`,
                          label: "免請款單手續費優惠券 x 3",
                          chips: ["折抵全額請款單手續費", "沒有使用期限"],
                          img: "https://via.placeholder.com/900x450.png?text=Need%20Picture%20Support",
                        },
                      ].map((item, index) => (
                        <div key={`reward_${index}`}>
                          <Typography
                            variant="body1"
                            style={{ textAlign: "center" }}
                          >
                            {item.title}
                          </Typography>
                          <SWAPSpace />
                          <Grid
                            container
                            justifyContent="space-between"
                            spacing={1}
                          >
                            <Grid
                              item
                              container
                              justifyContent="center"
                              alignItems="center"
                              direction="column"
                            >
                              <img
                                src={item.img}
                                style={{
                                  width: "100%",
                                  height: "300px",
                                  borderRadius: "6px",
                                }}
                              />
                              <SWAPSpace size="xxs" />
                              <Typography
                                variant="h5"
                                style={{ fontWeight: "normal" }}
                              >
                                {item.label}
                              </Typography>
                              <SWAPSpace size="xxs" />
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                              >
                                {item.chips.map((c, cindex) => (
                                  <Grid item key={`${c}_${cindex}`}>
                                    <Chip
                                      contained
                                      variant="primary"
                                      label={c}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </Slider>
                  </div>
                }
                primaryButton={{
                  title: "前往付款頁面",
                }}
                label={
                  <span>
                    2022 VIP 會員費{" "}
                    <Typography
                      variant="h6"
                      color="primary900"
                      style={{ display: "inline-block" }}
                    >
                      4,000TWD / 年
                    </Typography>
                  </span>
                }
              />
              <SWAPSpace size="xxs" />
              <Typography
                variant="body2"
                color="secondary900"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  direction="row-reverse"
                >
                  <Grid item>
                    <CardGiftcardTwoToneIcon fontSize="large" />
                  </Grid>
                  <Grid item>首次升級 VIP 會員獎勵，限量發放中！</Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <SWAPSpace size="4xl" />
        <SWAPSpace />
        {/**封存 */}
        <div style={{ display: "none" }}>
          <SWAPSpace />
          <Grid
            container
            spacing={1}
            alignItems="flex-end"
            justifyContent="space-around"
            className="pricing_chart"
          >
            <div className="x_label">
              <Typography variant="caption2" color="black800">
                月份
              </Typography>
            </div>
            <div className="y_label">
              <Typography variant="caption1" color="black800">
                費率
              </Typography>
            </div>
            <div className="y_1">
              <Typography variant="caption1" color="black1000">
                1%
              </Typography>
            </div>
            <div
              className={`y_baseline ${
                memberIdentity === "vip" ? "y_baseline_active" : ""
              }`}
              onClick={() => {
                handleMemberPriceChart("vip");
              }}
            />
            <div className="y_2_5">
              <Typography variant="caption1" color="black1000">
                2.5%
              </Typography>
            </div>
            <div className="y_4_5">
              <Typography variant="caption1" color="black1000">
                4.5%
              </Typography>
            </div>
            <div
              className={`y_range ${
                memberIdentity === "normal" ? "y_range_active" : ""
              }`}
              onClick={() => {
                handleMemberPriceChart("normal");
              }}
            />
            {[
              {
                month: "01",
                scale: 4,
              },
              {
                month: "02",
                scale: 4.2,
              },
              {
                month: "03",
                scale: 3.8,
              },
              {
                month: "04",
                scale: undefined,
              },
              {
                month: "05",
                scale: undefined,
              },
              {
                month: "06",
                scale: undefined,
              },
              {
                month: "07",
                scale: undefined,
              },
              {
                month: "08",
                scale: undefined,
              },
              {
                month: "09",
                scale: undefined,
              },
              {
                month: "10",
                scale: undefined,
              },
              {
                month: "11",
                scale: undefined,
              },
              {
                month: "12",
                scale: undefined,
              },
            ].map((item, index) => (
              <Grid
                item
                key={`bar_${index}`}
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Typography
                  color={item.scale ? "black1000" : "black600"}
                  variant="caption1"
                  style={{
                    position: "relative",
                    zIndex: 1,
                    margin: "0 0 -21px -10px",
                  }}
                >
                  {item.scale ? `${item.scale}%` : <>未公布</>}
                </Typography>
                <Bar height={item.scale} undefined={!item.scale} />
                <Typography
                  variant="caption1"
                  style={{ marginBottom: "-26px" }}
                >
                  2022.
                  {item.month}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <SWAPSpace size="4xl" />
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <RadioList
                onClick={() => {
                  handleMemberPriceChart("normal");
                }}
                title="一般會員"
                subtitle="動態費率 2.5% ~ 4.5%"
                checked={memberIdentity === "normal"}
                width="100%"
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <RadioList
                onClick={() => {
                  handleMemberPriceChart("vip");
                }}
                title="VIP 會員"
                subtitle="固定費率 1%"
                checked={memberIdentity === "vip"}
                width="100%"
              />
            </Grid>
          </Grid>
          {memberIdentity ? (
            <div>
              <SWAPSpace size="2xl" />
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography variant="h3">請款單金額試算</Typography>
                  <SWAPSpace />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="h6">
                        客戶付款金額(含稅金額)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        width={120}
                        label=""
                        height={36}
                        type="text"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, "");
                          if (isNaN(Number(value))) return;
                          setTaxAmount(Number(value) < 0 ? 0 : Number(value));
                          setAmount(amt);
                          if (amtError) {
                            setAmtError(false);
                          }
                        }}
                        value={taxAmount.toLocaleString()}
                        InputProps={{
                          endAdornment: "TWD",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <SWAPSpace />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="h6">- 營業稅(5%)</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {Math.round((taxAmount / 1.05) * 0.05)} TWD
                      </Typography>
                    </Grid>
                  </Grid>
                  <SWAPSpace />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="h6">
                        - 請款單手續費(
                        {memberIdentity === "normal" ? "2.5% ~ 4.5%" : "1%"})
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {memberIdentity === "normal"
                          ? `${Math.round(
                              (taxAmount / 1.05) * 0.025
                            )} ~ ${Math.round((taxAmount / 1.05) * 0.045)}`
                          : Math.round((taxAmount / 1.05) * 0.01)}{" "}
                        TWD
                      </Typography>
                    </Grid>
                  </Grid>
                  <SWAPSpace />
                  <Divider />
                  <SWAPSpace />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography variant="h6">我的報酬</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {memberIdentity === "normal"
                          ? `${
                              Math.round(taxAmount / 1.05) -
                              Math.round((taxAmount / 1.05) * 0.025)
                            } ~ ${
                              Math.round(taxAmount / 1.05) -
                              Math.round((taxAmount / 1.05) * 0.045)
                            }`
                          : Math.round(taxAmount / 1.05) -
                            Math.round((taxAmount / 1.05) * 0.01)}{" "}
                        TWD
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <SWAPSpace />
            </div>
          ) : null}
        </div>
        <div style={{ display: "none" }}>
          <SWAPSpace size="4xl" />
          <SWAPSpace size="4xl" />
          <SWAPSpace size="4xl" />
          <SWAPSpace size="4xl" />
          <Typography variant="d3" style={{ marginLeft: "-6px" }}>
            VIP 會員加碼獎勵
          </Typography>
          <SWAPSpace />
          <Typography variant="body1">
            TWD實級是謝明少長合我首依時開們樓世成生人聲，是與西下是則。
          </Typography>
          <SWAPSpace size="4xl" />
          <SWAPSpace size="4xl" />
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <Chip contained variant="primary" label="不限金額" />
                </Grid>
                <Grid item>
                  <Chip contained variant="primary" label="沒有使用期限" />
                </Grid>
              </Grid>
              <SWAPSpace />
              <Typography variant="h2">請款單手續費優惠券 x 3</Typography>
              <SWAPSpace />
              <Typography variant="body1">
                一意給就、可形友便、要而企由是是空勢。是情心也公動有說做。麼倒配兒名的層合原往家經！
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <img src="https://via.placeholder.com/450/000000/FFFFFF/?text=Coupon Code Img" />
            </Grid>
          </Grid>
          <SWAPSpace size="4xl" />
          <SWAPSpace size="4xl" />
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <img src="https://via.placeholder.com/450/000000/FFFFFF/?text=Appointment Img" />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <Chip contained variant="primary" label="產品教學" />
                </Grid>
                <Grid item>
                  <Chip contained variant="primary" label="所得稅估算" />
                </Grid>
              </Grid>
              <SWAPSpace />
              <Typography variant="h2">30 mins 線上諮詢</Typography>
              <SWAPSpace />
              <Typography variant="body1">
                業一經雖老，的完女球不告腳建商年我內林當會民絕嚴好人這友我什場兒等，半小重的前面去是不太！
              </Typography>
            </Grid>
          </Grid>
          <SWAPSpace size="4xl" />
          <SWAPSpace size="4xl" />
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={1}>
                <Grid item>
                  <Chip contained variant="primary" label="NFT" />
                </Grid>
                <Grid item>
                  <Chip contained variant="primary" label="限量 100 位" />
                </Grid>
              </Grid>
              <SWAPSpace />
              <Typography variant="h2">2022 VIP 會員證</Typography>
              <SWAPSpace />
              <Typography variant="body1">
                一意給就、可形友便、要而企由是是空勢。是情心也公動有說做。麼倒配兒名的層合原往家經！
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <img src="https://via.placeholder.com/450/000000/FFFFFF/?text=Medallion Img" />
            </Grid>
          </Grid>
        </div>
        <SWAPSpace size="4xl" />
      </Container>
      <Footer />
    </PricingWrap>
  );
};

export default Pricing;
