import "./styles.css";
import styled from "styled-components";
import { Button, SWAPSpace, SWAPTheme, Typography } from "@yosgo/swap-ui";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Grid } from "@material-ui/core";

import MapRouter from "./routes/MapRouter";
import StudioRouter from "./routes/StudioRouter";
import GlobeRouter from "./routes/GlobeRouter";
import HeroRouter from "./routes/HeroRouter";
import ProfileRouter from "./routes/ProfileRouter";
import TransactionRouter from "./routes/TransactionRouter";
import PricingRouter from "./routes/PricingRouter";
import LpRouter from "./routes/LpRouter";
import Lp2Router from "./routes/Lp2Router";

const AppWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function App() {
  const paths = [
    {
      url: "/studio",
      name: "工作室模型應用",
      router: <StudioRouter />,
      disabled: false,
    },
    {
      url: "/hero",
      name: "Hero Section 應用",
      router: <HeroRouter />,
      disabled: false,
    },
    {
      url: "/profile",
      name: "商務檔案",
      router: <ProfileRouter />,
      disabled: false,
    },
    {
      url: "/globe",
      name: "地球",
      router: <GlobeRouter />,
      disabled: true,
    },
    {
      url: "/map",
      name: "地圖",
      router: <MapRouter />,
      disabled: true,
    },
    {
      url: "/transaction",
      name: "交易流程",
      router: <TransactionRouter />,
      disabled: false,
    },
    {
      url: "/pricing",
      name: "動態費率與VIP會員",
      router: <PricingRouter />,
      disabled: false,
    },
    {
      url: "/lp",
      name: "Landing Page",
      router: <LpRouter />,
      disabled: false,
    },
    {
      url: "/lp2",
      name: "Landing Page 2",
      router: <Lp2Router />,
      disabled: false,
    },
  ];
  return (
    <SWAPTheme>
      <Router>
        <Routes>
          {paths
            .filter((item) => !item.disabled)
            .map((item, index) => {
              return (
                <Route
                  key={`${item.name} + ${index}`}
                  path={item.url}
                  element={item.router}
                />
              );
            })}
          <Route
            path="/"
            element={
              <AppWrap>
                <div>
                  <Typography variant="h6">SWAP 相關 DEMO</Typography>
                  <SWAPSpace />
                  <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {paths
                      .filter((item) => !item.disabled)
                      .map((item, index) => {
                        return (
                          <Grid item key={`${item.name}} - ${index}`}>
                            <Link
                              to={item.url}
                              style={{ textDecoration: "none" }}
                            >
                              <Button>{item.name}</Button>
                            </Link>
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              </AppWrap>
            }
          />
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
      </Router>
    </SWAPTheme>
  );
}
