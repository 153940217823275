import { useFrame } from "@react-three/fiber";
import { useEffect } from "react";

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/room 2_01.gltf");

  const myText = useRef();

  useFrame(({ clock }) => {
    myText.current.rotation.x = Math.sin(clock.getElapsedTime());
  });

  useEffect(() => {
    console.log("Model props", props);
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={myText}>
        <mesh
          onClick={() => alert("YO")}
          castShadow
          receiveShadow
          geometry={nodes.Kcinlab002.geometry}
          material={nodes.Kcinlab002.material}
          position={[-2.22, 7.86, -4.09]}
          rotation={[1.47, 0, -0.05]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle004.geometry}
        material={nodes.Circle004.material}
        position={[-2.36, 4.55, -3.99]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.57, 0.57, 0.57]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane027_1.geometry}
          material={nodes.Plane027_1.material}
          position={[-0.02, 0.11, -0.25]}
          scale={[0.14, 0.14, 0.63]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane028.geometry}
          material={nodes.Plane028.material}
          position={[0.26, 0.11, -0.02]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={[0.12, 0.12, 0.37]}
        />
      </mesh>
      <group
        position={[3.04, 3.4, -3.57]}
        rotation={[0, 1.26, 0]}
        scale={[0.31, 0.5, 0.41]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008_1.geometry}
          material={nodes.Plane008_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008_2.geometry}
          material={nodes.Plane008_2.material}
        />
      </group>
      <group
        position={[2.93, 3.6, -3.5]}
        rotation={[-Math.PI, 1.56, -Math.PI]}
        scale={[0.31, 0.5, 0.41]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009_1.geometry}
          material={nodes.Plane009_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane009_2.geometry}
          material={nodes.Plane009_2.material}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007.geometry}
        material={nodes.Cube007.material}
        position={[3.07, 6.08, -3.61]}
        rotation={[0, -1.57, 0]}
        scale={[0.42, 0.46, 0.46]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube006_1.geometry}
          material={nodes.Cube006_1.material}
          position={[0, -1.48, 0]}
          scale={[0.78, 0.78, 0.78]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane011.geometry}
        material={nodes.Plane011.material}
        position={[-1.43, 2.99, -0.06]}
        rotation={[0, 0.41, 0]}
        scale={[0.19, 0.19, 0.19]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane012.geometry}
        material={nodes.Plane012.material}
        position={[-0.42, 0.04, 0.76]}
        rotation={[-Math.PI, -0.41, -Math.PI]}
        scale={[0.06, 0.06, 0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={nodes.Cube004.material}
        position={[3.63, 3.32, -4.28]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={nodes.Cube001.material}
        position={[3.63, 5.1, -4.28]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials["white.001"]}
        position={[-0.01, 2.58, -4.57]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane029.geometry}
        material={nodes.Plane029.material}
        position={[0.11, 4.34, -4.17]}
        rotation={[-Math.PI / 2, 1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane030.geometry}
        material={nodes.Plane030.material}
        position={[0.09, 5.27, -4.01]}
        rotation={[-Math.PI, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane031.geometry}
        material={nodes.Plane031.material}
        position={[0.08, 6.67, -4.02]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.15, 1.64, 1.91]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane032.geometry}
        material={nodes.Plane032.material}
        position={[0.09, 6.39, -3.92]}
        rotation={[1.25, -1.57, 0]}
        scale={[0.16, 0.31, 1.83]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane033_1.geometry}
          material={nodes.Plane033_1.material}
          position={[1.16, -0.21, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane034.geometry}
          material={nodes.Plane034.material}
          position={[2.12, -0.38, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane035.geometry}
          material={nodes.Plane035.material}
          position={[3.29, -0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane036_1.geometry}
          material={nodes.Plane036_1.material}
          position={[4.13, -0.74, 0]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder051.geometry}
        material={nodes.Cylinder051.material}
        position={[1.58, 4.46, -3.92]}
        rotation={[0, -1.57, 0]}
        scale={[-0.02, -0.45, -0.02]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere003.geometry}
        material={nodes.Sphere003.material}
        position={[1.59, 3.98, -3.93]}
        rotation={[0, -1.57, 0]}
        scale={[0.09, 0.09, 0.09]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle022.geometry}
        material={materials["Material.001"]}
        position={[0.43, 0, 0.23]}
        scale={[2.48, 2.48, 2.48]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle005.geometry}
        material={nodes.Circle005.material}
        position={[0.46, 0.05, 0.27]}
        scale={[2.02, 2.02, 2.02]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder041.geometry}
        material={nodes.Cylinder041.material}
        position={[-4.01, 3.98, -0.32]}
        rotation={[-Math.PI, 1.57, 0]}
        scale={[0.05, 1.52, 0.05]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere.geometry}
        material={nodes.Sphere.material}
        position={[-3.82, 3.29, -1.12]}
        scale={[0.25, 0.25, 0.25]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere001.geometry}
        material={nodes.Sphere001.material}
        position={[-4.03, 5.47, 1.16]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere002.geometry}
        material={nodes.Sphere002.material}
        position={[-4.03, 5.47, -0.32]}
        scale={[0.1, 0.1, 0.1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere004.geometry}
        material={nodes.Sphere004.material}
        position={[-4.03, 4.71, 1.17]}
        scale={[0.1, 0.1, 0.1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere005.geometry}
        material={nodes.Sphere005.material}
        position={[-4.03, 3.99, 1.17]}
        scale={[0.1, 0.1, 0.1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere006.geometry}
        material={nodes.Sphere006.material}
        position={[-4.03, 2.48, -1.78]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere007.geometry}
        material={nodes.Sphere007.material}
        position={[-4.03, 2.51, -1.11]}
        scale={[0.1, 0.1, 0.1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle001.geometry}
        material={nodes.Circle001.material}
        position={[-3.84, 4.16, 0.38]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[0.16, 0.16, 0.16]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cone.geometry}
          material={nodes.Cone.material}
          position={[-0.01, -0.3, 0.01]}
          rotation={[Math.PI, 0, 0]}
          scale={[0.41, 0.41, 0.41]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane022.geometry}
        material={nodes.Plane022.material}
        position={[-3.96, 3.85, 0.5]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[0.46, 0.46, 0.58]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane023.geometry}
        material={nodes.Plane023.material}
        position={[-3.92, 3.85, 0.33]}
        rotation={[1.77, 0.06, -1.55]}
        scale={[0.46, 0.46, 0.58]}
      />
      <group
        position={[-2.68, 1.48, 2.48]}
        rotation={[-Math.PI, 1.48, -Math.PI]}
        scale={[0.34, 0.54, 0.45]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005.geometry}
          material={nodes.Plane005.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_1.geometry}
          material={nodes.Plane005_1.material}
        />
      </group>
      <group
        position={[-2.68, 1.71, 2.52]}
        rotation={[0, -0.72, 0]}
        scale={[0.34, 0.54, 0.45]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane085.geometry}
          material={nodes.Plane085.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane085_1.geometry}
          material={nodes.Plane085_1.material}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane009.geometry}
        material={nodes.Plane009.material}
        position={[-2.95, 1.23, 1.65]}
        scale={[1, 1.05, 1.67]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane058.geometry}
        material={nodes.Plane058.material}
        position={[-1.88, 0, 3.09]}
        scale={[0.11, 0.11, 0.11]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane059.geometry}
        material={nodes.Plane059.material}
        position={[-1.88, 0, -0.12]}
        scale={[0.11, 0.11, 0.11]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane042.geometry}
        material={nodes.Plane042.material}
        position={[-3.14, 2.53, 0.88]}
        rotation={[1.56, 0.12, -1.54]}
        scale={[0.63, 0.53, 0.53]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle008.geometry}
        material={nodes.Circle008.material}
        position={[-3.19, 2.93, 1.37]}
        rotation={[Math.PI / 2, 0.16, -Math.PI / 2]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle009.geometry}
        material={nodes.Circle009.material}
        position={[-3.19, 2.93, 1.21]}
        rotation={[Math.PI / 2, 0.16, -Math.PI / 2]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle010.geometry}
        material={nodes.Circle010.material}
        position={[-3.19, 2.93, 1.05]}
        rotation={[Math.PI / 2, 0.16, -Math.PI / 2]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane044.geometry}
        material={nodes.Plane044.material}
        position={[-3.06, 2.67, 0.86]}
        rotation={[1.57, 0.13, -1.55]}
        scale={[-0.47, -0.25, -0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane045.geometry}
        material={nodes.Plane045.material}
        position={[-3.02, 2.42, 0.86]}
        rotation={[1.57, 0.13, -1.55]}
        scale={[-0.47, -0.25, -0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane046.geometry}
        material={nodes.Plane046.material}
        position={[-3.03, 2.42, 1.4]}
        rotation={[1.57, 0.13, -1.55]}
        scale={[-0.47, -0.25, -0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane037.geometry}
        material={nodes.Plane037.material}
        position={[-3.57, 4.88, 2.23]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[-0.47, -0.25, -0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane039.geometry}
        material={nodes.Plane039.material}
        position={[-3.58, 5.13, 2.23]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[-0.47, -0.25, -0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane040.geometry}
        material={nodes.Plane040.material}
        position={[-3.57, 5.13, 1.66]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[-0.47, -0.25, -0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane063.geometry}
        material={nodes.Plane063.material}
        position={[-3.58, 4.05, 2.23]}
        rotation={[Math.PI / 2, 0.02, -Math.PI / 2]}
        scale={[-0.47, -0.25, -0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane065.geometry}
        material={nodes.Plane065.material}
        position={[-3.58, 3.8, 2.23]}
        rotation={[Math.PI / 2, 0.02, -Math.PI / 2]}
        scale={[-0.47, -0.25, -0.06]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane014.geometry}
        material={nodes.Plane014.material}
        position={[-3.84, 3.97, 2.33]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.44, 0.71, 0.48]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane025.geometry}
        material={nodes.Plane025.material}
        position={[-3.84, 5.08, 2.33]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.44, 0.71, 0.48]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane017.geometry}
        material={nodes.Plane017.material}
        position={[-2.58, 1.51, 1.1]}
        rotation={[-Math.PI, 1.38, -Math.PI]}
        scale={[0.6, 0.23, 0.23]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane026.geometry}
        material={nodes.Plane026.material}
        position={[-2.52, 1.51, -0.12]}
        rotation={[-Math.PI, 1.54, -Math.PI]}
        scale={[0.6, 0.23, 0.23]}
      />
      <group onClick={() => alert("Yo")}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane008.geometry}
          material={nodes.Plane008.material}
          position={[-3.38, 2.53, 0.65]}
          rotation={[1.56, 0.12, -1.52]}
          scale={[1.35, 1.35, -0.91]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane001.geometry}
            material={nodes.Plane001.material}
            position={[-0.22, -0.01, -1.02]}
            rotation={[-0.13, -0.05, -1.57]}
            scale={[-0.08, -0.12, -0.11]}
          />
        </mesh>
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane021.geometry}
        material={materials.iphone}
        position={[-2.71, 1.49, -0.35]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[0.14, 0.27, 0.27]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle003.geometry}
        material={materials.Material}
        position={[-4, 6.15, -1.07]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[0.23, 0.23, 0.23]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials["Material.002"]}
        scale={[4, 4, 4]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane047.geometry}
        material={nodes.Plane047.material}
        position={[-3.92, 0.28, 4.26]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.3, 0.3, 0.3]}
      />
      <group
        position={[-3.81, 2.04, 2.13]}
        rotation={[0.16, 0.08, 0.02]}
        scale={[0.07, 0.38, 0.07]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder028_1.geometry}
          material={nodes.Cylinder028_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder028_2.geometry}
          material={nodes.Cylinder028_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder028_3.geometry}
          material={nodes.Cylinder028_3.material}
        />
      </group>
      <group
        position={[-3.69, 1.94, 2.03]}
        rotation={[-0.55, -0.91, -0.36]}
        scale={[0.07, 0.38, 0.07]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder029.geometry}
          material={nodes.Cylinder029.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder029_1.geometry}
          material={nodes.Cylinder029_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder029_2.geometry}
          material={nodes.Cylinder029_2.material}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle016.geometry}
        material={nodes.Circle016.material}
        position={[-3.75, 1.52, 2.08]}
        scale={[0.21, 0.21, 0.21]}
      />
      <group
        position={[-2.67, 1.99, 2.49]}
        rotation={[-1.5, 0.04, -0.93]}
        scale={[0.07, 0.38, 0.07]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006_1.geometry}
          material={nodes.Cylinder006_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006_2.geometry}
          material={nodes.Cylinder006_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006_3.geometry}
          material={nodes.Cylinder006_3.material}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kcinlab001.geometry}
        material={nodes.Kcinlab001.material}
        position={[-3.66, 7.86, 4.46]}
        rotation={[1.57, 0.1, -1.52]}
      />
      <group position={[3.45, 0.27, -2.9]} scale={[0.55, 0.55, 0.55]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle015.geometry}
          material={nodes.Circle015.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle015_1.geometry}
          material={nodes.Circle015_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle018.geometry}
          material={nodes.Circle018.material}
          position={[0.16, 1.22, -0.49]}
          scale={[0.75, 0.75, 0.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane002_1.geometry}
          material={nodes.Plane002_1.material}
          position={[-0.11, 1.23, -0.32]}
          rotation={[-1.23, -0.52, -0.1]}
          scale={[-0.2, -0.2, -0.2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane048_1.geometry}
          material={nodes.Plane048_1.material}
          position={[0.11, 1.28, -0.81]}
          rotation={[-1.61, 0.79, 2.89]}
          scale={[-0.19, -0.19, -0.19]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane050.geometry}
        material={nodes.Plane050.material}
        position={[0.05, 0.76, -2.74]}
        rotation={[0, -1.57, 0]}
        scale={[0.62, 1.01, 0.87]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane049.geometry}
          material={nodes.Plane049.material}
          position={[-0.54, -0.47, -1.2]}
          scale={[1.63, 1.25, 2.57]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane051.geometry}
          material={nodes.Plane051.material}
          position={[0, 0, -1.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane052.geometry}
          material={nodes.Plane052.material}
          position={[-1.55, 0.45, -0.04]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={[0.88, 1.27, 1.02]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane053_1.geometry}
          material={nodes.Plane053_1.material}
          position={[-0.44, 0.63, -2.79]}
          rotation={[0.44, -0.05, -0.04]}
          scale={[0.64, 0.35, 0.45]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane054_1.geometry}
          material={nodes.Plane054_1.material}
          position={[-0.01, 0.59, 0.8]}
          rotation={[2.74, 0.11, -3.13]}
          scale={[0.6, 0.33, 0.42]}
          onClick={() => alert("跟人家坐著聊聊天 >///<")}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane055.geometry}
          material={nodes.Plane055.material}
          position={[-0.31, 0.61, -1.76]}
          rotation={[0.82, 0.56, -0.64]}
          scale={[0.49, 0.35, 0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane056_1.geometry}
          material={nodes.Plane056_1.material}
          position={[2.2, -0.98, 1.18]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[-0.17, -0.14, -0.23]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane057.geometry}
          material={nodes.Plane057.material}
          position={[2.2, -0.98, -3.15]}
          rotation={[0, Math.PI / 2, 0]}
          scale={[-0.17, -0.14, -0.23]}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane067.geometry}
        material={nodes.Plane067.material}
        position={[-2.56, 1.61, -2]}
        rotation={[-0.03, -0.38, -1.48]}
        scale={[0.17, 0.17, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane066.geometry}
        material={nodes.Plane066.material}
        position={[-2.72, 1.62, -1.6]}
        rotation={[-0.03, -0.38, -1.48]}
        scale={[0.17, 0.17, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane024.geometry}
        material={nodes.Plane024.material}
        position={[-2.41, 1.6, -2.38]}
        rotation={[-0.03, -0.38, -1.48]}
        scale={[0.17, 0.17, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane068.geometry}
        material={nodes.Plane068.material}
        position={[-2.25, 1.59, -2.77]}
        rotation={[-0.03, -0.38, -1.48]}
        scale={[0.17, 0.17, 0.13]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle002.geometry}
        material={nodes.Circle002.material}
        position={[-2.44, 3.05, -2.82]}
        rotation={[-0.04, -0.39, -1.42]}
        scale={[0.33, 0.33, 0.33]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane070.geometry}
        material={nodes.Plane070.material}
        position={[-2.35, 3.08, -2.79]}
        rotation={[-0.04, -0.39, -1.42]}
        scale={[-0.08, -0.08, -0.08]}
        onClick={() => alert("看人家的目標 >///<")}
      />
      <group
        position={[-1.73, 0.37, -4.99]}
        rotation={[0, -0.4, -1.37]}
        scale={[1.38, 1.38, 1.12]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane015.geometry}
          material={nodes.Plane015.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane015_1.geometry}
          material={nodes.Plane015_1.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane061_1.geometry}
          material={nodes.Plane061_1.material}
          position={[0.83, 0.33, 3.47]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[-0.08, -0.1, -0.09]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane062_1.geometry}
          material={nodes.Plane062_1.material}
          position={[0.83, 0.33, 1.62]}
          rotation={[0, 0, Math.PI / 2]}
          scale={[-0.08, -0.1, -0.09]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane064_1.geometry}
          material={nodes.Plane064_1.material}
          position={[0.57, -0.97, 2.55]}
          rotation={[0, 0, 1.1]}
          scale={[-0.08, -0.1, -0.09]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle024.geometry}
        material={materials.sofa}
        position={[-3.81, 4.98, -1.24]}
        rotation={[0.02, 0, -Math.PI / 2]}
        scale={[0.7, 0.7, 0.7]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle023.geometry}
        material={nodes.Circle023.material}
        position={[-3.78, 4.95, -1.28]}
        rotation={[0.02, 0, -Math.PI / 2]}
        scale={[0.78, 0.78, 0.78]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle006.geometry}
        material={nodes.Circle006.material}
        position={[-3.95, 5.52, 0.46]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.38, 0.28, 0.38]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane071.geometry}
        material={nodes.Plane071.material}
        position={[-3.91, 5.53, 0.46]}
        rotation={[0.75, 0, -Math.PI / 2]}
        scale={[-0.08, -0.08, -0.08]}
      />
    </group>
  );
}

useGLTF.preload("/room 2_01.gltf");
