const MockData = [
  {
    company_name: "健康傳媒國際事業有限公司",
    company_tax_id: 28844229,
    company_address: "臺北市中正區羅斯福路2段56號7樓之3",
    company_lat: 25.027762,
    company_lng: 121.5216212,
    paid_at: "2020-09-25T10:11:03.468+08:00",
    member_name: "劉育名",
    member_address: "新北市三重區永興里15鄰福德南路24巷2之4號3樓",
    member_lat: 25.0632754,
    member_lng: 121.5029973,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    company_tax_id: 50798939,
    company_address: "臺北市中山區建國北路2段129號2樓",
    company_lat: 25.059998,
    company_lng: 121.5373357,
    paid_at: "2020-10-06T10:53:36.538+08:00",
    member_name: "高永祺",
    member_address: "台北市大安區復興南路一段66號5樓",
    member_lat: 25.0444025,
    member_lng: 121.5436193,
  },
  {
    company_name: "練習曲文創有限公司",
    company_tax_id: 83445943,
    company_address: "花蓮縣新城鄉新城村17鄰博愛路67號",
    company_lat: 24.1265949,
    company_lng: 121.651409,
    paid_at: "2020-10-13T15:35:38.209+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "練習曲文創有限公司",
    company_tax_id: 83445943,
    company_address: "花蓮縣新城鄉新城村17鄰博愛路67號",
    company_lat: 24.1265949,
    company_lng: 121.651409,
    paid_at: "2020-10-13T15:35:38.209+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "星予公關顧問有限公司",
    company_tax_id: 52359823,
    company_address: "臺北市士林區文昌路100號2樓",
    company_lat: 25.0965228,
    company_lng: 121.5211867,
    paid_at: "2020-11-13T11:31:02.867+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "伽馬實業有限公司",
    company_tax_id: 24959852,
    company_address: "臺北市大同區迪化街1段14巷38號",
    company_lat: 25.0541296,
    company_lng: 121.5093384,
    paid_at: "2020-11-16T00:05:01.881+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "春富光電科技有限公司",
    company_tax_id: 54040373,
    company_address: "新北市樹林區太元街110巷14號",
    company_lat: 24.9776491,
    company_lng: 121.4193102,
    paid_at: "2020-11-17T15:02:16.614+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    company_tax_id: 50798939,
    company_address: "臺北市中山區建國北路2段129號2樓",
    company_lat: 25.059998,
    company_lng: 121.5373357,
    paid_at: "2020-10-06T10:53:36.538+08:00",
    member_name: "高永祺",
    member_address: "台北市大安區復興南路一段66號5樓",
    member_lat: 25.0444025,
    member_lng: 121.5436193,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    company_tax_id: 50798939,
    company_address: "臺北市中山區建國北路2段129號2樓",
    company_lat: 25.059998,
    company_lng: 121.5373357,
    paid_at: "2020-10-06T10:53:36.538+08:00",
    member_name: "高永祺",
    member_address: "台北市大安區復興南路一段66號5樓",
    member_lat: 25.0444025,
    member_lng: 121.5436193,
  },
  {
    company_name: "威銘儀器有限公司",
    company_tax_id: 28197004,
    company_address: "新北市新店區安興路127之12號5樓",
    company_lat: 24.9789982,
    company_lng: 121.5147574,
    paid_at: "2020-11-30T14:09:07.623+08:00",
    member_name: "楊鈞丞",
    member_address: "台北市文山區政大里9鄰政大二街18號",
    member_lat: 24.9880479,
    member_lng: 121.5865644,
  },
  {
    company_name: "國立中正大學",
    company_tax_id: "06313774",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2020-12-09T11:37:13.169+08:00",
    member_name: "何威廷",
    member_address: "臺南市安平區建平七街370巷131弄43號",
    member_lat: 22.9895606,
    member_lng: 120.1768171,
  },
  {
    company_name: "國立臺灣藝術大學",
    company_tax_id: 95927022,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2020-12-09T15:30:38.995+08:00",
    member_name: "莊文化",
    member_address: "台南市仁德區中正路一段82號",
    member_lat: 22.9191875,
    member_lng: 120.231622,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 23.0534043,
    member_lng: 120.1664231,
  },
  {
    company_name: "和泉健康節能有限公司",
    company_tax_id: 50752259,
    company_address: "臺北市內湖區陽光街345巷12號4樓",
    company_lat: 25.073187,
    company_lng: 121.578539,
    paid_at: "2020-12-10T00:49:42.415+08:00",
    member_name: "王昇",
    member_address: "新竹市東區仙水里光復路一段373號4樓之3",
    member_lat: 24.7832591,
    member_lng: 121.0172125,
  },
  {
    company_name: "和泉健康節能有限公司",
    company_tax_id: 50752259,
    company_address: "臺北市內湖區陽光街345巷12號4樓",
    company_lat: 25.073187,
    company_lng: 121.578539,
    paid_at: "2020-12-10T00:49:42.415+08:00",
    member_name: "王昇",
    member_address: "新竹市東區仙水里光復路一段373號4樓之3",
    member_lat: 25.069278,
    member_lng: 121.641272,
  },
  {
    company_name: "美澤生活事業有限公司",
    company_tax_id: 83261634,
    company_address: "臺北市大安區瑞安街142巷2之1號",
    company_lat: 25.0293732,
    company_lng: 121.5418056,
    paid_at: "2020-12-12T21:23:44.292+08:00",
    member_name: "魏廷蓉",
    member_address: "台北市松山區民權東路三段191巷七弄三號",
    member_lat: 25.0638195,
    member_lng: 121.5466226,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    company_tax_id: 50798939,
    company_address: "臺北市中山區建國北路2段129號2樓",
    company_lat: 25.059998,
    company_lng: 121.5373357,
    paid_at: "2020-10-06T10:53:36.538+08:00",
    member_name: "高永祺",
    member_address: "台北市大安區復興南路一段66號5樓",
    member_lat: 25.0444025,
    member_lng: 121.5436193,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "願如是股份有限公司",
    company_tax_id: 82955186,
    company_address: "新北市永和區保安路90號",
    company_lat: 25.0160972,
    company_lng: 121.509486,
    paid_at: "2020-12-21T23:34:01.888+08:00",
    member_name: "魏廷蓉",
    member_address: "台北市松山區民權東路三段191巷七弄三號",
    member_lat: 25.0638195,
    member_lng: 121.5466226,
  },
  {
    company_name: "知己者創意數位行銷有限公司",
    company_tax_id: 83460253,
    company_address: "臺北市大同區西寧北路78之16號8樓",
    company_lat: 25.0544273,
    company_lng: 121.5086744,
    paid_at: "2020-12-22T11:38:11.916+08:00",
    member_name: "覃基豪",
    member_address: "台北市大同區重慶北路三段236巷44弄20號4樓",
    member_lat: 25.0703556,
    member_lng: 121.5120986,
  },
  {
    company_name: "台灣戴姆勒亞洲商車股份有限公司職工福利委員會",
    company_tax_id: 77019787,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2020-12-23T22:21:48.778+08:00",
    member_name: "林陳揚",
    member_address: "台北市萬華區雙園里3鄰興寧街71號三樓",
    member_lat: 25.0323203,
    member_lng: 121.4998264,
  },
  {
    company_name: "春富光電科技有限公司",
    company_tax_id: 54040373,
    company_address: "新北市樹林區太元街110巷14號",
    company_lat: 24.9776491,
    company_lng: 121.4193102,
    paid_at: "2020-11-17T15:02:16.614+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "信佳人資服務有限公司",
    company_tax_id: 80636454,
    company_address: "桃園市中壢區自強里莊敬路119號",
    company_lat: 24.9696187,
    company_lng: 121.2574626,
    paid_at: "2021-01-08T14:39:31.349+08:00",
    member_name: "黃琬真",
    member_address: "桃園市中壢區中堅里21鄰同慶路360巷2號8樓",
    member_lat: 24.9396486,
    member_lng: 121.2576911,
  },
  {
    company_name: "為烽科技有限公司",
    company_tax_id: 82809987,
    company_address: "臺北市士林區後港街147號1樓",
    company_lat: 25.0870669,
    company_lng: 121.5214867,
    paid_at: "2021-01-08T16:03:19.297+08:00",
    member_name: "王昇",
    member_address: "新竹市東區仙水里光復路一段373號4樓之3",
    member_lat: 24.7832591,
    member_lng: 121.0172125,
  },
  {
    company_name: "和泉健康節能有限公司",
    company_tax_id: 50752259,
    company_address: "臺北市內湖區陽光街345巷12號4樓",
    company_lat: 25.073187,
    company_lng: 121.578539,
    paid_at: "2020-12-10T00:49:42.415+08:00",
    member_name: "王昇",
    member_address: "新竹市東區仙水里光復路一段373號4樓之3",
    member_lat: 24.7832591,
    member_lng: 121.0172125,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 23.0534043,
    member_lng: 120.1664231,
  },
  {
    company_name: "蒂嘉國際有限公司",
    company_tax_id: 66621260,
    company_address: "臺北市內湖區瑞光路358巷30弄15號4樓",
    company_lat: 25.0763898,
    company_lng: 121.5730712,
    paid_at: "2021-01-15T21:48:27.191+08:00",
    member_name: "王昇",
    member_address: "新竹市東區仙水里光復路一段373號4樓之3",
    member_lat: 24.7832591,
    member_lng: 121.0172125,
  },
  {
    company_name: "歐奇國際事業有限公司",
    company_tax_id: 24754204,
    company_address: "臺北市中山區合江街20巷35號1樓、地下",
    company_lat: 25.0552613,
    company_lng: 121.5380001,
    paid_at: "2021-01-16T13:11:50.098+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "維斯塔行銷顧問有限公司",
    company_tax_id: 83434777,
    company_address: "新北市中和區建八路2號9樓之2",
    company_lat: 24.9982308,
    company_lng: 121.4862998,
    paid_at: "2021-01-22T10:12:22.625+08:00",
    member_name: "黃祺芸",
    member_address: "台北市文山區樟文里11鄰木新路三段310巷14弄18號三樓",
    member_lat: 24.9825017,
    member_lng: 121.5574692,
  },
  {
    company_name: "新譯漢方有限公司",
    company_tax_id: 24912983,
    company_address: "臺南市西港區南海里東港28號1樓",
    company_lat: 23.1075966,
    company_lng: 120.1964522,
    paid_at: "2021-01-22T19:39:35.793+08:00",
    member_name: "林昀駿",
    member_address: "雲林縣北港鎮西勢里4鄰文化路30之1號",
    member_lat: 23.567619,
    member_lng: 120.3012286,
  },
  {
    company_name: "唯聖紡織股份有限公司",
    company_tax_id: 23934881,
    company_address: "新北市鶯歌區德昌二街70巷32弄77號",
    company_lat: 24.9579417,
    company_lng: 121.3301294,
    paid_at: "2021-01-25T17:44:45.208+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 24.489881,
    member_lng: 120.782511,
  },
  {
    company_name: "漢翔企業社",
    company_tax_id: 39677894,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-02-01T16:36:47.388+08:00",
    member_name: "林湘玟",
    member_address: "台南市安平區健康三街12巷1號3F-18",
    member_lat: 22.9851056,
    member_lng: 120.1683718,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "品則股份有限公司",
    company_tax_id: 55771115,
    company_address: "臺北市信義區基隆路2段77號4樓之7",
    company_lat: 25.0302461,
    company_lng: 121.5580997,
    paid_at: "2021-02-03T15:37:42.047+08:00",
    member_name: "鄧立心",
    member_address: "臺北市中正區和平西路2段52巷3號3樓",
    member_lat: 25.0283906,
    member_lng: 121.5124275,
  },
  {
    company_name: "品則股份有限公司",
    company_tax_id: 55771115,
    company_address: "臺北市信義區基隆路2段77號4樓之7",
    company_lat: 25.0302461,
    company_lng: 121.5580997,
    paid_at: "2021-02-03T15:37:42.047+08:00",
    member_name: "鄧立心",
    member_address: "臺北市中正區和平西路2段52巷3號3樓",
    member_lat: 25.0283906,
    member_lng: 121.5124275,
  },
  {
    company_name: "春富光電科技有限公司",
    company_tax_id: 54040373,
    company_address: "新北市樹林區太元街110巷14號",
    company_lat: 24.9776491,
    company_lng: 121.4193102,
    paid_at: "2020-11-17T15:02:16.614+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "德立媒體行銷有限公司",
    company_tax_id: 53129146,
    company_address: "臺北市松山區光復北路112號10樓",
    company_lat: 25.0508529,
    company_lng: 121.557661,
    paid_at: "2021-02-05T12:24:07.829+08:00",
    member_name: "洪宛薐",
    member_address: "高雄市鼓山區華豐里30鄰華榮路440號8樓之2",
    member_lat: 22.6695612,
    member_lng: 120.294178,
  },
  {
    company_name: "中國青年救國團桃園市中壢國民運動中心",
    company_tax_id: 82195670,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-02-05T13:57:47.982+08:00",
    member_name: "張辰宇",
    member_address: "桃園市桃園區青溪里3鄰鎮二街41號",
    member_lat: 24.9990709,
    member_lng: 121.315304,
  },
  {
    company_name: "信佳人資服務有限公司",
    company_tax_id: 80636454,
    company_address: "桃園市中壢區自強里莊敬路119號",
    company_lat: 24.9696187,
    company_lng: 121.2574626,
    paid_at: "2021-01-08T14:39:31.349+08:00",
    member_name: "黃琬真",
    member_address: "桃園市中壢區中堅里21鄰同慶路360巷2號8樓",
    member_lat: 24.9396486,
    member_lng: 121.2576911,
  },
  {
    company_name: "伽馬實業有限公司",
    company_tax_id: 24959852,
    company_address: "臺北市大同區迪化街1段14巷38號",
    company_lat: 25.0541296,
    company_lng: 121.5093384,
    paid_at: "2020-11-16T00:05:01.881+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "伽馬實業有限公司",
    company_tax_id: 24959852,
    company_address: "臺北市大同區迪化街1段14巷38號",
    company_lat: 25.0541296,
    company_lng: 121.5093384,
    paid_at: "2020-11-16T00:05:01.881+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "馨安養生事業有限公司",
    company_tax_id: 50841318,
    company_address: "新北市林口區忠孝三路30號(1樓)",
    company_lat: 25.0698785,
    company_lng: 121.3605592,
    paid_at: "2021-02-19T14:47:04.832+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "德禾國際工業有限公司",
    company_tax_id: 89765703,
    company_address: "高雄市苓雅區武漢街167號1樓",
    company_lat: 22.6198299,
    company_lng: 120.3259376,
    paid_at: "2021-02-21T21:40:44.689+08:00",
    member_name: "蘇祥輝",
    member_address: "高雄市路竹區竹西里18鄰國昌路415巷22號",
    member_lat: 22.8623639,
    member_lng: 120.2571554,
  },
  {
    company_name: "空島設計有限公司",
    company_tax_id: 42854970,
    company_address: "臺北市士林區劍潭路45號",
    company_lat: 25.0839012,
    company_lng: 121.5216725,
    paid_at: "2021-02-25T22:38:44.131+08:00",
    member_name: "莊文化",
    member_address: "台南市仁德區中正路一段82號",
    member_lat: 22.9191875,
    member_lng: 120.231622,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 24.489881,
    member_lng: 120.782511,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 24.489881,
    member_lng: 120.782511,
  },
  {
    company_name: "紘祥實業有限公司",
    company_tax_id: 50793293,
    company_address: "臺北市中正區重慶南路1段95號11樓",
    company_lat: 25.0436733,
    company_lng: 121.5130016,
    paid_at: "2021-03-05T12:25:26.83+08:00",
    member_name: "林晨浩",
    member_address: "南投縣竹山鎮中山路26之6號",
    member_lat: 23.7529831,
    member_lng: 120.689902,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "德立媒體行銷有限公司",
    company_tax_id: 53129146,
    company_address: "臺北市松山區光復北路112號10樓",
    company_lat: 25.0508529,
    company_lng: 121.557661,
    paid_at: "2021-02-05T12:24:07.829+08:00",
    member_name: "洪宛薐",
    member_address: "高雄市鼓山區華豐里30鄰華榮路440號8樓之2",
    member_lat: 22.6695612,
    member_lng: 120.294178,
  },
  {
    company_name: "金上吉塑膠股份有限公司",
    company_tax_id: 89905351,
    company_address: "臺南市安南區佃東里安新五路285號",
    company_lat: 23.080822,
    company_lng: 120.1861314,
    paid_at: "2021-03-19T10:57:55.058+08:00",
    member_name: "譚竟汶",
    member_address: "臺北市文山區萬和里10鄰汀州路四段229號十樓",
    member_lat: 25.0026333,
    member_lng: 121.535933,
  },
  {
    company_name: "知己者創意數位行銷有限公司",
    company_tax_id: 83460253,
    company_address: "臺北市大同區西寧北路78之16號8樓",
    company_lat: 25.0544273,
    company_lng: 121.5086744,
    paid_at: "2020-12-22T11:38:11.916+08:00",
    member_name: "覃基豪",
    member_address: "台北市大同區重慶北路三段236巷44弄20號4樓",
    member_lat: 25.0703556,
    member_lng: 121.5120986,
  },
  {
    company_name: "哲煜科技股份有限公司",
    company_tax_id: 43774383,
    company_address: "臺北市中山區復興北路80號12樓",
    company_lat: 25.0510614,
    company_lng: 121.5438226,
    paid_at: "2021-03-26T17:41:36.13+08:00",
    member_name: "蘇祥輝",
    member_address: "高雄市路竹區竹西里18鄰國昌路415巷22號",
    member_lat: 22.8623639,
    member_lng: 120.2571554,
  },
  {
    company_name: "有保庇宗教文創股份有限公司",
    company_tax_id: 90825840,
    company_address: "臺中市北區雙十路二段101號3樓之1",
    company_lat: 24.1558986,
    company_lng: 120.689922,
    paid_at: "2021-03-29T15:05:48.045+08:00",
    member_name: "魏廷蓉",
    member_address: "台北市松山區民權東路三段191巷七弄三號",
    member_lat: 25.0638195,
    member_lng: 121.5466226,
  },
  {
    company_name: "亮采生物科技有限公司",
    company_tax_id: 24255122,
    company_address: "新北市板橋區中山路1段3號5樓",
    company_lat: 24.9698996,
    company_lng: 121.4321567,
    paid_at: "2021-03-31T12:57:27.465+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "恆易資訊有限公司",
    company_tax_id: 82876161,
    company_address: "新北市中和區中山路2段568巷26號2樓",
    company_lat: 25.0065366,
    company_lng: 121.4826825,
    paid_at: "2021-03-31T14:37:45.827+08:00",
    member_name: "王林郁翔",
    member_address: "台北市南港區南港里3鄰東南街17號",
    member_lat: 25.0548172,
    member_lng: 121.6124211,
  },
  {
    company_name: "紘祥實業有限公司",
    company_tax_id: 50793293,
    company_address: "臺北市中正區重慶南路1段95號11樓",
    company_lat: 25.0436733,
    company_lng: 121.5130016,
    paid_at: "2021-03-05T12:25:26.83+08:00",
    member_name: "林晨浩",
    member_address: "南投縣竹山鎮中山路26之6號",
    member_lat: 23.7529831,
    member_lng: 120.689902,
  },
  {
    company_name: "給樂工作室",
    company_tax_id: 72928470,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-07T19:04:45.551+08:00",
    member_name: "周奕安",
    member_address: "臺北市士林區後港里25鄰通河街東二段27號四樓",
    member_lat: 25.0801246,
    member_lng: 121.5231455,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 24.489881,
    member_lng: 120.782511,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 22.6330559,
    member_lng: 120.3689482,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 22.6330559,
    member_lng: 120.3689482,
  },
  {
    company_name: "自由人藝術設計股份有限公司",
    company_tax_id: 53874137,
    company_address: "臺中市西屯區西平南巷5弄12號1樓",
    company_lat: 24.1947779,
    company_lng: 120.6170344,
    paid_at: "2021-04-09T15:50:24.83+08:00",
    member_name: "李東峻",
    member_address: "南投縣中寮鄉內城4臨龍南路22號",
    member_lat: 23.9421261,
    member_lng: 120.7720261,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "自由人藝術設計股份有限公司",
    company_tax_id: 53874137,
    company_address: "臺中市西屯區西平南巷5弄12號1樓",
    company_lat: 24.1947779,
    company_lng: 120.6170344,
    paid_at: "2021-04-09T15:50:24.83+08:00",
    member_name: "李東峻",
    member_address: "南投縣中寮鄉內城4臨龍南路22號",
    member_lat: 23.9421261,
    member_lng: 120.7720261,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "馥甘有限公司",
    company_tax_id: 50869476,
    company_address: "臺北市松山區南京東路4段133巷5弄2號",
    company_lat: 25.0523753,
    company_lng: 121.5552234,
    paid_at: "2021-04-13T11:32:40.294+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "紅創數位科技股份有限公司",
    company_tax_id: 42655965,
    company_address: "臺北市松山區南京東路4段1號8樓",
    company_lat: 25.0519605,
    company_lng: 121.5495511,
    paid_at: "2021-04-15T14:48:44.502+08:00",
    member_name: "劉柏年",
    member_address: "桃園區中壢市永福路961號8樓",
    member_lat: 24.9630312,
    member_lng: 121.2530793,
  },
  {
    company_name: "苔蘚文化有限公司",
    company_tax_id: 83552062,
    company_address: "新竹縣竹北市十興里勝利六街98號1樓",
    company_lat: 24.8247004,
    company_lng: 121.0268999,
    paid_at: "2021-04-20T10:32:48.264+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "人人愛有限公司",
    company_tax_id: 83206020,
    company_address: "臺北市中正區中華路2段127號5樓",
    company_lat: 25.0321115,
    company_lng: 121.5047715,
    paid_at: "2021-04-21T10:56:13.398+08:00",
    member_name: "張辰宇",
    member_address: "桃園市桃園區青溪里3鄰鎮二街41號",
    member_lat: 24.9990709,
    member_lng: 121.315304,
  },
  {
    company_name: "德立媒體行銷有限公司",
    company_tax_id: 53129146,
    company_address: "臺北市松山區光復北路112號10樓",
    company_lat: 25.0508529,
    company_lng: 121.557661,
    paid_at: "2021-02-05T12:24:07.829+08:00",
    member_name: "洪宛薐",
    member_address: "高雄市鼓山區華豐里30鄰華榮路440號8樓之2",
    member_lat: 22.6695612,
    member_lng: 120.294178,
  },
  {
    company_name: "若晨室內裝修有限公司",
    company_tax_id: 83075603,
    company_address: "新北市新莊區新崑路179號3樓",
    company_lat: 25.0001902,
    company_lng: 121.4331912,
    paid_at: "2021-04-23T10:00:46.108+08:00",
    member_name: "鄭婷君",
    member_address: "新竹縣竹北市東興路二段1020巷73號",
    member_lat: 24.7849112,
    member_lng: 121.0651768,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "國立臺灣藝術大學",
    company_tax_id: 95927022,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2020-12-09T15:30:38.995+08:00",
    member_name: "莊文化",
    member_address: "台南市仁德區中正路一段82號",
    member_lat: 25.0542482,
    member_lng: 121.5357688,
  },
  {
    company_name: "南山人壽保險股份有限公司",
    company_tax_id: 11456006,
    company_address: "臺北市信義區莊敬路168號",
    company_lat: 25.0317005,
    company_lng: 121.5611392,
    paid_at: "2021-04-29T12:52:58.101+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "昱廣貿易有限公司",
    company_tax_id: 42746924,
    company_address: "桃園市龜山區南美里南上路280巷13-1號1樓",
    company_lat: 25.045472,
    company_lng: 121.3009319,
    paid_at: "2021-04-29T13:26:31.564+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "無限量股份有限公司",
    company_tax_id: 82816087,
    company_address: "新北市新莊區頭前路161號10樓",
    company_lat: 25.0521403,
    company_lng: 121.461069,
    paid_at: "2021-04-29T14:15:55.299+08:00",
    member_name: "張博清",
    member_address: "高雄市湖內區公館里11鄰中正路一段157號",
    member_lat: 22.9092377,
    member_lng: 120.2260519,
  },
  {
    company_name: "國立中山大學",
    company_tax_id: 76211194,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-29T16:49:24.04+08:00",
    member_name: "李崇偉",
    member_address: "台北市萬華區榮德里14鄰萬大路486巷48號四樓之六",
    member_lat: 25.0207186,
    member_lng: 121.495583,
  },
  {
    company_name: "財團法人基督之家",
    company_tax_id: "04129648",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-30T23:05:17.422+08:00",
    member_name: "蘇祥輝",
    member_address: "高雄市路竹區竹西里18鄰國昌路415巷22號",
    member_lat: 22.8623639,
    member_lng: 120.2571554,
  },
  {
    company_name: "群眾自造股份有限公司",
    company_tax_id: 52354974,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2021-05-01T00:42:16.938+08:00",
    member_name: "謝明佑",
    member_address: "台北市信義區廣居里15鄰忠孝東路五段372巷27弄13號5樓",
    member_lat: 25.0397775,
    member_lng: 121.5744715,
  },
  {
    company_name: "雲想文創股份有限公司",
    company_tax_id: 53560754,
    company_address: "臺北市松山區敦化北路170號3樓",
    company_lat: 25.0564442,
    company_lng: 121.5485702,
    paid_at: "2021-05-02T18:33:51.219+08:00",
    member_name: "陳瑞軒",
    member_address: "桃園市中壢區大仁二街15號4樓之11",
    member_lat: 24.9548991,
    member_lng: 121.2422706,
  },
  {
    company_name: "歐奇國際事業有限公司",
    company_tax_id: 24754204,
    company_address: "臺北市中山區合江街20巷35號1樓、地下",
    company_lat: 25.0552613,
    company_lng: 121.5380001,
    paid_at: "2021-01-16T13:11:50.098+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "紘祥實業有限公司",
    company_tax_id: 50793293,
    company_address: "臺北市中正區重慶南路1段95號11樓",
    company_lat: 25.0436733,
    company_lng: 121.5130016,
    paid_at: "2021-03-05T12:25:26.83+08:00",
    member_name: "林晨浩",
    member_address: "南投縣竹山鎮中山路26之6號",
    member_lat: 23.7529831,
    member_lng: 120.689902,
  },
  {
    company_name: "德立媒體行銷有限公司",
    company_tax_id: 53129146,
    company_address: "臺北市松山區光復北路112號10樓",
    company_lat: 25.0508529,
    company_lng: 121.557661,
    paid_at: "2021-02-05T12:24:07.829+08:00",
    member_name: "洪宛薐",
    member_address: "高雄市鼓山區華豐里30鄰華榮路440號8樓之2",
    member_lat: 22.6695612,
    member_lng: 120.294178,
  },
  {
    company_name: "多香果舖",
    company_tax_id: 42365017,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-05T18:16:37.741+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "余宗鳴律師事務所",
    company_tax_id: 74721658,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-05T18:38:06.797+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "崑山科技大學",
    company_tax_id: 73502108,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-06T00:54:01.159+08:00",
    member_name: "彭子恆",
    member_address: "桃園市楊梅區楊明里22鄰新農街376號6樓",
    member_lat: 24.9116831,
    member_lng: 121.1569771,
  },
  {
    company_name: "崑山科技大學",
    company_tax_id: 73502108,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-06T00:54:01.159+08:00",
    member_name: "彭子恆",
    member_address: "桃園市楊梅區楊明里22鄰新農街376號6樓",
    member_lat: 24.9116831,
    member_lng: 121.1569771,
  },
  {
    company_name: "崑山科技大學",
    company_tax_id: 73502108,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-06T00:54:01.159+08:00",
    member_name: "彭子恆",
    member_address: "桃園市楊梅區楊明里22鄰新農街376號6樓",
    member_lat: 24.9116831,
    member_lng: 121.1569771,
  },
  {
    company_name: "維斯塔行銷顧問有限公司",
    company_tax_id: 83434777,
    company_address: "新北市中和區建八路2號9樓之2",
    company_lat: 24.9982308,
    company_lng: 121.4862998,
    paid_at: "2021-01-22T10:12:22.625+08:00",
    member_name: "黃祺芸",
    member_address: "台北市文山區樟文里11鄰木新路三段310巷14弄18號三樓",
    member_lat: 24.9825017,
    member_lng: 121.5574692,
  },
  {
    company_name: "給樂工作室",
    company_tax_id: 72928470,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-07T19:04:45.551+08:00",
    member_name: "周奕安",
    member_address: "臺北市士林區後港里25鄰通河街東二段27號四樓",
    member_lat: 25.0801246,
    member_lng: 121.5231455,
  },
  {
    company_name: "而秝顧問股份有限公司",
    company_tax_id: 85013619,
    company_address: "臺北市大安區忠孝東路4段169號11樓之4",
    company_lat: 25.0417443,
    company_lng: 121.5502401,
    paid_at: "2021-05-13T10:54:50.167+08:00",
    member_name: "黃鈺茹",
    member_address: "709台南市安南區安中路四段320巷95弄10號",
    member_lat: 23.0534043,
    member_lng: 120.1664231,
  },
  {
    company_name: "中強光電股份有限公司",
    company_tax_id: 22099907,
    company_address: "新竹市東區力行路11號",
    company_lat: 24.7684894,
    company_lng: 121.0280192,
    paid_at: "2021-05-14T11:45:58.028+08:00",
    member_name: "許乃蓉",
    member_address: "嘉義縣水上鄉南鄉村6鄰牛稠埔42號",
    member_lat: 23.407121,
    member_lng: 120.468528,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "圓山大飯店有限公司",
    company_tax_id: 70350107,
    company_address: "臺北市中山區中山北路４段１巷１號１０樓",
    company_lat: 25.0786107,
    company_lng: 121.5262867,
    paid_at: "2021-05-19T20:32:04.763+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "財團法人台灣敦睦聯誼會高雄圓山大飯店",
    company_tax_id: 85235083,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-19T21:23:11.867+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "皇錩實業有限公司",
    company_tax_id: 22559839,
    company_address: "新北市三重區國道路1段85巷3號(1樓)",
    company_lat: 25.0726758,
    company_lng: 121.4799074,
    paid_at: "2021-05-20T12:22:10.165+08:00",
    member_name: "劉育名",
    member_address: "新北市三重區永興里15鄰福德南路24巷2之4號3樓",
    member_lat: 25.0632754,
    member_lng: 121.5029973,
  },
  {
    company_name: "為烽科技有限公司",
    company_tax_id: 82809987,
    company_address: "臺北市士林區後港街147號1樓",
    company_lat: 25.0870669,
    company_lng: 121.5214867,
    paid_at: "2021-01-08T16:03:19.297+08:00",
    member_name: "王昇",
    member_address: "新竹市東區仙水里光復路一段373號4樓之3",
    member_lat: 24.7832591,
    member_lng: 121.0172125,
  },
  {
    company_name: "阮綜合社團法人阮綜合醫院",
    company_tax_id: 57983950,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-24T11:15:36.439+08:00",
    member_name: "何承謙",
    member_address: "高雄市鳳山區南正一路30號6樓",
    member_lat: 22.5948749,
    member_lng: 120.3369817,
  },
  {
    company_name: "練習曲文創有限公司",
    company_tax_id: 83445943,
    company_address: "花蓮縣新城鄉新城村17鄰博愛路67號",
    company_lat: 24.1265949,
    company_lng: 121.651409,
    paid_at: "2020-10-13T15:35:38.209+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "博克科技股份有限公司",
    company_tax_id: 12675508,
    company_address: "臺北市內湖區新湖二路162號4樓之1",
    company_lat: 25.06311,
    company_lng: 121.5796955,
    paid_at: "2021-05-25T05:24:48.639+08:00",
    member_name: "李崇偉",
    member_address: "台北市萬華區榮德里14鄰萬大路486巷48號四樓之六",
    member_lat: 25.0207186,
    member_lng: 121.495583,
  },
  {
    company_name: "博克科技股份有限公司",
    company_tax_id: 12675508,
    company_address: "臺北市內湖區新湖二路162號4樓之1",
    company_lat: 25.06311,
    company_lng: 121.5796955,
    paid_at: "2021-05-25T05:24:48.639+08:00",
    member_name: "李崇偉",
    member_address: "台北市萬華區榮德里14鄰萬大路486巷48號四樓之六",
    member_lat: 25.0207186,
    member_lng: 121.495583,
  },
  {
    company_name: "群眾自造股份有限公司",
    company_tax_id: 52354974,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2021-05-01T00:42:16.938+08:00",
    member_name: "謝明佑",
    member_address: "台北市信義區廣居里15鄰忠孝東路五段372巷27弄13號5樓",
    member_lat: 25.0397775,
    member_lng: 121.5744715,
  },
  {
    company_name: "若晨室內裝修有限公司",
    company_tax_id: 83075603,
    company_address: "新北市新莊區新崑路179號3樓",
    company_lat: 25.0001902,
    company_lng: 121.4331912,
    paid_at: "2021-04-23T10:00:46.108+08:00",
    member_name: "鄭婷君",
    member_address: "新竹縣竹北市東興路二段1020巷73號",
    member_lat: 24.7849112,
    member_lng: 121.0651768,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "台灣舞蹈治療研究協會",
    company_tax_id: 14694896,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-28T19:26:49.309+08:00",
    member_name: "黃于珊",
    member_address: "台中市北區賴厝里15鄰山西路一段53號14樓",
    member_lat: 24.1656855,
    member_lng: 120.6751205,
  },
  {
    company_name: "若晨室內裝修有限公司",
    company_tax_id: 83075603,
    company_address: "新北市新莊區新崑路179號3樓",
    company_lat: 25.0001902,
    company_lng: 121.4331912,
    paid_at: "2021-04-23T10:00:46.108+08:00",
    member_name: "鄭婷君",
    member_address: "新竹縣竹北市東興路二段1020巷73號",
    member_lat: 24.7849112,
    member_lng: 121.0651768,
  },
  {
    company_name: "嘜記餐飲食品有限公司",
    company_tax_id: 28480590,
    company_address: "臺北市中山區南京東路3段218號",
    company_lat: 25.0516453,
    company_lng: 121.5434858,
    paid_at: "2021-05-31T12:25:02.971+08:00",
    member_name: "覃基豪",
    member_address: "台北市大同區重慶北路三段236巷44弄20號4樓",
    member_lat: 25.0703556,
    member_lng: 121.5120986,
  },
  {
    company_name: "晰穀有限公司",
    company_tax_id: 50866181,
    company_address: "臺北市萬華區萬大路277巷8號1樓",
    company_lat: 25.0253215,
    company_lng: 121.5008819,
    paid_at: "2021-05-31T13:44:06.618+08:00",
    member_name: "譚竟汶",
    member_address: "臺北市文山區萬和里10鄰汀州路四段229號十樓",
    member_lat: 25.0026333,
    member_lng: 121.535933,
  },
  {
    company_name: "凱莉愛內衣股份有限公司",
    company_tax_id: 59261640,
    company_address: "高雄市苓雅區興中二路69號6樓之2",
    company_lat: 22.61437,
    company_lng: 120.300927,
    paid_at: "2021-05-31T19:23:18.32+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "亞圖斯科技工作室",
    company_tax_id: 72526840,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-31T23:19:51.757+08:00",
    member_name: "林冠霓",
    member_address: "台中市大里區日新路272巷3號",
    member_lat: 24.114391,
    member_lng: 120.6935115,
  },
  {
    company_name: "阮綜合社團法人阮綜合醫院",
    company_tax_id: 57983950,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-24T11:15:36.439+08:00",
    member_name: "何承謙",
    member_address: "高雄市鳳山區南正一路30號6樓",
    member_lat: 22.5948749,
    member_lng: 120.3369817,
  },
  {
    company_name: "凱莉愛內衣股份有限公司",
    company_tax_id: 59261640,
    company_address: "高雄市苓雅區興中二路69號6樓之2",
    company_lat: 22.61437,
    company_lng: 120.300927,
    paid_at: "2021-05-31T19:23:18.32+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "路遙力股份有限公司",
    company_tax_id: 83799706,
    company_address: "臺北市中山區南京東路2段69號8樓",
    company_lat: 25.0522712,
    company_lng: 121.5305436,
    paid_at: "2021-06-02T14:57:46.579+08:00",
    member_name: "林品賢",
    member_address: "臺北市松山區民有里1鄰民生東路三段105號六樓",
    member_lat: 25.0580125,
    member_lng: 121.5447268,
  },
  {
    company_name: "德立媒體行銷有限公司",
    company_tax_id: 53129146,
    company_address: "臺北市松山區光復北路112號10樓",
    company_lat: 25.0508529,
    company_lng: 121.557661,
    paid_at: "2021-02-05T12:24:07.829+08:00",
    member_name: "洪宛薐",
    member_address: "高雄市鼓山區華豐里30鄰華榮路440號8樓之2",
    member_lat: 22.6695612,
    member_lng: 120.294178,
  },
  {
    company_name: "野人創意設計有限公司",
    company_tax_id: 55722810,
    company_address: "臺北市士林區德行東路90巷33弄2號1樓",
    company_lat: 25.1059445,
    company_lng: 121.5281894,
    paid_at: "2021-06-04T15:15:21.381+08:00",
    member_name: "張雅晴",
    member_address: "新北市中和區員山路394巷6號5樓",
    member_lat: 25.0044986,
    member_lng: 121.4809993,
  },
  {
    company_name: "給樂工作室",
    company_tax_id: 72928470,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-07T19:04:45.551+08:00",
    member_name: "周奕安",
    member_address: "臺北市士林區後港里25鄰通河街東二段27號四樓",
    member_lat: 25.0801246,
    member_lng: 121.5231455,
  },
  {
    company_name: "財團法人台灣敦睦聯誼會高雄圓山大飯店",
    company_tax_id: 85235083,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-19T21:23:11.867+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "晨紘國際有限公司",
    company_tax_id: 82806834,
    company_address: "臺北市士林區中正路601號8樓之7",
    company_lat: 25.086467,
    company_lng: 121.510007,
    paid_at: "2021-06-07T09:04:20.389+08:00",
    member_name: "徐俊強",
    member_address: "新竹縣新埔鎮五埔里20鄰新關路五埔段909號",
    member_lat: 24.8240136,
    member_lng: 121.0893386,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    company_tax_id: 50798939,
    company_address: "臺北市中山區建國北路2段129號2樓",
    company_lat: 25.059998,
    company_lng: 121.5373357,
    paid_at: "2020-10-06T10:53:36.538+08:00",
    member_name: "高永祺",
    member_address: "台北市大安區復興南路一段66號5樓",
    member_lat: 23.7529831,
    member_lng: 120.689902,
  },
  {
    company_name: "群眾自造股份有限公司",
    company_tax_id: 52354974,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2021-05-01T00:42:16.938+08:00",
    member_name: "謝明佑",
    member_address: "台北市信義區廣居里15鄰忠孝東路五段372巷27弄13號5樓",
    member_lat: 25.0397775,
    member_lng: 121.5744715,
  },
  {
    company_name: "萱普艾森國際有限公司",
    company_tax_id: 90693968,
    company_address: "高雄市苓雅區中華四路2號13樓",
    company_lat: 22.6119251,
    company_lng: 120.3029069,
    paid_at: "2021-06-08T00:10:53.479+08:00",
    member_name: "梁金後",
    member_address: "高雄縣崗山鎮程香里18鄰華園路95巷6號6樓",
    member_lat: 22.7888562,
    member_lng: 120.3057492,
  },
  {
    company_name: "中國青年救國團桃園市中壢國民運動中心",
    company_tax_id: 82195670,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-02-05T13:57:47.982+08:00",
    member_name: "張辰宇",
    member_address: "桃園市桃園區青溪里3鄰鎮二街41號",
    member_lat: 24.9990709,
    member_lng: 121.315304,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "臺北市政府警察局文山第二分局",
    company_tax_id: "03807731",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-06-11T17:41:34.894+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "奔跑山豬運動事業有限公司",
    company_tax_id: 85088168,
    company_address: "花蓮縣花蓮市主睦里成功街145號一、二樓",
    company_lat: 23.9728987,
    company_lng: 121.6051326,
    paid_at: "2021-06-13T20:11:08.936+08:00",
    member_name: "吳飛旭",
    member_address: "臺中市南屯區大業里9鄰東興路三段239號13樓之1",
    member_lat: 24.1529344,
    member_lng: 120.6528566,
  },
  {
    company_name: "萱普艾森國際有限公司",
    company_tax_id: 90693968,
    company_address: "高雄市苓雅區中華四路2號13樓",
    company_lat: 22.6119251,
    company_lng: 120.3029069,
    paid_at: "2021-06-08T00:10:53.479+08:00",
    member_name: "梁金後",
    member_address: "高雄縣崗山鎮程香里18鄰華園路95巷6號6樓",
    member_lat: 22.7888562,
    member_lng: 120.3057492,
  },
  {
    company_name: "皇錩實業有限公司",
    company_tax_id: 22559839,
    company_address: "新北市三重區國道路1段85巷3號(1樓)",
    company_lat: 25.0726758,
    company_lng: 121.4799074,
    paid_at: "2021-05-20T12:22:10.165+08:00",
    member_name: "劉育名",
    member_address: "新北市三重區永興里15鄰福德南路24巷2之4號3樓",
    member_lat: 25.0632754,
    member_lng: 121.5029973,
  },
  {
    company_name: "迪利米食品有限公司",
    company_tax_id: 90768192,
    company_address: "新北市永和區保福路2段144號(1樓)",
    company_lat: 25.014553,
    company_lng: 121.5096404,
    paid_at: "2021-06-20T16:40:48.183+08:00",
    member_name: "林陳揚",
    member_address: "台北市萬華區雙園里3鄰興寧街71號三樓",
    member_lat: 25.0323203,
    member_lng: 121.4998264,
  },
  {
    company_name: "雄材大智材料科技股份有限公司",
    company_tax_id: 82910479,
    company_address: "臺北市內湖區洲子街12號3樓",
    company_lat: 25.0781698,
    company_lng: 121.5748975,
    paid_at: "2021-06-24T14:57:32.144+08:00",
    member_name: "殷聖哲",
    member_address: "新北市汐止區北山里19鄰明峰街298巷1號5樓",
    member_lat: 25.0685395,
    member_lng: 121.6249906,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "維斯塔行銷顧問有限公司",
    company_tax_id: 83434777,
    company_address: "新北市中和區建八路2號9樓之2",
    company_lat: 24.9982308,
    company_lng: 121.4862998,
    paid_at: "2021-01-22T10:12:22.625+08:00",
    member_name: "黃祺芸",
    member_address: "台北市文山區樟文里11鄰木新路三段310巷14弄18號三樓",
    member_lat: 24.9825017,
    member_lng: 121.5574692,
  },
  {
    company_name: "高雄室內合唱團",
    company_tax_id: 57960247,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-06-29T09:20:14.463+08:00",
    member_name: "何威廷",
    member_address: "臺南市安平區建平七街370巷131弄43號",
    member_lat: 22.9895606,
    member_lng: 120.1768171,
  },
  {
    company_name: "財團法人台灣敦睦聯誼會高雄圓山大飯店",
    company_tax_id: 85235083,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-19T21:23:11.867+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "京兆儀藝術創作有限公司",
    company_tax_id: 82853499,
    company_address: "新北市淡水區濱海路3段148巷50弄56號13樓",
    company_lat: 25.1918325,
    company_lng: 121.4293649,
    paid_at: "2021-06-30T15:52:42.328+08:00",
    member_name: "朱淇宏",
    member_address: "新北市淡水區竿蓁里3鄰坪頂路56之9五樓",
    member_lat: 25.1625342,
    member_lng: 121.458763,
  },
  {
    company_name: "白鹿工作室",
    company_tax_id: 87487417,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-06-30T16:02:56.603+08:00",
    member_name: "朱淇宏",
    member_address: "新北市淡水區竿蓁里3鄰坪頂路56之9五樓",
    member_lat: 25.1625342,
    member_lng: 121.458763,
  },
  {
    company_name: "全心互娛有限公司",
    company_tax_id: 84006767,
    company_address: "臺北市大安區忠孝東路4段162號9樓之1",
    company_lat: 25.0413335,
    company_lng: 121.5502744,
    paid_at: "2021-07-01T11:26:36.885+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "萱普艾森國際有限公司",
    company_tax_id: 90693968,
    company_address: "高雄市苓雅區中華四路2號13樓",
    company_lat: 22.6119251,
    company_lng: 120.3029069,
    paid_at: "2021-06-08T00:10:53.479+08:00",
    member_name: "梁金後",
    member_address: "高雄縣崗山鎮程香里18鄰華園路95巷6號6樓",
    member_lat: 22.7888562,
    member_lng: 120.3057492,
  },
  {
    company_name: "楓林書館",
    company_tax_id: 40951912,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-07-02T23:22:58.239+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "享萊股份有限公司",
    company_tax_id: 24569221,
    company_address: "臺北市大同區承德路3段287之2號",
    company_lat: 25.0747421,
    company_lng: 121.520783,
    paid_at: "2021-07-04T14:39:58.944+08:00",
    member_name: "郭子德",
    member_address: "臺中市太平區中平九街141巷1號",
    member_lat: 24.1233142,
    member_lng: 120.7203394,
  },
  {
    company_name: "給樂工作室",
    company_tax_id: 72928470,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-07T19:04:45.551+08:00",
    member_name: "周奕安",
    member_address: "臺北市士林區後港里25鄰通河街東二段27號四樓",
    member_lat: 25.0801246,
    member_lng: 121.5231455,
  },
  {
    company_name: "楓林書館",
    company_tax_id: 40951912,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-07-02T23:22:58.239+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "德立媒體行銷有限公司",
    company_tax_id: 53129146,
    company_address: "臺北市松山區光復北路112號10樓",
    company_lat: 25.0508529,
    company_lng: 121.557661,
    paid_at: "2021-02-05T12:24:07.829+08:00",
    member_name: "洪宛薐",
    member_address: "高雄市鼓山區華豐里30鄰華榮路440號8樓之2",
    member_lat: 22.6695612,
    member_lng: 120.294178,
  },
  {
    company_name: "共專有限公司",
    company_tax_id: 90776398,
    company_address: "新北市新莊區中正路829巷19號7樓",
    company_lat: 25.0277311,
    company_lng: 121.4211747,
    paid_at: "2021-07-05T14:51:33.064+08:00",
    member_name: "張維元",
    member_address: "高雄市三民區同德里2鄰九如二路24號",
    member_lat: 22.6406675,
    member_lng: 120.3131084,
  },
  {
    company_name: "共專有限公司",
    company_tax_id: 90776398,
    company_address: "新北市新莊區中正路829巷19號7樓",
    company_lat: 25.0277311,
    company_lng: 121.4211747,
    paid_at: "2021-07-05T14:51:33.064+08:00",
    member_name: "張維元",
    member_address: "高雄市三民區同德里2鄰九如二路24號",
    member_lat: 25.0251539,
    member_lng: 121.426164,
  },
  {
    company_name: "凱莉愛內衣股份有限公司",
    company_tax_id: 59261640,
    company_address: "高雄市苓雅區興中二路69號6樓之2",
    company_lat: 22.61437,
    company_lng: 120.300927,
    paid_at: "2021-05-31T19:23:18.32+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "晨紘國際有限公司",
    company_tax_id: 82806834,
    company_address: "臺北市士林區中正路601號8樓之7",
    company_lat: 25.086467,
    company_lng: 121.510007,
    paid_at: "2021-06-07T09:04:20.389+08:00",
    member_name: "徐俊強",
    member_address: "新竹縣新埔鎮五埔里20鄰新關路五埔段909號",
    member_lat: 24.997401,
    member_lng: 121.058871,
  },
  {
    company_name: "四零四科技股份有限公司聯合職工福利委員會",
    company_tax_id: 19999192,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-07-07T10:44:59.547+08:00",
    member_name: "林家輝",
    member_address: "彰化縣芳苑鄉王功村4鄰功湖路王功段455號",
    member_lat: 23.9652406,
    member_lng: 120.3420015,
  },
  {
    company_name: "奔跑山豬運動事業有限公司",
    company_tax_id: 85088168,
    company_address: "花蓮縣花蓮市主睦里成功街145號一、二樓",
    company_lat: 23.9728987,
    company_lng: 121.6051326,
    paid_at: "2021-06-13T20:11:08.936+08:00",
    member_name: "吳飛旭",
    member_address: "臺中市南屯區大業里9鄰東興路三段239號13樓之1",
    member_lat: 24.1529344,
    member_lng: 120.6528566,
  },
  {
    company_name: "資創國際保險經紀人股份有限公司",
    company_tax_id: 50896158,
    company_address: "臺北市松山區復興北路167號8樓之2",
    company_lat: 25.053975,
    company_lng: 121.5443208,
    paid_at: "2021-07-08T00:43:11.51+08:00",
    member_name: "陳薪智",
    member_address: "新北市土城區仁愛路4號4樓",
    member_lat: 24.9810958,
    member_lng: 121.4597896,
  },
  {
    company_name: "臺北市政府警察局文山第二分局",
    company_tax_id: "03807731",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-06-11T17:41:34.894+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "楓林書館",
    company_tax_id: 40951912,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-07-02T23:22:58.239+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "夢想家房屋經紀有限公司",
    company_tax_id: 83490812,
    company_address: "桃園市龜山區樂善二路193號",
    company_lat: 25.0465133,
    company_lng: 121.3844032,
    paid_at: "2021-07-16T10:52:21.735+08:00",
    member_name: "李宛蓉",
    member_address: "新北市淡水區民權路23號3樓之3",
    member_lat: 25.1419287,
    member_lng: 121.4603807,
  },
  {
    company_name: "曉數碼股份有限公司",
    company_tax_id: 24563367,
    company_address: "臺北市中正區羅斯福路2段100號20樓",
    company_lat: 25.0267241,
    company_lng: 121.5222149,
    paid_at: "2021-07-16T16:05:00.168+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "享萊股份有限公司",
    company_tax_id: 24569221,
    company_address: "臺北市大同區承德路3段287之2號",
    company_lat: 25.0747421,
    company_lng: 121.520783,
    paid_at: "2021-07-04T14:39:58.944+08:00",
    member_name: "郭子德",
    member_address: "臺中市太平區中平九街141巷1號",
    member_lat: 24.9722347,
    member_lng: 121.5450225,
  },
  {
    company_name: "超級傳家泰潤股份有限公司",
    company_tax_id: 90889029,
    company_address: "臺北市內湖區行愛路57號1樓",
    company_lat: 25.0624048,
    company_lng: 121.581503,
    paid_at: "2021-07-19T14:58:35.682+08:00",
    member_name: "覃基豪",
    member_address: "台北市大同區重慶北路三段236巷44弄20號4樓",
    member_lat: 25.0703556,
    member_lng: 121.5120986,
  },
  {
    company_name: "春富光電科技有限公司",
    company_tax_id: 54040373,
    company_address: "新北市樹林區太元街110巷14號",
    company_lat: 24.9776491,
    company_lng: 121.4193102,
    paid_at: "2020-11-17T15:02:16.614+08:00",
    member_name: "葉百豐",
    member_address: "台北市文山區萬祥里15鄰羅斯福路五段269巷17弄3號2樓",
    member_lat: 25.0014552,
    member_lng: 121.5401236,
  },
  {
    company_name: "阮綜合社團法人阮綜合醫院",
    company_tax_id: 57983950,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-24T11:15:36.439+08:00",
    member_name: "何承謙",
    member_address: "高雄市鳳山區南正一路30號6樓",
    member_lat: 22.5948749,
    member_lng: 120.3369817,
  },
  {
    company_name: "阮綜合社團法人阮綜合醫院",
    company_tax_id: 57983950,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-24T11:15:36.439+08:00",
    member_name: "何承謙",
    member_address: "高雄市鳳山區南正一路30號6樓",
    member_lat: 22.5948749,
    member_lng: 120.3369817,
  },
  {
    company_name: "閱野文創股份有限公司",
    company_tax_id: 54289140,
    company_address: "新北市三峽區大學路103號",
    company_lat: 24.9419808,
    company_lng: 121.3756643,
    paid_at: "2021-07-23T14:27:20.783+08:00",
    member_name: "陳瑞軒",
    member_address: "桃園市中壢區大仁二街15號4樓之11",
    member_lat: 24.9548991,
    member_lng: 121.2422706,
  },
  {
    company_name: "路遙力股份有限公司",
    company_tax_id: 83799706,
    company_address: "臺北市中山區南京東路2段69號8樓",
    company_lat: 25.0522712,
    company_lng: 121.5305436,
    paid_at: "2021-06-02T14:57:46.579+08:00",
    member_name: "林品賢",
    member_address: "臺北市松山區民有里1鄰民生東路三段105號六樓",
    member_lat: 25.0580125,
    member_lng: 121.5447268,
  },
  {
    company_name: "金寶山事業股份有限公司",
    company_tax_id: 86510452,
    company_address: "臺北市中山區民權東路2段158號1樓",
    company_lat: 25.0621598,
    company_lng: 121.5350214,
    paid_at: "2021-07-26T09:18:17.485+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 22.3447018,
    member_lng: 120.3751828,
  },
  {
    company_name: "無穹藝術有限公司",
    company_tax_id: 29035252,
    company_address: "高雄市鼓山區中華一路860號1樓",
    company_lat: 22.6590136,
    company_lng: 120.2919969,
    paid_at: "2021-07-26T13:06:29.877+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "聚梧桐餐廳有限公司",
    company_tax_id: 24659588,
    company_address: "高雄市新興區中山二路472號1樓之1",
    company_lat: 22.620447,
    company_lng: 120.30229,
    paid_at: "2021-07-30T15:16:08.5+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "萱普艾森國際有限公司",
    company_tax_id: 90693968,
    company_address: "高雄市苓雅區中華四路2號13樓",
    company_lat: 22.6119251,
    company_lng: 120.3029069,
    paid_at: "2021-06-08T00:10:53.479+08:00",
    member_name: "梁金後",
    member_address: "高雄縣崗山鎮程香里18鄰華園路95巷6號6樓",
    member_lat: 22.7888562,
    member_lng: 120.3057492,
  },
  {
    company_name: "英格斯能源股份有限公司",
    company_tax_id: 91086370,
    company_address: "臺中市北區中清路一段79號5樓",
    company_lat: 24.1546207,
    company_lng: 120.6771246,
    paid_at: "2021-08-02T12:29:40.283+08:00",
    member_name: "蔣雅文",
    member_address: "高雄市岡山區柳橋西路110巷2弄4號",
    member_lat: 22.7906757,
    member_lng: 120.2854939,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 25.0659933,
    member_lng: 121.6549092,
  },
  {
    company_name: "和光里股份有限公司",
    company_tax_id: 83554879,
    company_address: "臺北市大安區信義路4段296號15樓",
    company_lat: 25.0329977,
    company_lng: 121.5544765,
    paid_at: "2021-08-05T10:53:00.384+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "德立媒體行銷有限公司",
    company_tax_id: 53129146,
    company_address: "臺北市松山區光復北路112號10樓",
    company_lat: 25.0508529,
    company_lng: 121.557661,
    paid_at: "2021-02-05T12:24:07.829+08:00",
    member_name: "洪宛薐",
    member_address: "高雄市鼓山區華豐里30鄰華榮路440號8樓之2",
    member_lat: 22.6695612,
    member_lng: 120.294178,
  },
  {
    company_name: "給樂工作室",
    company_tax_id: 72928470,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-07T19:04:45.551+08:00",
    member_name: "周奕安",
    member_address: "臺北市士林區後港里25鄰通河街東二段27號四樓",
    member_lat: 25.0801246,
    member_lng: 121.5231455,
  },
  {
    company_name: "路遙力股份有限公司",
    company_tax_id: 83799706,
    company_address: "臺北市中山區南京東路2段69號8樓",
    company_lat: 25.0522712,
    company_lng: 121.5305436,
    paid_at: "2021-06-02T14:57:46.579+08:00",
    member_name: "林品賢",
    member_address: "臺北市松山區民有里1鄰民生東路三段105號六樓",
    member_lat: 25.0580125,
    member_lng: 121.5447268,
  },
  {
    company_name: "閱野文創股份有限公司",
    company_tax_id: 54289140,
    company_address: "新北市三峽區大學路103號",
    company_lat: 24.9419808,
    company_lng: 121.3756643,
    paid_at: "2021-07-23T14:27:20.783+08:00",
    member_name: "陳瑞軒",
    member_address: "桃園市中壢區大仁二街15號4樓之11",
    member_lat: 24.9548991,
    member_lng: 121.2422706,
  },
  {
    company_name: "山品壹合有限公司",
    company_tax_id: 50888137,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2020-12-09T21:52:26.898+08:00",
    member_name: "温宗翰",
    member_address: "桃園市桃園區中平里6鄰宏昌六街102號",
    member_lat: 24.9882882,
    member_lng: 121.2964197,
  },
  {
    company_name: "秀泰國際娛樂有限公司",
    company_tax_id: 28698133,
    company_address: "臺北市萬華區武昌街2段91巷8號",
    company_lat: 25.0458582,
    company_lng: 121.5036089,
    paid_at: "2021-08-11T14:58:42.391+08:00",
    member_name: "覃基豪",
    member_address: "台北市大同區重慶北路三段236巷44弄20號4樓",
    member_lat: 25.0703556,
    member_lng: 121.5120986,
  },
  {
    company_name: "明基電通股份有限公司",
    company_tax_id: 70721522,
    company_address: "臺北市內湖區基湖路16號",
    company_lat: 25.0812608,
    company_lng: 121.5645846,
    paid_at: "2021-08-13T13:30:25.081+08:00",
    member_name: "張杰",
    member_address: "台北市中山區明水路397巷2弄10號8樓",
    member_lat: 25.077976,
    member_lng: 121.5472669,
  },
  {
    company_name: "明基電通股份有限公司",
    company_tax_id: 70721522,
    company_address: "臺北市內湖區基湖路16號",
    company_lat: 25.0812608,
    company_lng: 121.5645846,
    paid_at: "2021-08-13T13:30:25.081+08:00",
    member_name: "張杰",
    member_address: "台北市中山區明水路397巷2弄10號8樓",
    member_lat: 25.077976,
    member_lng: 121.5472669,
  },
  {
    company_name: "明基電通股份有限公司",
    company_tax_id: 70721522,
    company_address: "臺北市內湖區基湖路16號",
    company_lat: 25.0812608,
    company_lng: 121.5645846,
    paid_at: "2021-08-13T13:30:25.081+08:00",
    member_name: "張杰",
    member_address: "台北市中山區明水路397巷2弄10號8樓",
    member_lat: 25.077976,
    member_lng: 121.5472669,
  },
  {
    company_name: "明基電通股份有限公司",
    company_tax_id: 70721522,
    company_address: "臺北市內湖區基湖路16號",
    company_lat: 25.0812608,
    company_lng: 121.5645846,
    paid_at: "2021-08-13T13:30:25.081+08:00",
    member_name: "張杰",
    member_address: "台北市中山區明水路397巷2弄10號8樓",
    member_lat: 25.077976,
    member_lng: 121.5472669,
  },
  {
    company_name: "爆器顧問股份有限公司",
    company_tax_id: 85077107,
    company_address: "新北市土城區裕生路86巷12號3樓",
    company_lat: 24.9854013,
    company_lng: 121.4466746,
    paid_at: "2021-08-18T13:45:48.476+08:00",
    member_name: "范綱燊",
    member_address: "桃園市八德區大安里9鄰和平路692巷22號",
    member_lat: 24.9543018,
    member_lng: 121.3184499,
  },
  {
    company_name: "晨紘國際有限公司",
    company_tax_id: 82806834,
    company_address: "臺北市士林區中正路601號8樓之7",
    company_lat: 25.086467,
    company_lng: 121.510007,
    paid_at: "2021-06-07T09:04:20.389+08:00",
    member_name: "徐俊強",
    member_address: "新竹縣新埔鎮五埔里20鄰新關路五埔段909號",
    member_lat: 24.90736,
    member_lng: 121.2400568,
  },
  {
    company_name: "沃爾斯丹國際有限公司",
    company_tax_id: 91069127,
    company_address: "新北市樹林區三龍街45巷2號",
    company_lat: 25.0186579,
    company_lng: 121.4145123,
    paid_at: "2021-08-19T12:37:26.437+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "楓林書館",
    company_tax_id: 40951912,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-07-02T23:22:58.239+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "珍納達國際有限公司",
    company_tax_id: 28822668,
    company_address: "臺北市信義區基隆路2段85號5樓",
    company_lat: 25.030021,
    company_lng: 121.55798,
    paid_at: "2021-08-20T16:07:38.223+08:00",
    member_name: "覃基豪",
    member_address: "台北市大同區重慶北路三段236巷44弄20號4樓",
    member_lat: 25.0703556,
    member_lng: 121.5120986,
  },
  {
    company_name: "超級傳家泰潤股份有限公司",
    company_tax_id: 90889029,
    company_address: "臺北市內湖區行愛路57號1樓",
    company_lat: 25.0624048,
    company_lng: 121.581503,
    paid_at: "2021-07-19T14:58:35.682+08:00",
    member_name: "覃基豪",
    member_address: "台北市大同區重慶北路三段236巷44弄20號4樓",
    member_lat: 25.0703556,
    member_lng: 121.5120986,
  },
  {
    company_name: "台南企業股份有限公司職工福利委員會",
    company_tax_id: 87879130,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-23T15:18:24.751+08:00",
    member_name: "戚正寰",
    member_address: "臺北市內湖區南湖里7鄰康寧路三段190巷23號4樓",
    member_lat: 25.0683946,
    member_lng: 121.611042,
  },
  {
    company_name: "紅動亞洲傳媒有限公司",
    company_tax_id: 42618462,
    company_address: "新北市永和區永和路2段57號7樓",
    company_lat: 25.0110334,
    company_lng: 121.5145841,
    paid_at: "2021-08-24T20:12:50.406+08:00",
    member_name: "李宛蓉",
    member_address: "新北市淡水區民權路23號3樓之3",
    member_lat: 25.1419287,
    member_lng: 121.4603807,
  },
  {
    company_name: "財團法人中山管理教育基金會",
    company_tax_id: 92081768,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-25T10:02:06.325+08:00",
    member_name: "徐梅軒",
    member_address: "325 桃園市龍潭區三和里22鄰渴望一路432號",
    member_lat: 24.8400562,
    member_lng: 121.1802059,
  },
  {
    company_name: "勝騰緩衝研發股份有限公司",
    company_tax_id: 80039415,
    company_address: "新北市新莊區中和街118號8樓",
    company_lat: 25.0476435,
    company_lng: 121.4476099,
    paid_at: "2021-08-25T21:12:12.007+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "勝騰緩衝研發股份有限公司",
    company_tax_id: 80039415,
    company_address: "新北市新莊區中和街118號8樓",
    company_lat: 25.0476435,
    company_lng: 121.4476099,
    paid_at: "2021-08-25T21:12:12.007+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "享萊股份有限公司",
    company_tax_id: 24569221,
    company_address: "臺北市大同區承德路3段287之2號",
    company_lat: 25.0747421,
    company_lng: 121.520783,
    paid_at: "2021-07-04T14:39:58.944+08:00",
    member_name: "郭子德",
    member_address: "臺中市太平區中平九街141巷1號",
    member_lat: 24.9722347,
    member_lng: 121.5450225,
  },
  {
    company_name: "嚎哮排演",
    company_tax_id: 38906716,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-29T15:32:04.425+08:00",
    member_name: "王瑞璞",
    member_address: "臺北市中正區頂東里4鄰羅斯福路3段84巷13號5樓",
    member_lat: 25.0213529,
    member_lng: 121.5260622,
  },
  {
    company_name: "長鴻出版社股份有限公司",
    company_tax_id: 86392027,
    company_address: "臺南市東區東門里北門路一段76號3樓",
    company_lat: 22.9912791,
    company_lng: 120.2117274,
    paid_at: "2021-08-31T12:10:08.925+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "目目和設計有限公司",
    company_tax_id: 54874509,
    company_address: "新北市永和區國中路14號4樓之1",
    company_lat: 25.0049271,
    company_lng: 121.5244982,
    paid_at: "2021-08-31T12:37:22.653+08:00",
    member_name: "陳瑞軒",
    member_address: "桃園市中壢區大仁二街15號4樓之11",
    member_lat: 24.9548991,
    member_lng: 121.2422706,
  },
  {
    company_name: "晨富建設有限公司",
    company_tax_id: 28088049,
    company_address: "新竹市公園里東大路一段39巷39號",
    company_lat: 24.8026023,
    company_lng: 120.9745547,
    paid_at: "2021-08-31T12:48:37.262+08:00",
    member_name: "黃一玲",
    member_address: "新北市汐止區樟樹一路99巷2弄3號2樓",
    member_lat: 25.063907,
    member_lng: 121.641573,
  },
  {
    company_name: "大豐建築企業股份有限公司",
    company_tax_id: 18556000,
    company_address: "臺北市大同區迪化街1段289號",
    company_lat: 25.0616146,
    company_lng: 121.5093239,
    paid_at: "2021-08-31T14:51:19.998+08:00",
    member_name: "羅聖婷",
    member_address: "台北市信義區惠安里10鄰吳興街368巷18號4樓",
    member_lat: 25.0248618,
    member_lng: 121.5662638,
  },
  {
    company_name: "萱普艾森國際有限公司",
    company_tax_id: 90693968,
    company_address: "高雄市苓雅區中華四路2號13樓",
    company_lat: 22.6119251,
    company_lng: 120.3029069,
    paid_at: "2021-06-08T00:10:53.479+08:00",
    member_name: "梁金後",
    member_address: "高雄縣崗山鎮程香里18鄰華園路95巷6號6樓",
    member_lat: 22.7888562,
    member_lng: 120.3057492,
  },
  {
    company_name: "千慶興業有限公司",
    company_tax_id: 80288878,
    company_address: "臺北市北投區知行路317號2樓",
    company_lat: 25.1224243,
    company_lng: 121.4670408,
    paid_at: "2021-09-03T21:55:17.943+08:00",
    member_name: "許哲耀",
    member_address: "台北市松山區南京東路五段291巷5弄2號2樓",
    member_lat: 25.0518683,
    member_lng: 121.5664522,
  },
  {
    company_name: "石皮有限公司",
    company_tax_id: 52328287,
    company_address: "臺北市大安區泰順街60巷22號",
    company_lat: 25.0223238,
    company_lng: 121.5298233,
    paid_at: "2021-09-06T06:35:32.158+08:00",
    member_name: "葉仁智",
    member_address: "彰化縣彰化市興北里15鄰三民路237巷65號",
    member_lat: 24.0870104,
    member_lng: 120.5439721,
  },
  {
    company_name: "國立科學工藝博物館",
    company_tax_id: "08203324",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-09-07T18:00:28.563+08:00",
    member_name: "余孟軒",
    member_address: "高雄市左營區尾南里8鄰孔營路79巷2號",
    member_lat: 22.6891111,
    member_lng: 120.2958912,
  },
  {
    company_name: "社團法人全球小紅帽協會",
    company_tax_id: 87527396,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-09-09T23:27:31.226+08:00",
    member_name: "江佩容",
    member_address: "新北市三重區長壽街42號4樓",
    member_lat: 25.0684103,
    member_lng: 121.5002992,
  },
  {
    company_name: "白鹿工作室",
    company_tax_id: 87487417,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-06-30T16:02:56.603+08:00",
    member_name: "朱淇宏",
    member_address: "新北市淡水區竿蓁里3鄰坪頂路56之9五樓",
    member_lat: 24.9917678,
    member_lng: 121.5344732,
  },
  {
    company_name: "國立中正大學",
    company_tax_id: "06313774",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2020-12-09T11:37:13.169+08:00",
    member_name: "何威廷",
    member_address: "臺南市安平區建平七街370巷131弄43號",
    member_lat: 25.0213987,
    member_lng: 121.5181751,
  },
  {
    company_name: "維斯塔行銷顧問有限公司",
    company_tax_id: 83434777,
    company_address: "新北市中和區建八路2號9樓之2",
    company_lat: 24.9982308,
    company_lng: 121.4862998,
    paid_at: "2021-01-22T10:12:22.625+08:00",
    member_name: "黃祺芸",
    member_address: "台北市文山區樟文里11鄰木新路三段310巷14弄18號三樓",
    member_lat: 24.9825017,
    member_lng: 121.5574692,
  },
  {
    company_name: "蝶映藝術文化有限公司",
    company_tax_id: 90760662,
    company_address: "臺北市中山區長春路40號4樓之3",
    company_lat: 25.0548502,
    company_lng: 121.5250555,
    paid_at: "2021-09-17T16:29:29.271+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "欣宸國際股份有限公司",
    company_tax_id: 24932741,
    company_address: "臺北市大安區忠孝東路4段148號4樓之3",
    company_lat: 25.0413537,
    company_lng: 121.549734,
    paid_at: "2021-09-21T21:30:14.021+08:00",
    member_name: "許哲耀",
    member_address: "台北市松山區南京東路五段291巷5弄2號2樓",
    member_lat: 25.0518683,
    member_lng: 121.5664522,
  },
  {
    company_name: "國立科學工藝博物館",
    company_tax_id: "08203324",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-09-07T18:00:28.563+08:00",
    member_name: "余孟軒",
    member_address: "高雄市左營區尾南里8鄰孔營路79巷2號",
    member_lat: 22.6891111,
    member_lng: 120.2958912,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 24.489881,
    member_lng: 120.782511,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "活意行銷企管顧問有限公司",
    company_tax_id: 80644139,
    company_address: "臺中市南屯區大誠里東興路二段69巷11號",
    company_lat: 24.138147,
    company_lng: 120.652483,
    paid_at: "2021-09-24T15:22:28.265+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "赤粒藝術經紀策展有限公司",
    company_tax_id: 27733999,
    company_address: "臺北市大安區大安路1段116巷15號(1樓)",
    company_lat: 25.0399124,
    company_lng: 121.5449241,
    paid_at: "2021-09-24T17:07:53.505+08:00",
    member_name: "汪子涵",
    member_address: "新北市新店區大鵬里7鄰中正路700巷55號",
    member_lat: 24.9917678,
    member_lng: 121.5344732,
  },
  {
    company_name: "紹宏國際股份有限公司",
    company_tax_id: 69635041,
    company_address: "桃園市龜山區大坑里南上路435號2樓",
    company_lat: 25.045274,
    company_lng: 121.3051766,
    paid_at: "2021-09-25T22:42:13.402+08:00",
    member_name: "徐俊強",
    member_address: "新竹縣新埔鎮五埔里20鄰新關路五埔段909號",
    member_lat: 24.8240136,
    member_lng: 121.0893386,
  },
  {
    company_name: "虹彩服飾股份有限公司",
    company_tax_id: 82911239,
    company_address: "新北市土城區中華路2段212號2樓",
    company_lat: 24.9685667,
    company_lng: 121.4370729,
    paid_at: "2021-09-27T12:00:41.416+08:00",
    member_name: "張文彥",
    member_address: "新北市土城區青雲路483巷12弄24號4樓",
    member_lat: 24.9730201,
    member_lng: 121.4596711,
  },
  {
    company_name: "虹彩服飾股份有限公司",
    company_tax_id: 82911239,
    company_address: "新北市土城區中華路2段212號2樓",
    company_lat: 24.9685667,
    company_lng: 121.4370729,
    paid_at: "2021-09-27T12:00:41.416+08:00",
    member_name: "張文彥",
    member_address: "新北市土城區青雲路483巷12弄24號4樓",
    member_lat: 24.9730201,
    member_lng: 121.4596711,
  },
  {
    company_name: "少女凱倫有限公司",
    company_tax_id: 83799754,
    company_address: "臺北市中正區羅斯福路3段244巷9弄2之1號",
    company_lat: 25.0173089,
    company_lng: 121.530071,
    paid_at: "2021-09-29T18:14:12.539+08:00",
    member_name: "林賢榮",
    member_address: "新竹縣湖口鄉鳳山村榮光路108號",
    member_lat: 24.8574073,
    member_lng: 121.0146642,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "給樂工作室",
    company_tax_id: 72928470,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-07T19:04:45.551+08:00",
    member_name: "周奕安",
    member_address: "臺北市士林區後港里25鄰通河街東二段27號四樓",
    member_lat: 25.0801246,
    member_lng: 121.5231455,
  },
  {
    company_name: "給樂工作室",
    company_tax_id: 72928470,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-04-07T19:04:45.551+08:00",
    member_name: "周奕安",
    member_address: "臺北市士林區後港里25鄰通河街東二段27號四樓",
    member_lat: 23.4738334,
    member_lng: 120.4566976,
  },
  {
    company_name: "萱普艾森國際有限公司",
    company_tax_id: 90693968,
    company_address: "高雄市苓雅區中華四路2號13樓",
    company_lat: 22.6119251,
    company_lng: 120.3029069,
    paid_at: "2021-06-08T00:10:53.479+08:00",
    member_name: "梁金後",
    member_address: "高雄縣崗山鎮程香里18鄰華園路95巷6號6樓",
    member_lat: 22.7888562,
    member_lng: 120.3057492,
  },
  {
    company_name: "疍宅甜點店",
    company_tax_id: 72931051,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-10-01T12:26:05.743+08:00",
    member_name: "鄭宇",
    member_address: "新北市三峽區文化路120巷10號4樓",
    member_lat: 24.9366369,
    member_lng: 121.371176,
  },
  {
    company_name: "活力平方創意包裝設計有限公司",
    company_tax_id: 24389136,
    company_address: "臺中市北屯區景賢路310號1樓",
    company_lat: 24.1673613,
    company_lng: 120.716965,
    paid_at: "2021-10-01T13:05:12.378+08:00",
    member_name: "吳飛旭",
    member_address: "臺中市南屯區大業里9鄰東興路三段239號13樓之1",
    member_lat: 24.1529344,
    member_lng: 120.6528566,
  },
  {
    company_name: "國立中正大學",
    company_tax_id: "06313774",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2020-12-09T11:37:13.169+08:00",
    member_name: "何威廷",
    member_address: "臺南市安平區建平七街370巷131弄43號",
    member_lat: 25.0213987,
    member_lng: 121.5181751,
  },
  {
    company_name: "國立臺中教育大學",
    company_tax_id: 52009903,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-10-03T22:42:52.435+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "嬌生股份有限公司",
    company_tax_id: 30814854,
    company_address: "臺北市中山區民生東路3段2號10、11樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2021-10-04T22:37:13.668+08:00",
    member_name: "許勝凱",
    member_address: "台北市中山區金泰里9鄰敬業三路162巷92號9樓",
    member_lat: 25.0779989,
    member_lng: 121.5592508,
  },
  {
    company_name: "寬陽台空間創創股份有限公司",
    company_tax_id: 83382010,
    company_address: "臺北市大安區復興南路2段327號14樓之2",
    company_lat: 25.0238286,
    company_lng: 121.5437552,
    paid_at: "2021-10-05T10:04:33.769+08:00",
    member_name: "張宇良",
    member_address: "桃園市中壢區普義里5鄰四維路1巷6號",
    member_lat: 24.9598402,
    member_lng: 121.2316601,
  },
  {
    company_name: "夢想家房屋經紀有限公司",
    company_tax_id: 83490812,
    company_address: "桃園市龜山區樂善二路193號",
    company_lat: 25.0465133,
    company_lng: 121.3844032,
    paid_at: "2021-07-16T10:52:21.735+08:00",
    member_name: "李宛蓉",
    member_address: "新北市淡水區民權路23號3樓之3",
    member_lat: 25.1419287,
    member_lng: 121.4603807,
  },
  {
    company_name: "德立媒體行銷有限公司",
    company_tax_id: 53129146,
    company_address: "臺北市松山區光復北路112號10樓",
    company_lat: 25.0508529,
    company_lng: 121.557661,
    paid_at: "2021-02-05T12:24:07.829+08:00",
    member_name: "洪宛薐",
    member_address: "高雄市鼓山區華豐里30鄰華榮路440號8樓之2",
    member_lat: 22.6695612,
    member_lng: 120.294178,
  },
  {
    company_name: "建儒科技文教有限公司",
    company_tax_id: 24969916,
    company_address: "臺北市中正區衡陽路51號1樓、4樓之6、4樓之7",
    company_lat: 25.0425567,
    company_lng: 121.5121094,
    paid_at: "2021-10-05T15:23:01.195+08:00",
    member_name: "鄭宇",
    member_address: "新北市三峽區文化路120巷10號4樓",
    member_lat: 24.9366369,
    member_lng: 121.371176,
  },
  {
    company_name: "建儒科技文教有限公司",
    company_tax_id: 24969916,
    company_address: "臺北市中正區衡陽路51號1樓、4樓之6、4樓之7",
    company_lat: 25.0425567,
    company_lng: 121.5121094,
    paid_at: "2021-10-05T15:23:01.195+08:00",
    member_name: "鄭宇",
    member_address: "新北市三峽區文化路120巷10號4樓",
    member_lat: 24.9366369,
    member_lng: 121.371176,
  },
  {
    company_name: "建儒科技文教有限公司",
    company_tax_id: 24969916,
    company_address: "臺北市中正區衡陽路51號1樓、4樓之6、4樓之7",
    company_lat: 25.0425567,
    company_lng: 121.5121094,
    paid_at: "2021-10-05T15:23:01.195+08:00",
    member_name: "鄭宇",
    member_address: "新北市三峽區文化路120巷10號4樓",
    member_lat: 24.9366369,
    member_lng: 121.371176,
  },
  {
    company_name: "落居百物有限公司",
    company_tax_id: 90821669,
    company_address: "臺北市中山區南京東路2段150號4樓",
    company_lat: 25.0518186,
    company_lng: 121.5342281,
    paid_at: "2021-10-05T18:46:45.885+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "采傑整合行銷國際有限公司",
    company_tax_id: 83410986,
    company_address: "臺北市大安區忠孝東路4段107號12樓",
    company_lat: 25.0417523,
    company_lng: 121.5483089,
    paid_at: "2021-10-08T16:15:37.253+08:00",
    member_name: "林陳揚",
    member_address: "台北市萬華區雙園里3鄰興寧街71號三樓",
    member_lat: 25.0323203,
    member_lng: 121.4998264,
  },
  {
    company_name: "摩爾國際品牌顧問有限公司",
    company_tax_id: 85007335,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2021-10-12T16:17:24.632+08:00",
    member_name: "覃基豪",
    member_address: "台北市大同區重慶北路三段236巷44弄20號4樓",
    member_lat: 25.0703556,
    member_lng: 121.5120986,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "恩大娛樂有限公司",
    company_tax_id: 90538299,
    company_address: "臺北市松山區復興北路201號4樓之1",
    company_lat: 25.0557591,
    company_lng: 121.544358,
    paid_at: "2021-10-14T10:37:17.901+08:00",
    member_name: "劉晏辰",
    member_address: "桃園市中壢區明德里1鄰橫陽街85號4樓",
    member_lat: 24.9494177,
    member_lng: 121.229578,
  },
  {
    company_name: "財團法人台灣敦睦聯誼會高雄圓山大飯店",
    company_tax_id: 85235083,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-19T21:23:11.867+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "田喬營造股份有限公司",
    company_tax_id: 90522368,
    company_address: "高雄市苓雅區三多四路110號10樓之5",
    company_lat: 22.612304,
    company_lng: 120.298872,
    paid_at: "2021-10-15T07:48:04.842+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "開物整合設計股份有限公司",
    company_tax_id: 53742827,
    company_address: "臺北市松山區南京東路4段51號3樓",
    company_lat: 25.0519694,
    company_lng: 121.551057,
    paid_at: "2021-10-15T20:51:55.635+08:00",
    member_name: "范綱燊",
    member_address: "桃園市八德區大安里9鄰和平路692巷22號",
    member_lat: 24.9543018,
    member_lng: 121.3184499,
  },
  {
    company_name: "美味大師股份有限公司",
    company_tax_id: 23311250,
    company_address: "臺北市文山區一壽街20巷7號1樓",
    company_lat: 24.9796933,
    company_lng: 121.5558394,
    paid_at: "2021-10-18T14:51:58.017+08:00",
    member_name: "俞吟佳",
    member_address: "臺中市西屯區何仁里36鄰文心路三段129巷8號三樓之3",
    member_lat: 24.1696845,
    member_lng: 120.6551875,
  },
  {
    company_name: "國立屏東大學",
    company_tax_id: 91004005,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-10-20T14:07:38+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "維肯媒體股份有限公司",
    company_tax_id: 55734512,
    company_address: "臺北市信義區菸廠路88號5樓之5",
    company_lat: 25.0445608,
    company_lng: 121.5613507,
    paid_at: "2021-10-21T17:23:44.207+08:00",
    member_name: "吳翊嘉",
    member_address: "新北市土城區興城路8號11樓",
    member_lat: 24.9734145,
    member_lng: 121.442561,
  },
  {
    company_name: "衛生福利部中央健康保險署",
    company_tax_id: "08628407",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-10-22T16:29:12.22+08:00",
    member_name: "高立翰",
    member_address: "臺南市海東里14鄰安南區海環街6巷7號",
    member_lat: 23.0397709,
    member_lng: 120.1871072,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "顧廣毅創意工作室",
    company_tax_id: 72988722,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-10-26T14:12:41.541+08:00",
    member_name: "王偉泰",
    member_address: "台北市大同區星明里12鄰太原路155號15樓之3",
    member_lat: 25.0553197,
    member_lng: 121.5167724,
  },
  {
    company_name: "灼見文創有限公司",
    company_tax_id: 85076297,
    company_address: "新北市三峽區復興路162號6樓之1",
    company_lat: 24.9384648,
    company_lng: 121.3711666,
    paid_at: "2021-10-26T19:03:19.692+08:00",
    member_name: "郭于菁",
    member_address: "新北市新莊區民本街27巷8-4號五樓",
    member_lat: 25.0191481,
    member_lng: 121.4285687,
  },
  {
    company_name: "楓林書館",
    company_tax_id: 40951912,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-07-02T23:22:58.239+08:00",
    member_name: "王莉棋",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "中國青年救國團桃園市中壢國民運動中心",
    company_tax_id: 82195670,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-02-05T13:57:47.982+08:00",
    member_name: "張辰宇",
    member_address: "桃園市桃園區青溪里3鄰鎮二街41號",
    member_lat: 24.9990709,
    member_lng: 121.315304,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "高雄醫學大學附設中和紀念醫院",
    company_tax_id: 76215451,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-10-28T14:26:03.402+08:00",
    member_name: "張宇良",
    member_address: "桃園市中壢區普義里5鄰四維路1巷6號",
    member_lat: 24.9598402,
    member_lng: 121.2316601,
  },
  {
    company_name: "就是俊傑音樂股份有限公司",
    company_tax_id: 28675785,
    company_address: "臺北市內湖區洲子街151號1樓",
    company_lat: 25.0812646,
    company_lng: 121.568565,
    paid_at: "2021-10-28T15:59:48.961+08:00",
    member_name: "朱淇宏",
    member_address: "新北市淡水區竿蓁里3鄰坪頂路56之9五樓",
    member_lat: 25.1625342,
    member_lng: 121.458763,
  },
  {
    company_name: "藝起呵呵有限公司",
    company_tax_id: 83702891,
    company_address: "桃園市桃園區文康街11之2號",
    company_lat: 24.9936427,
    company_lng: 121.3039395,
    paid_at: "2021-10-29T17:42:07.098+08:00",
    member_name: "朱淇宏",
    member_address: "新北市淡水區竿蓁里3鄰坪頂路56之9五樓",
    member_lat: 25.1625342,
    member_lng: 121.458763,
  },
  {
    company_name: "寵物百分百股份有限公司",
    company_tax_id: 83201117,
    company_address: "高雄市仁武區鳳仁路231號1樓",
    company_lat: 22.6979627,
    company_lng: 120.3465459,
    paid_at: "2021-11-01T08:00:51.861+08:00",
    member_name: "鄭鈞元",
    member_address: "新北市永和區民治里20鄰民有街21巷1弄4號四樓",
    member_lat: 24.9969024,
    member_lng: 121.5168138,
  },
  {
    company_name: "虹彩服飾股份有限公司",
    company_tax_id: 82911239,
    company_address: "新北市土城區中華路2段212號2樓",
    company_lat: 24.9685667,
    company_lng: 121.4370729,
    paid_at: "2021-09-27T12:00:41.416+08:00",
    member_name: "張文彥",
    member_address: "新北市土城區青雲路483巷12弄24號4樓",
    member_lat: 24.9730201,
    member_lng: 121.4596711,
  },
  {
    company_name: "好事貸有限公司",
    company_tax_id: 85020415,
    company_address: "臺中市西區忠誠里臺灣大道二段573號12樓",
    company_lat: 24.1576798,
    company_lng: 120.6580182,
    paid_at: "2021-11-02T14:20:57.445+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "虹彩服飾股份有限公司",
    company_tax_id: 82911239,
    company_address: "新北市土城區中華路2段212號2樓",
    company_lat: 24.9685667,
    company_lng: 121.4370729,
    paid_at: "2021-09-27T12:00:41.416+08:00",
    member_name: "張文彥",
    member_address: "新北市土城區青雲路483巷12弄24號4樓",
    member_lat: 24.9730201,
    member_lng: 121.4596711,
  },
  {
    company_name: "英格斯能源股份有限公司",
    company_tax_id: 91086370,
    company_address: "臺中市北區中清路一段79號5樓",
    company_lat: 24.1546207,
    company_lng: 120.6771246,
    paid_at: "2021-08-02T12:29:40.283+08:00",
    member_name: "蔣雅文",
    member_address: "高雄市岡山區柳橋西路110巷2弄4號",
    member_lat: 24.3443571,
    member_lng: 120.6261693,
  },
  {
    company_name: "Embassy of Saint Vincent and the Grenadines",
    company_tax_id: 76342626,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-04T13:39:50.678+08:00",
    member_name: "連英佑",
    member_address: "新北市板橋區和平里11鄰國慶路158巷10-1號4樓",
    member_lat: 24.9956586,
    member_lng: 121.4612633,
  },
  {
    company_name: "數字科技股份有限公司",
    company_tax_id: 28466605,
    company_address: "新北市三重區重新路5段609巷12號10樓",
    company_lat: 25.0466943,
    company_lng: 121.468381,
    paid_at: "2021-11-04T23:12:50.621+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "寶島手路菜有限公司",
    company_tax_id: 42839649,
    company_address: "臺北市萬華區峨眉街100號7樓之16",
    company_lat: 25.0442299,
    company_lng: 121.5034445,
    paid_at: "2021-11-05T10:25:11.513+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "屏東縣立大同高中",
    company_tax_id: 91600003,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-05T14:13:56.536+08:00",
    member_name: "江佩容",
    member_address: "新北市三重區長壽街42號4樓",
    member_lat: 25.0684103,
    member_lng: 121.5002992,
  },
  {
    company_name: "奔跑山豬運動事業有限公司",
    company_tax_id: 85088168,
    company_address: "花蓮縣花蓮市主睦里成功街145號一、二樓",
    company_lat: 23.9728987,
    company_lng: 121.6051326,
    paid_at: "2021-06-13T20:11:08.936+08:00",
    member_name: "吳飛旭",
    member_address: "臺中市南屯區大業里9鄰東興路三段239號13樓之1",
    member_lat: 24.1529344,
    member_lng: 120.6528566,
  },
  {
    company_name: "彩蛋影像有限公司",
    company_tax_id: 82868556,
    company_address: "高雄市鳳山區瑞隆東路209巷5號1樓",
    company_lat: 22.6058924,
    company_lng: 120.3391854,
    paid_at: "2021-11-06T12:45:54.181+08:00",
    member_name: "江元宏",
    member_address: "南投縣埔里鎮中山路二段336號",
    member_lat: 23.9648647,
    member_lng: 120.9668102,
  },
  {
    company_name: "裕眾電子商務有限公司",
    company_tax_id: 91071860,
    company_address: "高雄市鳳山區老爺四街135巷2之4號",
    company_lat: 22.6127906,
    company_lng: 120.3490253,
    paid_at: "2021-11-08T00:06:11.206+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "數字科技股份有限公司",
    company_tax_id: 28466605,
    company_address: "新北市三重區重新路5段609巷12號10樓",
    company_lat: 25.0466943,
    company_lng: 121.468381,
    paid_at: "2021-11-04T23:12:50.621+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "阿托科技股份有限公司",
    company_tax_id: 16085721,
    company_address: "臺北市松山區南京東路3段285號4樓",
    company_lat: 25.0520908,
    company_lng: 121.5456188,
    paid_at: "2021-11-08T16:01:25.814+08:00",
    member_name: "陳祐萱",
    member_address: "新北市新店區北新路二段29巷3弄7號7樓",
    member_lat: 24.9728247,
    member_lng: 121.5419271,
  },
  {
    company_name: "衣衫衣舞股份有限公司",
    company_tax_id: 53870391,
    company_address: "彰化縣花壇鄉中庄村車路街196巷臨26之1號",
    company_lat: 24.0120102,
    company_lng: 120.5549267,
    paid_at: "2021-11-08T16:46:19.17+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "目目和設計有限公司",
    company_tax_id: 54874509,
    company_address: "新北市永和區國中路14號4樓之1",
    company_lat: 25.0049271,
    company_lng: 121.5244982,
    paid_at: "2021-08-31T12:37:22.653+08:00",
    member_name: "陳瑞軒",
    member_address: "桃園市中壢區大仁二街15號4樓之11",
    member_lat: 24.9548991,
    member_lng: 121.2422706,
  },
  {
    company_name: "享萊股份有限公司",
    company_tax_id: 24569221,
    company_address: "臺北市大同區承德路3段287之2號",
    company_lat: 25.0747421,
    company_lng: 121.520783,
    paid_at: "2021-07-04T14:39:58.944+08:00",
    member_name: "郭子德",
    member_address: "臺中市太平區中平九街141巷1號",
    member_lat: 24.9722347,
    member_lng: 121.5450225,
  },
  {
    company_name: "一棠文創工作室",
    company_tax_id: "01525068",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-09T14:52:12.604+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "夙怡國際有限公司",
    company_tax_id: 29037370,
    company_address: "臺北市內湖區江南街96巷10號",
    company_lat: 25.0771888,
    company_lng: 121.5765217,
    paid_at: "2021-11-09T15:08:12.811+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "大樹家股份有限公司古亭分公司",
    company_tax_id: 50879209,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-10T18:28:34.322+08:00",
    member_name: "林嵩皓",
    member_address: "台中市東區三賢東街三號",
    member_lat: 24.1467859,
    member_lng: 120.7084779,
  },
  {
    company_name: "靜宜大學",
    company_tax_id: 52024708,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-10T22:41:33.083+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "台灣香港邊城青年",
    company_tax_id: 88236614,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-11T13:07:38.726+08:00",
    member_name: "羅樂文",
    member_address: "新北市板橋區保安街二段45巷22號10樓",
    member_lat: 25.0002675,
    member_lng: 121.4151536,
  },
  {
    company_name: "灼見文創有限公司",
    company_tax_id: 85076297,
    company_address: "新北市三峽區復興路162號6樓之1",
    company_lat: 24.9384648,
    company_lng: 121.3711666,
    paid_at: "2021-10-26T19:03:19.692+08:00",
    member_name: "郭于菁",
    member_address: "新北市新莊區民本街27巷8-4號五樓",
    member_lat: 25.0191481,
    member_lng: 121.4285687,
  },
  {
    company_name: "晨紘國際有限公司",
    company_tax_id: 82806834,
    company_address: "臺北市士林區中正路601號8樓之7",
    company_lat: 25.086467,
    company_lng: 121.510007,
    paid_at: "2021-06-07T09:04:20.389+08:00",
    member_name: "徐俊強",
    member_address: "新竹縣新埔鎮五埔里20鄰新關路五埔段909號",
    member_lat: 24.997401,
    member_lng: 121.058871,
  },
  {
    company_name: "戴思科技股份有限公司",
    company_tax_id: 64943012,
    company_address: "臺南市仁德區後壁里後壁里德崙路77巷25弄62號1樓",
    company_lat: 22.9458329,
    company_lng: 120.2533514,
    paid_at: "2021-11-12T11:54:26.865+08:00",
    member_name: "張育銓",
    member_address: "彰化縣員林市南東里錦安巷1號",
    member_lat: 23.967504,
    member_lng: 120.6092323,
  },
  {
    company_name: "木和子影像音樂工作室",
    company_tax_id: 72917570,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-13T20:18:19.027+08:00",
    member_name: "徐翊庭",
    member_address: "台北市萬華區德昌街185巷70號5樓",
    member_lat: 25.0223687,
    member_lng: 121.4929605,
  },
  {
    company_name: "沃爾斯丹國際有限公司",
    company_tax_id: 91069127,
    company_address: "新北市樹林區三龍街45巷2號",
    company_lat: 25.0186579,
    company_lng: 121.4145123,
    paid_at: "2021-08-19T12:37:26.437+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "艾思奇整合行銷股份有限公司",
    company_tax_id: 91101920,
    company_address: "嘉義縣中埔鄉和美村大義路118號一樓",
    company_lat: 23.4529325,
    company_lng: 120.4781098,
    paid_at: "2021-11-15T14:56:02.282+08:00",
    member_name: "邱念慈",
    member_address: "新北市中和區秀福里16鄰秀朗路三段50巷七號",
    member_lat: 24.9951163,
    member_lng: 121.5248777,
  },
  {
    company_name: "展奕娛樂有限公司",
    company_tax_id: 69354800,
    company_address: "新北市蘆洲區中原路12巷32號2樓",
    company_lat: 25.084401,
    company_lng: 121.460692,
    paid_at: "2021-11-15T16:09:50.871+08:00",
    member_name: "徐翊庭",
    member_address: "台北市萬華區德昌街185巷70號5樓",
    member_lat: 25.0223687,
    member_lng: 121.4929605,
  },
  {
    company_name: "財團法人台灣敦睦聯誼會高雄圓山大飯店",
    company_tax_id: 85235083,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-19T21:23:11.867+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "台灣檢驗科技股份有限公司",
    company_tax_id: 23928467,
    company_address: "新北市五股區(新北產業園區)五工路136之1號",
    company_lat: 25.0635494,
    company_lng: 121.457472,
    paid_at: "2021-11-16T09:47:03.103+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "朝陽科技大學",
    company_tax_id: 78951384,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-16T14:22:09.196+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "年頡科技股份有限公司",
    company_tax_id: 53314727,
    company_address: "臺北市內湖區基湖路10巷46號5樓",
    company_lat: 25.0820508,
    company_lng: 121.5635504,
    paid_at: "2021-11-16T15:38:34.294+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "晰穀有限公司",
    company_tax_id: 50866181,
    company_address: "臺北市萬華區萬大路277巷8號1樓",
    company_lat: 25.0253215,
    company_lng: 121.5008819,
    paid_at: "2021-05-31T13:44:06.618+08:00",
    member_name: "譚竟汶",
    member_address: "臺北市文山區萬和里10鄰汀州路四段229號十樓",
    member_lat: 25.0026333,
    member_lng: 121.535933,
  },
  {
    company_name: "群眾自造股份有限公司",
    company_tax_id: 52354974,
    company_address: "臺北市中山區民生東路3段2號7樓",
    company_lat: 25.0576377,
    company_lng: 121.5376752,
    paid_at: "2021-05-01T00:42:16.938+08:00",
    member_name: "謝明佑",
    member_address: "台北市信義區廣居里15鄰忠孝東路五段372巷27弄13號5樓",
    member_lat: 25.0397775,
    member_lng: 121.5744715,
  },
  {
    company_name: "鉲栮有限公司",
    company_tax_id: 90835116,
    company_address: "臺南市中西區南美里民生路一段167號3樓",
    company_lat: 22.9939535,
    company_lng: 120.2002504,
    paid_at: "2021-11-17T21:20:07.847+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "盛香堂股份有限公司",
    company_tax_id: 51652709,
    company_address: "臺中市南區建國南路一段112號",
    company_lat: 24.1200416,
    company_lng: 120.6504246,
    paid_at: "2021-11-19T07:46:49.868+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "少女凱倫有限公司",
    company_tax_id: 83799754,
    company_address: "臺北市中正區羅斯福路3段244巷9弄2之1號",
    company_lat: 25.0173089,
    company_lng: 121.530071,
    paid_at: "2021-09-29T18:14:12.539+08:00",
    member_name: "林賢榮",
    member_address: "新竹縣湖口鄉鳳山村榮光路108號",
    member_lat: 24.8574073,
    member_lng: 121.0146642,
  },
  {
    company_name: "常予有限公司",
    company_tax_id: 68213022,
    company_address: "新北市三重區仁安街73號8樓",
    company_lat: 25.0839045,
    company_lng: 121.4841071,
    paid_at: "2021-11-19T10:26:13.166+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "國立臺北藝術大學",
    company_tax_id: 99335422,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-19T11:02:27.541+08:00",
    member_name: "李崇偉",
    member_address: "台北市萬華區榮德里14鄰萬大路486巷48號四樓之六",
    member_lat: 25.0207186,
    member_lng: 121.495583,
  },
  {
    company_name: "維斯塔行銷顧問有限公司",
    company_tax_id: 83434777,
    company_address: "新北市中和區建八路2號9樓之2",
    company_lat: 24.9982308,
    company_lng: 121.4862998,
    paid_at: "2021-01-22T10:12:22.625+08:00",
    member_name: "黃祺芸",
    member_address: "台北市文山區樟文里11鄰木新路三段310巷14弄18號三樓",
    member_lat: 24.9825017,
    member_lng: 121.5574692,
  },
  {
    company_name: "愛瑞思創意婚紗有限公司",
    company_tax_id: 42848099,
    company_address: "新北市新莊區昌平街342號",
    company_lat: 25.0602135,
    company_lng: 121.4548274,
    paid_at: "2021-11-19T21:20:35.118+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "專案管理顧問有限公司",
    company_tax_id: 54170109,
    company_address: "臺北市松山區南京東路3段287號10樓",
    company_lat: 25.0520768,
    company_lng: 121.5462024,
    paid_at: "2021-11-20T10:55:59.648+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "寵物百分百股份有限公司",
    company_tax_id: 83201117,
    company_address: "高雄市仁武區鳳仁路231號1樓",
    company_lat: 22.6979627,
    company_lng: 120.3465459,
    paid_at: "2021-11-01T08:00:51.861+08:00",
    member_name: "鄭鈞元",
    member_address: "新北市永和區民治里20鄰民有街21巷1弄4號四樓",
    member_lat: 24.9969024,
    member_lng: 121.5168138,
  },
  {
    company_name: "平原創意股份有限公司",
    company_tax_id: 42853509,
    company_address: "臺北市大安區敦化南路1段233巷48號1樓",
    company_lat: 25.0404112,
    company_lng: 121.5491047,
    paid_at: "2021-11-24T17:24:52.803+08:00",
    member_name: "林嵩皓",
    member_address: "台中市東區三賢東街三號",
    member_lat: 24.1467859,
    member_lng: 120.7084779,
  },
  {
    company_name: "獨立女子整合行銷股份有限公司",
    company_tax_id: 83194988,
    company_address: "臺北市內湖區內湖路2段51號2樓之2",
    company_lat: 25.0795905,
    company_lng: 121.581868,
    paid_at: "2021-11-25T10:50:06.923+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "一特安餐飲股份有限公司",
    company_tax_id: 50866422,
    company_address: "臺北市中正區重慶南路1段10號8樓之15",
    company_lat: 25.041934,
    company_lng: 121.513307,
    paid_at: "2021-11-26T15:15:40.709+08:00",
    member_name: "徐翊庭",
    member_address: "台北市萬華區德昌街185巷70號5樓",
    member_lat: 25.0223687,
    member_lng: 121.4929605,
  },
  {
    company_name: "寬陽台空間創創股份有限公司",
    company_tax_id: 83382010,
    company_address: "臺北市大安區復興南路2段327號14樓之2",
    company_lat: 25.0238286,
    company_lng: 121.5437552,
    paid_at: "2021-10-05T10:04:33.769+08:00",
    member_name: "張宇良",
    member_address: "桃園市中壢區普義里5鄰四維路1巷6號",
    member_lat: 24.9598402,
    member_lng: 121.2316601,
  },
  {
    company_name: "寬陽台空間創創股份有限公司",
    company_tax_id: 83382010,
    company_address: "臺北市大安區復興南路2段327號14樓之2",
    company_lat: 25.0238286,
    company_lng: 121.5437552,
    paid_at: "2021-10-05T10:04:33.769+08:00",
    member_name: "張宇良",
    member_address: "桃園市中壢區普義里5鄰四維路1巷6號",
    member_lat: 24.9598402,
    member_lng: 121.2316601,
  },
  {
    company_name: "詠暉行銷股份有限公司",
    company_tax_id: 90526532,
    company_address: "臺中市西屯區潮洋里市政北二路282號19樓之11",
    company_lat: 24.1644388,
    company_lng: 120.6364718,
    paid_at: "2021-11-29T02:14:49.093+08:00",
    member_name: "崔立言",
    member_address: "台北市士林區菁山里8鄰菁山路101巷21弄10號",
    member_lat: 25.1450771,
    member_lng: 121.5571223,
  },
  {
    company_name: "梅花鹿影業股份有限公司",
    company_tax_id: 24572817,
    company_address: "臺北市松山區民生東路5段69巷2弄3號7樓",
    company_lat: 25.0593642,
    company_lng: 121.5584971,
    paid_at: "2021-11-29T13:25:43.878+08:00",
    member_name: "張宇良",
    member_address: "桃園市中壢區普義里5鄰四維路1巷6號",
    member_lat: 24.9598402,
    member_lng: 121.2316601,
  },
  {
    company_name: "新北市興福國小",
    company_tax_id: 36079938,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-29T21:13:02.62+08:00",
    member_name: "魏嘉興",
    member_address: "新竹市東區新光里15鄰明湖路198巷3弄1號",
    member_lat: 24.7923989,
    member_lng: 120.968118,
  },
  {
    company_name: "萱普艾森國際有限公司",
    company_tax_id: 90693968,
    company_address: "高雄市苓雅區中華四路2號13樓",
    company_lat: 22.6119251,
    company_lng: 120.3029069,
    paid_at: "2021-06-08T00:10:53.479+08:00",
    member_name: "梁金後",
    member_address: "高雄縣崗山鎮程香里18鄰華園路95巷6號6樓",
    member_lat: 22.7888562,
    member_lng: 120.3057492,
  },
  {
    company_name: "灼見文創有限公司",
    company_tax_id: 85076297,
    company_address: "新北市三峽區復興路162號6樓之1",
    company_lat: 24.9384648,
    company_lng: 121.3711666,
    paid_at: "2021-10-26T19:03:19.692+08:00",
    member_name: "郭于菁",
    member_address: "新北市新莊區民本街27巷8-4號五樓",
    member_lat: 25.0191481,
    member_lng: 121.4285687,
  },
  {
    company_name: "維肯媒體股份有限公司",
    company_tax_id: 55734512,
    company_address: "臺北市信義區菸廠路88號5樓之5",
    company_lat: 25.0445608,
    company_lng: 121.5613507,
    paid_at: "2021-10-21T17:23:44.207+08:00",
    member_name: "吳翊嘉",
    member_address: "新北市土城區興城路8號11樓",
    member_lat: 24.9734145,
    member_lng: 121.442561,
  },
  {
    company_name: "緹拉設計有限公司",
    company_tax_id: 56673892,
    company_address: "新北市板橋區南興路36號",
    company_lat: 25.0076279,
    company_lng: 121.4545727,
    paid_at: "2021-11-30T21:31:38.801+08:00",
    member_name: "王雅萱",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "台灣迪卡儂有限公司",
    company_tax_id: 53048094,
    company_address: "臺中市南屯區大墩南路379號",
    company_lat: 24.1313963,
    company_lng: 120.6496192,
    paid_at: "2021-11-30T23:37:37.759+08:00",
    member_name: "林雅淳",
    member_address: "新北市三重區三和路四段138巷1號4樓",
    member_lat: 25.075436,
    member_lng: 121.488347,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "社團法人台灣數位文化協會",
    company_tax_id: 48760459,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-12-02T20:53:52.49+08:00",
    member_name: "李崇偉",
    member_address: "台北市萬華區榮德里14鄰萬大路486巷48號四樓之六",
    member_lat: 25.0207186,
    member_lng: 121.495583,
  },
  {
    company_name: "社團法人台灣數位文化協會",
    company_tax_id: 48760459,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-12-02T20:53:52.49+08:00",
    member_name: "李崇偉",
    member_address: "台北市萬華區榮德里14鄰萬大路486巷48號四樓之六",
    member_lat: 25.0207186,
    member_lng: 121.495583,
  },
  {
    company_name: "目目和設計有限公司",
    company_tax_id: 54874509,
    company_address: "新北市永和區國中路14號4樓之1",
    company_lat: 25.0049271,
    company_lng: 121.5244982,
    paid_at: "2021-08-31T12:37:22.653+08:00",
    member_name: "陳瑞軒",
    member_address: "桃園市中壢區大仁二街15號4樓之11",
    member_lat: 24.9548991,
    member_lng: 121.2422706,
  },
  {
    company_name: "苗栗縣私立云成文理補習班",
    company_tax_id: 10938202,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-12-02T22:17:17.299+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "亞奇雷科技股份有限公司",
    company_tax_id: 50890128,
    company_address: "臺北市內湖區堤頂大道1段33號8樓",
    company_lat: 25.0757019,
    company_lng: 121.572079,
    paid_at: "2021-12-03T11:47:58.725+08:00",
    member_name: "許哲耀",
    member_address: "台北市松山區南京東路五段291巷5弄2號2樓",
    member_lat: 25.0518683,
    member_lng: 121.5664522,
  },
  {
    company_name: "品晟股份有限公司",
    company_tax_id: 80274678,
    company_address: "臺北市信義區基隆路1段111號1樓",
    company_lat: 25.0449772,
    company_lng: 121.5670046,
    paid_at: "2021-12-03T23:06:28.31+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "詠越實業有限公司",
    company_tax_id: 90508274,
    company_address: "桃園市桃園區新埔六街128號(1樓)",
    company_lat: 25.0163119,
    company_lng: 121.3017863,
    paid_at: "2021-12-05T16:12:55.871+08:00",
    member_name: "林晉崴",
    member_address: "廣福路530巷1弄6號",
    member_lat: 24.9605844,
    member_lng: 121.2861506,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    company_tax_id: 50798939,
    company_address: "臺北市中山區建國北路2段129號2樓",
    company_lat: 25.059998,
    company_lng: 121.5373357,
    paid_at: "2020-10-06T10:53:36.538+08:00",
    member_name: "高永祺",
    member_address: "台北市大安區復興南路一段66號5樓",
    member_lat: 24.985548,
    member_lng: 121.5500956,
  },
  {
    company_name: "詠越實業有限公司",
    company_tax_id: 90508274,
    company_address: "桃園市桃園區新埔六街128號(1樓)",
    company_lat: 25.0163119,
    company_lng: 121.3017863,
    paid_at: "2021-12-05T16:12:55.871+08:00",
    member_name: "林晉崴",
    member_address: "廣福路530巷1弄6號",
    member_lat: 24.9605844,
    member_lng: 121.2861506,
  },
  {
    company_name: "開拓動漫事業有限公司",
    company_tax_id: 29034628,
    company_address: "新北市永和區秀朗路2段229號4樓之3",
    company_lat: 25.0002559,
    company_lng: 121.5264458,
    paid_at: "2021-12-06T13:16:17.064+08:00",
    member_name: "王瑞璞",
    member_address: "臺北市中正區頂東里4鄰羅斯福路3段84巷13號5樓",
    member_lat: 25.0213529,
    member_lng: 121.5260622,
  },
  {
    company_name: "寶久實業股份有限公司",
    company_tax_id: 84408024,
    company_address: "新北市中和區員山路502號6樓之8",
    company_lat: 25.0073391,
    company_lng: 121.4797564,
    paid_at: "2021-12-06T15:14:15.064+08:00",
    member_name: "胡庭茵",
    member_address: "桃園市桃園區大豐里4鄰金門二街238號七樓",
    member_lat: 24.9817795,
    member_lng: 121.3143749,
  },
  {
    company_name: "玖惟股份有限公司",
    company_tax_id: 90702468,
    company_address: "臺北市大安區和平東路2段265巷3號1樓",
    company_lat: 25.0253124,
    company_lng: 121.544109,
    paid_at: "2021-12-06T16:30:37.916+08:00",
    member_name: "蔡孟儒",
    member_address: "澎湖縣馬公市西衛里27鄰五福路60-1號",
    member_lat: 23.5806888,
    member_lng: 119.5773277,
  },
  {
    company_name: "玖惟股份有限公司",
    company_tax_id: 90702468,
    company_address: "臺北市大安區和平東路2段265巷3號1樓",
    company_lat: 25.0253124,
    company_lng: 121.544109,
    paid_at: "2021-12-06T16:30:37.916+08:00",
    member_name: "蔡孟儒",
    member_address: "澎湖縣馬公市西衛里27鄰五福路60-1號",
    member_lat: 23.5806888,
    member_lng: 119.5773277,
  },
  {
    company_name: "亞圖斯科技工作室",
    company_tax_id: 72526840,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-05-31T23:19:51.757+08:00",
    member_name: "林冠霓",
    member_address: "台中市大里區日新路272巷3號",
    member_lat: 24.114391,
    member_lng: 120.6935115,
  },
  {
    company_name: "沃爾斯丹國際有限公司",
    company_tax_id: 91069127,
    company_address: "新北市樹林區三龍街45巷2號",
    company_lat: 25.0186579,
    company_lng: 121.4145123,
    paid_at: "2021-08-19T12:37:26.437+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0684103,
    member_lng: 121.5002992,
  },
  {
    company_name: "財團法人麗寶基金會",
    company_tax_id: 42065089,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-12-08T12:40:23.26+08:00",
    member_name: "朱淇宏",
    member_address: "新北市淡水區竿蓁里3鄰坪頂路56之9五樓",
    member_lat: 25.1625342,
    member_lng: 121.458763,
  },
  {
    company_name: "歐洲之星貿易有限公司",
    company_tax_id: 54734108,
    company_address: "新北市板橋區中山路2段62號7樓之3",
    company_lat: 25.0157681,
    company_lng: 121.4741077,
    paid_at: "2021-12-08T14:27:53.681+08:00",
    member_name: "黃琮群",
    member_address: "台南市七股區大埕里21鄰大寮97號",
    member_lat: 23.14922,
    member_lng: 120.149802,
  },
  {
    company_name: "創宇數位科技有限公司",
    company_tax_id: 25166891,
    company_address: "桃園市中壢區普強里中山東路二段154號1樓",
    company_lat: 24.9528608,
    company_lng: 121.2392835,
    paid_at: "2021-12-09T17:59:27.484+08:00",
    member_name: "黃明斌",
    member_address: "臺中市北屯區舊社里1鄰南興路925號三樓之三",
    member_lat: 24.1932956,
    member_lng: 120.705869,
  },
  {
    company_name: "午洋企業有限公司",
    company_tax_id: 12485134,
    company_address: "臺北市中山區南京東路2段66號9樓之2",
    company_lat: 25.0518726,
    company_lng: 121.5303935,
    paid_at: "2021-12-10T05:55:49.578+08:00",
    member_name: "殷聖哲",
    member_address: "新北市汐止區北山里19鄰明峰街298巷1號5樓",
    member_lat: 25.0685395,
    member_lng: 121.6249906,
  },
  {
    company_name: "振泰檢驗科技股份有限公司",
    company_tax_id: 54733826,
    company_address: "新北市汐止區新台五路1段79號13樓之7",
    company_lat: 25.061683,
    company_lng: 121.6459607,
    paid_at: "2021-12-10T10:17:02.35+08:00",
    member_name: "林陳揚",
    member_address: "台北市萬華區雙園里3鄰興寧街71號三樓",
    member_lat: 25.0323203,
    member_lng: 121.4998264,
  },
  {
    company_name: "和光里股份有限公司",
    company_tax_id: 83554879,
    company_address: "臺北市大安區信義路4段296號15樓",
    company_lat: 25.0329977,
    company_lng: 121.5544765,
    paid_at: "2021-08-05T10:53:00.384+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 24.489881,
    member_lng: 120.782511,
  },
  {
    company_name: "好心藝國際股份有限公司",
    company_tax_id: 27318705,
    company_address: "臺北市士林區承德路4段150號5樓",
    company_lat: 25.0850965,
    company_lng: 121.5231469,
    paid_at: "2021-12-10T16:18:56.469+08:00",
    member_name: "范芳婷",
    member_address: "新竹市東區龍山里8鄰長春街145號",
    member_lat: 24.7888271,
    member_lng: 121.01513,
  },
  {
    company_name: "撼星數位行銷股份有限公司",
    company_tax_id: 82911293,
    company_address: "臺北市中山區龍江路295巷10之2號",
    company_lat: 25.0606546,
    company_lng: 121.5414738,
    paid_at: "2021-12-11T12:26:46.318+08:00",
    member_name: "邱念慈",
    member_address: "新北市中和區秀福里16鄰秀朗路三段50巷七號",
    member_lat: 24.9951163,
    member_lng: 121.5248777,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "若晨室內裝修有限公司",
    company_tax_id: 83075603,
    company_address: "新北市新莊區新崑路179號3樓",
    company_lat: 25.0001902,
    company_lng: 121.4331912,
    paid_at: "2021-04-23T10:00:46.108+08:00",
    member_name: "鄭婷君",
    member_address: "新竹縣竹北市東興路二段1020巷73號",
    member_lat: 24.7849112,
    member_lng: 121.0651768,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "典珀有限公司",
    company_tax_id: 82959681,
    company_address: "臺北市大安區忠孝東路4段170巷6弄2號7樓",
    company_lat: 25.0407739,
    company_lng: 121.5507033,
    paid_at: "2021-12-13T23:19:31.549+08:00",
    member_name: "姚盈伃",
    member_address: "台北市北投區永明里7鄰義理街63巷17號2樓",
    member_lat: 25.117228,
    member_lng: 121.514173,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "明基電通股份有限公司",
    company_tax_id: 70721522,
    company_address: "臺北市內湖區基湖路16號",
    company_lat: 25.0812608,
    company_lng: 121.5645846,
    paid_at: "2021-08-13T13:30:25.081+08:00",
    member_name: "張杰",
    member_address: "台北市中山區明水路397巷2弄10號8樓",
    member_lat: 25.077976,
    member_lng: 121.5472669,
  },
  {
    company_name: "高雄醫學大學附設中和紀念醫院",
    company_tax_id: 76215451,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-10-28T14:26:03.402+08:00",
    member_name: "張宇良",
    member_address: "桃園市中壢區普義里5鄰四維路1巷6號",
    member_lat: 24.9598402,
    member_lng: 121.2316601,
  },
  {
    company_name: "勝宏精密科技股份有限公司",
    company_tax_id: 53780954,
    company_address: "臺中市大里區日新里福大路41號1樓",
    company_lat: 24.1210479,
    company_lng: 120.6938779,
    paid_at: "2021-12-14T15:27:37.733+08:00",
    member_name: "黃明斌",
    member_address: "臺中市北屯區舊社里1鄰南興路925號三樓之三",
    member_lat: 24.1932956,
    member_lng: 120.705869,
  },
  {
    company_name: "高雄室內合唱團",
    company_tax_id: 57960247,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-06-29T09:20:14.463+08:00",
    member_name: "何威廷",
    member_address: "臺南市安平區建平七街370巷131弄43號",
    member_lat: 22.9895606,
    member_lng: 120.1768171,
  },
  {
    company_name: "西街阿爸有限公司",
    company_tax_id: 90475359,
    company_address: "臺北市松山區敦化北路222巷31號2樓",
    company_lat: 25.059912,
    company_lng: 121.547497,
    paid_at: "2021-12-15T18:58:25.76+08:00",
    member_name: "林陳揚",
    member_address: "台北市萬華區雙園里3鄰興寧街71號三樓",
    member_lat: 25.0323203,
    member_lng: 121.4998264,
  },
  {
    company_name: "艾思奇整合行銷股份有限公司",
    company_tax_id: 91101920,
    company_address: "嘉義縣中埔鄉和美村大義路118號一樓",
    company_lat: 23.4529325,
    company_lng: 120.4781098,
    paid_at: "2021-11-15T14:56:02.282+08:00",
    member_name: "邱念慈",
    member_address: "新北市中和區秀福里16鄰秀朗路三段50巷七號",
    member_lat: 24.9951163,
    member_lng: 121.5248777,
  },
  {
    company_name: "幻境咖啡有限公司",
    company_tax_id: 42983170,
    company_address: "桃園市中壢區龍興路591號",
    company_lat: 24.9441166,
    company_lng: 121.2442991,
    paid_at: "2021-12-16T16:57:20.747+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "苗栗縣私立云成文理補習班",
    company_tax_id: 10938202,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-12-02T22:17:17.299+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "享萊股份有限公司",
    company_tax_id: 24569221,
    company_address: "臺北市大同區承德路3段287之2號",
    company_lat: 25.0747421,
    company_lng: 121.520783,
    paid_at: "2021-07-04T14:39:58.944+08:00",
    member_name: "郭子德",
    member_address: "臺中市太平區中平九街141巷1號",
    member_lat: 24.1233142,
    member_lng: 120.7203394,
  },
  {
    company_name: "光穹遊戲股份有限公司",
    company_tax_id: 42755943,
    company_address: "臺北市文山區羅斯福路6段164號2樓",
    company_lat: 24.994976,
    company_lng: 121.5409669,
    paid_at: "2021-12-17T21:58:38.926+08:00",
    member_name: "王雅萱",
    member_address: "台南市佳里區安西里16鄰安西路201巷33弄2號",
    member_lat: 23.1638642,
    member_lng: 120.1701408,
  },
  {
    company_name: "沃草有限公司",
    company_tax_id: 54648384,
    company_address: "臺北市中正區懷寧街92之1號7樓",
    company_lat: 25.042685,
    company_lng: 121.513944,
    paid_at: "2021-12-20T11:36:18.663+08:00",
    member_name: "范子祥",
    member_address: "新竹縣竹東鎮中山里2鄰大林路69號",
    member_lat: 24.7311127,
    member_lng: 121.089709,
  },
  {
    company_name: "吳爾芙國際有限公司",
    company_tax_id: 83281076,
    company_address: "臺北市信義區基隆路2段149之49號11樓之9",
    company_lat: 25.026585,
    company_lng: 121.55531,
    paid_at: "2021-12-20T12:53:05.309+08:00",
    member_name: "陳炳彰",
    member_address: "台北市中山北路六段186巷25弄15號4樓",
    member_lat: 25.1083946,
    member_lng: 121.5266562,
  },
  {
    company_name: "沐石設計室內裝修有限公司",
    company_tax_id: 90468239,
    company_address: "臺北市中正區羅斯福路4段68號7樓之13",
    company_lat: 25.014612,
    company_lng: 121.5342254,
    paid_at: "2021-12-20T15:36:37.042+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "源美國際企業有限公司",
    company_tax_id: 53747392,
    company_address: "臺北市中山區南京東路3段118號7樓之2",
    company_lat: 25.0517428,
    company_lng: 121.54033,
    paid_at: "2021-12-20T16:08:00.416+08:00",
    member_name: "周承泰",
    member_address: "雙城街18巷21號五樓",
    member_lat: 25.0655139,
    member_lng: 121.523063,
  },
  {
    company_name: "中租迪和股份有限公司",
    company_tax_id: "05072925",
    company_address: "臺北市內湖區瑞光路362號8樓至12樓",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-12-21T10:37:41.093+08:00",
    member_name: "Nichaphat Saewang",
    member_address: "台北市萬華區大里街30號8樓",
    member_lat: 25.0345587,
    member_lng: 121.5016021,
  },
  {
    company_name: "國立中正大學",
    company_tax_id: "06313774",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2020-12-09T11:37:13.169+08:00",
    member_name: "何威廷",
    member_address: "臺南市安平區建平七街370巷131弄43號",
    member_lat: 25.0213987,
    member_lng: 121.5181751,
  },
  {
    company_name: "歐洲之星貿易有限公司",
    company_tax_id: 54734108,
    company_address: "新北市板橋區中山路2段62號7樓之3",
    company_lat: 25.0157681,
    company_lng: 121.4741077,
    paid_at: "2021-12-08T14:27:53.681+08:00",
    member_name: "黃琮群",
    member_address: "台南市七股區大埕里21鄰大寮97號",
    member_lat: 23.14922,
    member_lng: 120.149802,
  },
  {
    company_name: "得速科技有限公司",
    company_tax_id: 27968587,
    company_address: "新北市新店區復興路45號10樓",
    company_lat: 24.986762,
    company_lng: 121.5367631,
    paid_at: "2021-12-24T16:01:34.855+08:00",
    member_name: "禚昌龍",
    member_address: "高雄市鼓山區裕豐里4鄰裕豐街三號三樓之ㄧ",
    member_lat: 22.665388,
    member_lng: 120.2950671,
  },
  {
    company_name: "中國青年救國團桃園市中壢國民運動中心",
    company_tax_id: 82195670,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-02-05T13:57:47.982+08:00",
    member_name: "張辰宇",
    member_address: "桃園市桃園區青溪里3鄰鎮二街41號",
    member_lat: 24.9990709,
    member_lng: 121.315304,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 24.171243,
    member_lng: 120.755511,
  },
  {
    company_name: "寵愛有家股份有限公司",
    company_tax_id: 42623535,
    company_address: "苗栗縣苑裡鎮苑港里2鄰苑港10之69號",
    company_lat: 24.4505467,
    company_lng: 120.6546231,
    paid_at: "2021-12-27T23:04:52.978+08:00",
    member_name: "譚竟汶",
    member_address: "臺北市文山區萬和里10鄰汀州路四段229號十樓",
    member_lat: 25.0026333,
    member_lng: 121.535933,
  },
  {
    company_name: "社團法人全球小紅帽協會",
    company_tax_id: 87527396,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-09-09T23:27:31.226+08:00",
    member_name: "江佩容",
    member_address: "新北市三重區長壽街42號4樓",
    member_lat: 25.0684103,
    member_lng: 121.5002992,
  },
  {
    company_name: "台灣香港邊城青年",
    company_tax_id: 88236614,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-11-11T13:07:38.726+08:00",
    member_name: "羅樂文",
    member_address: "新北市板橋區保安街二段45巷22號10樓",
    member_lat: 25.0002675,
    member_lng: 121.4151536,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 25.0067357,
    member_lng: 121.5001374,
  },
  {
    company_name: "萱普艾森國際有限公司",
    company_tax_id: 90693968,
    company_address: "高雄市苓雅區中華四路2號13樓",
    company_lat: 22.6119251,
    company_lng: 120.3029069,
    paid_at: "2021-06-08T00:10:53.479+08:00",
    member_name: "梁金後",
    member_address: "高雄縣崗山鎮程香里18鄰華園路95巷6號6樓",
    member_lat: 22.7888562,
    member_lng: 120.3057492,
  },
  {
    company_name: "寵物百分百股份有限公司",
    company_tax_id: 83201117,
    company_address: "高雄市仁武區鳳仁路231號1樓",
    company_lat: 22.6979627,
    company_lng: 120.3465459,
    paid_at: "2021-11-01T08:00:51.861+08:00",
    member_name: "鄭鈞元",
    member_address: "新北市永和區民治里20鄰民有街21巷1弄4號四樓",
    member_lat: 24.9969024,
    member_lng: 121.5168138,
  },
  {
    company_name: "維斯塔行銷顧問有限公司",
    company_tax_id: 83434777,
    company_address: "新北市中和區建八路2號9樓之2",
    company_lat: 24.9982308,
    company_lng: 121.4862998,
    paid_at: "2021-01-22T10:12:22.625+08:00",
    member_name: "黃祺芸",
    member_address: "台北市文山區樟文里11鄰木新路三段310巷14弄18號三樓",
    member_lat: 24.9825017,
    member_lng: 121.5574692,
  },
  {
    company_name: "虹彩服飾股份有限公司",
    company_tax_id: 82911239,
    company_address: "新北市土城區中華路2段212號2樓",
    company_lat: 24.9685667,
    company_lng: 121.4370729,
    paid_at: "2021-09-27T12:00:41.416+08:00",
    member_name: "張文彥",
    member_address: "新北市土城區青雲路483巷12弄24號4樓",
    member_lat: 24.9730201,
    member_lng: 121.4596711,
  },
  {
    company_name: "檸檬知識創新股份有限公司",
    company_tax_id: 83243587,
    company_address: "臺北市松山區八德路3段218號7樓",
    company_lat: 25.0479971,
    company_lng: 121.5569447,
    paid_at: "2021-01-29T01:44:05.357+08:00",
    member_name: "潘信達",
    member_address: "臺灣省苗栗縣銅鑼鄉朝陽村11鄰朝西13之2號",
    member_lat: 24.9567337,
    member_lng: 121.2076469,
  },
  {
    company_name: "朵墨國際事業有限公司",
    company_tax_id: 54136807,
    company_address: "臺北市松山區八德路2段346巷7弄26號1樓",
    company_lat: 25.046696,
    company_lng: 121.545515,
    paid_at: "2021-12-31T19:27:14.565+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "資創國際保險經紀人股份有限公司",
    company_tax_id: 50896158,
    company_address: "臺北市松山區復興北路167號8樓之2",
    company_lat: 25.053975,
    company_lng: 121.5443208,
    paid_at: "2021-07-08T00:43:11.51+08:00",
    member_name: "陳薪智",
    member_address: "新北市土城區仁愛路4號4樓",
    member_lat: 24.9810958,
    member_lng: 121.4597896,
  },
  {
    company_name: "實踐大學",
    company_tax_id: "03724401",
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-08-02T16:11:17.794+08:00",
    member_name: "白玉萱",
    member_address: "新北市汐止區大同里17鄰大同路二段272巷1號 11樓-3",
    member_lat: 22.6729732,
    member_lng: 120.3086891,
  },
  {
    company_name: "天城影像有限公司",
    company_tax_id: 66575168,
    company_address: "新北市中和區圓通路295之3號13樓",
    company_lat: 24.9883149,
    company_lng: 121.4873125,
    paid_at: "2022-01-03T21:55:42.98+08:00",
    member_name: "姚盈伃",
    member_address: "台北市北投區永明里7鄰義理街63巷17號2樓",
    member_lat: 25.117228,
    member_lng: 121.514173,
  },
  {
    company_name: "村山生技有限公司",
    company_tax_id: 90828662,
    company_address: "臺北市中山區南京東路1段25號8樓之2",
    company_lat: 25.0523902,
    company_lng: 121.5242342,
    paid_at: "2022-01-03T22:09:55.555+08:00",
    member_name: "陳曦",
    member_address: "台南市仁德區成功里15鄰全福路80巷14弄15號",
    member_lat: 22.9527899,
    member_lng: 120.2293325,
  },
  {
    company_name: "虹彩服飾股份有限公司",
    company_tax_id: 82911239,
    company_address: "新北市土城區中華路2段212號2樓",
    company_lat: 24.9685667,
    company_lng: 121.4370729,
    paid_at: "2021-09-27T12:00:41.416+08:00",
    member_name: "張文彥",
    member_address: "新北市土城區青雲路483巷12弄24號4樓",
    member_lat: 24.9730201,
    member_lng: 121.4596711,
  },
  {
    company_name: "淳貿企業有限公司",
    company_tax_id: 12125595,
    company_address: "臺北市南港區八德路4段768巷7號3樓之1",
    company_lat: 25.049848,
    company_lng: 121.580619,
    paid_at: "2022-01-04T15:36:18.496+08:00",
    member_name: "吳家銘",
    member_address: "高雄市苓雅區林德里3鄰英明路307號",
    member_lat: 22.622071,
    member_lng: 120.322492,
  },
  {
    company_name: "晶衍有限公司",
    company_tax_id: 24976846,
    company_address: "臺北市內湖區內湖路1段250號12樓",
    company_lat: 25.0819853,
    company_lng: 121.5666813,
    paid_at: "2022-01-04T16:08:54.276+08:00",
    member_name: "林晉崴",
    member_address: "廣福路530巷1弄6號",
    member_lat: 24.9605844,
    member_lng: 121.2861506,
  },
  {
    company_name: "拓治股份有限公司",
    company_tax_id: 12565208,
    company_address: "臺北市南港區忠孝東路7段520號4樓",
    company_lat: 25.0524069,
    company_lng: 121.6107834,
    paid_at: "2021-09-23T09:40:04.196+08:00",
    member_name: "陳盈竹",
    member_address: "新北市中和區福美里19鄰莊敬路33巷7之3號",
    member_lat: 24.171243,
    member_lng: 120.755511,
  },
  {
    company_name: "菲爾資產有限公司",
    company_tax_id: 24779578,
    company_address: "臺北市信義區基隆路1段141號13樓之9",
    company_lat: 25.042986,
    company_lng: 121.565952,
    paid_at: "2022-01-05T09:44:22.793+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    company_tax_id: 50798939,
    company_address: "臺北市中山區建國北路2段129號2樓",
    company_lat: 25.059998,
    company_lng: 121.5373357,
    paid_at: "2020-10-06T10:53:36.538+08:00",
    member_name: "高永祺",
    member_address: "台北市大安區復興南路一段66號5樓",
    member_lat: 24.985548,
    member_lng: 121.5500956,
  },
  {
    company_name: "紘祥數位科技敎育有限公司",
    company_tax_id: 50798939,
    company_address: "臺北市中山區建國北路2段129號2樓",
    company_lat: 25.059998,
    company_lng: 121.5373357,
    paid_at: "2020-10-06T10:53:36.538+08:00",
    member_name: "高永祺",
    member_address: "台北市大安區復興南路一段66號5樓",
    member_lat: 25.079103,
    member_lng: 121.4850629,
  },
  {
    company_name: "采傑整合行銷國際有限公司",
    company_tax_id: 83410986,
    company_address: "臺北市大安區忠孝東路4段107號12樓",
    company_lat: 25.0417523,
    company_lng: 121.5483089,
    paid_at: "2021-10-08T16:15:37.253+08:00",
    member_name: "林陳揚",
    member_address: "台北市萬華區雙園里3鄰興寧街71號三樓",
    member_lat: 25.0323203,
    member_lng: 121.4998264,
  },
  {
    company_name: "天然健康股份有限公司",
    company_tax_id: 83102904,
    company_address: "南投縣埔里鎮中心路254號",
    company_lat: 23.9592182,
    company_lng: 120.9800463,
    paid_at: "2022-01-05T16:12:35.651+08:00",
    member_name: "江佩容",
    member_address: "新北市三重區長壽街42號4樓",
    member_lat: 25.0684103,
    member_lng: 121.5002992,
  },
  {
    company_name: "大人山養有限公司",
    company_tax_id: 90551659,
    company_address: "臺北市士林區磺溪街50巷11號",
    company_lat: 25.107024,
    company_lng: 121.523208,
    paid_at: "2022-01-07T18:46:43.687+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "顧廣毅創意工作室",
    company_tax_id: 72988722,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2021-10-26T14:12:41.541+08:00",
    member_name: "王偉泰",
    member_address: "台北市大同區星明里12鄰太原路155號15樓之3",
    member_lat: 25.0553197,
    member_lng: 121.5167724,
  },
  {
    company_name: "丰沛行銷管理顧問有限公司",
    company_tax_id: 83212395,
    company_address: "臺北市松山區南京東路4段1號2樓",
    company_lat: 25.0519458,
    company_lng: 121.5495015,
    paid_at: "2022-01-10T13:14:33.417+08:00",
    member_name: "黃子杰",
    member_address: "向善街41號八樓街",
    member_lat: 25.0187982,
    member_lng: 121.3098325,
  },
  {
    company_name: "絕色時尚股份有限公司",
    company_tax_id: 25123556,
    company_address: "臺北市內湖區內湖路1段120巷17號1樓",
    company_lat: 25.0821205,
    company_lng: 121.5624847,
    paid_at: "2020-10-23T11:33:43.757+08:00",
    member_name: "黃三鳳",
    member_address: "屏東縣琉球鄉23鄰中山路8-27號",
    member_lat: 25.034767,
    member_lng: 121.4931434,
  },
  {
    company_name: "億力鑫系統科技股份有限公司",
    company_tax_id: 27679496,
    company_address: "新竹縣竹北市台元街20號6樓之1",
    company_lat: 24.8404996,
    company_lng: 121.0120145,
    paid_at: "2022-01-10T15:25:55.533+08:00",
    member_name: "劉柏年",
    member_address: "桃園區中壢市永福路961號8樓",
    member_lat: 24.9630312,
    member_lng: 121.2530793,
  },
  {
    company_name: "國立高雄科技大學",
    company_tax_id: 76014406,
    company_address: "",
    company_lat: 0,
    company_lng: 0,
    paid_at: "2022-01-10T16:15:13.873+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "和光里股份有限公司",
    company_tax_id: 83554879,
    company_address: "臺北市大安區信義路4段296號15樓",
    company_lat: 25.0329977,
    company_lng: 121.5544765,
    paid_at: "2021-08-05T10:53:00.384+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0444286,
    member_lng: 121.4429906,
  },
  {
    company_name: "勝騰緩衝研發股份有限公司",
    company_tax_id: 80039415,
    company_address: "新北市新莊區中和街118號8樓",
    company_lat: 25.0476435,
    company_lng: 121.4476099,
    paid_at: "2021-08-25T21:12:12.007+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0684103,
    member_lng: 121.5002992,
  },
  {
    company_name: "勝騰緩衝研發股份有限公司",
    company_tax_id: 80039415,
    company_address: "新北市新莊區中和街118號8樓",
    company_lat: 25.0476435,
    company_lng: 121.4476099,
    paid_at: "2021-08-25T21:12:12.007+08:00",
    member_name: "魏心如",
    member_address: "新北市新莊區立泰里21鄰新泰路413巷8號",
    member_lat: 25.0684103,
    member_lng: 121.5002992,
  },
  {
    company_name: "幻境咖啡有限公司",
    company_tax_id: 42983170,
    company_address: "桃園市中壢區龍興路591號",
    company_lat: 24.9441166,
    company_lng: 121.2442991,
    paid_at: "2021-12-16T16:57:20.747+08:00",
    member_name: "李嘉展",
    member_address: "金門縣金寧鄉湖埔村31鄰環島西路二段83號",
    member_lat: 24.4567766,
    member_lng: 118.3148323,
  },
  {
    company_name: "糖果狗戰略行銷有限公司",
    company_tax_id: 90475533,
    company_address: "臺北市中山區南京東路2段132號9樓之1",
    company_lat: 25.0517452,
    company_lng: 121.5336982,
    paid_at: "2022-01-12T23:14:50.512+08:00",
    member_name: "蔡亞庭",
    member_address: "金門縣金寧鄉湖埔村18鄰下埔下66號",
    member_lat: 24.443171,
    member_lng: 118.309798,
  },
  {
    company_name: "典珀有限公司",
    company_tax_id: 82959681,
    company_address: "臺北市大安區忠孝東路4段170巷6弄2號7樓",
    company_lat: 25.0407739,
    company_lng: 121.5507033,
    paid_at: "2021-12-13T23:19:31.549+08:00",
    member_name: "姚盈伃",
    member_address: "台北市北投區永明里7鄰義理街63巷17號2樓",
    member_lat: 25.222207,
    member_lng: 121.474466,
  },
];

export default MockData;
