import { Container, Grid } from "@material-ui/core";
import { Button, SWAPSpace, Typography, Modal, Skeleton } from "@yosgo/swap-ui";
import { useEffect, useState } from "react";
import styled from "styled-components";
import "html5-device-mockups/dist/device-mockups.min.css";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkIcon from "@material-ui/icons/Link";
import Instagram from "@material-ui/icons/Instagram";

const ProfileWrap = styled.div`
  .stepButton {
    position: absolute;
    bottom: 24px;
    left: 0;
    width: 100%;
  }
  .search {
    display: block;
    width: 100%;
    object-fit: contain;
  }
`;

const ProfileArea = styled.div`
  background: white;
  width: 100%;
  height: 80vh;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.015);
  padding: 24px;
  position: relative;
  .verified {
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .verified_ani {
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 24px;
    right: 24px;
    animation: breathing 2s ease-out infinite normal;
  }
  @keyframes breathing {
    0% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    25% {
      -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5);
    }

    60% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }

    100% {
      -webkit-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
`;

const Profile = () => {
  const [authModal, setAuthModal] = useState(false);
  const [onboarding, setOnboarding] = useState(true);
  const [step, setStep] = useState(1);
  const [howItWorkIndex, setHowItWorkIndex] = useState(0);

  useEffect(() => {
    //UI Face Key：EDB8CBD8-68ED46A0-BBE28AEE-AF26EBEF
  }, []);

  return (
    <ProfileWrap>
      {onboarding ? (
        <div>
          <Container maxWidth="lg">
            <div style={{ height: "10vh" }} />
            <Grid
              container
              alignItems="stretch"
              justifyContent="center"
              spacing={6}
              style={{ height: "80vh" }}
            >
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{ position: "relative" }}
              >
                <Typography variant="d1" color="primary900">
                  {step === 1 ? "1️⃣ 填寫業務資訊" : null}
                  {step === 2 ? "2️⃣ 發布業務資訊" : null}
                  {step === 3 ? "3️⃣ 完成實名認證" : null}
                  {step === 4 ? "🥳 大功告成" : null}
                </Typography>
                <SWAPSpace />
                <Typography
                  variant="h5"
                  color="black900"
                  style={{ fontWeight: "lighter" }}
                >
                  {step === 1
                    ? "填上你的業務資訊，告訴我們你將在 SWAP 上提供什麼樣的服務！"
                    : null}
                  {step === 2
                    ? "你的業務資訊頁面已準備就緒，發布後讓潛在客戶可透過 Google 搜尋到你！"
                    : null}
                  {step === 3
                    ? "完成實名驗證後你的業務資訊頁面將增加藍勾勾！成為 SWAP 認證的工作者！"
                    : null}
                  {step === 4 ? "你已順利完成認證，接下來你可以" : null}
                </Typography>
                <SWAPSpace size="2xl" />
                <div>
                  <Skeleton height={12} variant="text" width="20%" />
                  <SWAPSpace size="s" />
                  <Skeleton height={48} variant="text" width="50%" />
                  <SWAPSpace size="2xl" />
                  <Skeleton height={12} variant="text" width="20%" />
                  <SWAPSpace size="s" />
                  <Skeleton height={48} variant="text" width="50%" />
                  <SWAPSpace size="2xl" />
                  <Skeleton height={12} variant="text" width="20%" />
                  <SWAPSpace size="s" />
                  <Skeleton height={48} variant="text" width="50%" />
                </div>
                <div className="stepButton">
                  <Grid
                    container
                    spacing={3}
                    direction="row-reverse"
                    style={{ width: "100%" }}
                  >
                    <Grid item>
                      {step < 4 ? (
                        <Button
                          onClick={() => {
                            if (step < 5) {
                              setStep(step + 1);
                            } else {
                              return null;
                            }
                          }}
                        >
                          {step === 3 ? `完成` : `下一步(${step} / 3)`}
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid item>
                      {step > 1 && step < 4 ? (
                        <Button
                          variant="text"
                          onClick={() => {
                            if (step > 1) {
                              setStep(step - 1);
                            } else {
                              return null;
                            }
                          }}
                        >
                          返回
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                {step === 2 ? (
                  <img src="/google.png" className="search" />
                ) : (
                  <ProfileArea>
                    {step === 3 ? (
                      <img className="verified_ani" src="/verified.png" />
                    ) : null}
                    <Skeleton
                      variant="circle"
                      width={120}
                      height={120}
                      style={{ margin: "0 auto" }}
                    />
                    <SWAPSpace />
                    <Skeleton
                      height={30}
                      variant="text"
                      width={300}
                      style={{ margin: "0 auto" }}
                    />
                    <SWAPSpace />
                    <Skeleton
                      height={210}
                      variant="text"
                      width="100%"
                      style={{ margin: "0 auto" }}
                    />
                    <SWAPSpace />
                    <Button
                      variant="secondary"
                      fullWidth
                      startIcon={<MailOutlineIcon />}
                    >
                      Email 聯絡我
                    </Button>
                    <SWAPSpace />
                    <Button
                      variant="secondary"
                      fullWidth
                      startIcon={<LinkIcon />}
                    >
                      作品集網站
                    </Button>
                    <SWAPSpace />
                    <Button
                      variant="secondary"
                      fullWidth
                      startIcon={<Instagram />}
                    >
                      Instagram
                    </Button>
                    <SWAPSpace />
                    <Button
                      variant="secondary"
                      fullWidth
                      startIcon={<FacebookIcon />}
                    >
                      Facebook
                    </Button>
                  </ProfileArea>
                )}
              </Grid>
            </Grid>
            <div style={{ height: "10vh" }} />
          </Container>
        </div>
      ) : (
        <div>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
              alignItems="center"
              style={{ minHeight: "100vh" }}
            ></Grid>
          </Container>
        </div>
      )}
      <Modal open={authModal} size="small" onClose={() => setAuthModal(false)}>
        <div
          onClick={() => {
            setAuthModal(false);
            setOnboarding(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/auth.png"
            style={{
              display: "block",
              objectFit: "contain",
              width: "100%",
            }}
          />
        </div>
      </Modal>
    </ProfileWrap>
  );
};

export default Profile;
